import { Flex, FlexProps, Image, Spinner, Text } from '@chakra-ui/react';

export interface IEmptyBannerProps extends FlexProps {
  /**
   * Empty banner cover image path.
   */
  cover?: string;
  /**
   * Empty benner title text.
   */
  title: string;
}

export const EmptyBanner: React.FC<IEmptyBannerProps> = (
  props,
): JSX.Element => {
  const { cover, title, ...rest } = props;

  return (
    <Flex {...rest}>
      <Image
        alt="empty-banner"
        fallback={<Spinner />}
        mb={2}
        src={cover}
        w={{ base: 70, md: 100 }}
      />

      <Text
        align="center"
        fontSize={{ base: 'lg', md: 'xl' }}
        fontWeight="black"
      >
        {title}
      </Text>
    </Flex>
  );
};

EmptyBanner.defaultProps = {
  align: 'center',
  bg: 'blackAlpha.50',
  direction: 'column',
  justify: 'center',
  mx: 'auto',
  px: 4,
  py: 12,
};
