import { instance } from 'services';
import { IFindFnReturn, IQueryParams, IResponse } from 'services/types.d';

import { IUserPayload, IUserResource } from './types.d';

const CUSTOM_HEADERS = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};

export const UserResources = {
  findAll: (p?: IQueryParams): Promise<IFindFnReturn<IUserResource>> =>
    instance.get('/user/', { params: p }),

  create: (params: IUserPayload): Promise<IResponse<IUserResource>> =>
    instance.post(`/user/`, params),

  update: (
    id: number,
    params: IUserPayload,
  ): Promise<IResponse<IUserResource>> =>
    instance.patch(`/user/${id}/`, params, CUSTOM_HEADERS),

  delete: (id: number): Promise<IResponse<unknown>> =>
    instance.delete(`/user/${id}/`),

  me: (): Promise<IResponse<IUserResource>> => instance.get('/user/me/'),
};
