import { Button, Stack, StackDivider } from '@chakra-ui/react';
import { FileField } from 'components/inputs';
import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { IKYCPhysicalPayload } from 'services/resources/users/kyc-physical/types.d';

import { DocumentValidationSchema } from './helpers/form-helpers';

export interface IKYCUpdateFormProps {
  /**
   * On submit handler.
   */
  onSubmit: (
    v: IKYCPhysicalPayload,
    helpers?: FormikHelpers<IKYCPhysicalPayload>,
  ) => Promise<void>;
}

export const KYCUpdateForm: React.FC<IKYCUpdateFormProps> = (
  props,
): JSX.Element => {
  const { onSubmit } = props;

  const { t } = useTranslation('kyc-physical');

  return (
    <Formik
      initialValues={{
        life_proof: undefined,
        address_proof: undefined,
        official_id_front: undefined,
        official_id_back: undefined,
      }}
      onSubmit={onSubmit}
      validationSchema={DocumentValidationSchema}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <Stack
            divider={<StackDivider borderColor="blackAlpha.100" />}
            spacing={4}
          >
            <FileField label={t('form.life-proof')} name="life_proof" />
            <FileField label={t('form.id-front')} name="official_id_front" />
            <FileField label={t('form.id-back')} name="official_id_back" />
            <FileField
              label={t('form.address-proof')}
              name="address_proof"
              helper="inputs.file.helpers-extended"
            />
          </Stack>
          <Button
            colorScheme="blue"
            isDisabled={isSubmitting || !isValid}
            isFullWidth
            isLoading={isSubmitting}
            mt={12}
            size="sm"
            type="submit"
          >
            {t('button.save')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
