import { Box, BoxProps, List, ListItem } from '@chakra-ui/react';
import { Divider } from 'components/elements';
import fp from 'lodash/fp';
import { nanoid } from 'nanoid';
import { useEffect, useRef } from 'react';
import { useToggle } from 'react-use';
import { DivElementRef, INavigation } from 'types.d';

import { Heading } from './components/Heading';
import { Menu } from './components/Menu';
import { NavigationItem } from './components/NavigationItem';
import { ToggleButton } from './components/ToggleButton';
import { Version } from './components/Version';
import { useOutsideHandler } from './hooks/useOutsideHandler';

export interface ISidebarProps extends BoxProps {
  /**
   * If `true` the sidebar will be open and its position will be fixed to the
   * page, otherwise will be close with a relative positioning.
   */
  isLgScreen: boolean;
  /**
   * Navigation list.
   */
  navigation?: INavigation[];
}

export const Sidebar: React.FC<ISidebarProps> = (props): JSX.Element => {
  const { isLgScreen, navigation = [], ...rest } = props;

  const sidebarRef = useRef<HTMLDivElement>() as DivElementRef;

  const [toggle, setToggle] = useToggle(false);

  const ml = toggle ? '-206px' : 0;

  useOutsideHandler(sidebarRef, isLgScreen, toggle, setToggle);

  useEffect(() => {
    setToggle(!isLgScreen);
  }, [isLgScreen]);

  return (
    <Box as="aside" ml={ml} ref={sidebarRef} {...rest}>
      <ToggleButton isToggle={toggle} onClick={setToggle} />

      <Heading />

      <Divider />

      <Box h="calc(100vh - 4rem - 130px)" overflow="scroll">
        <List>
          {navigation.map(({ icon, label, path, routes }) => (
            <ListItem key={nanoid()}>
              {!fp.isNil(path) ? (
                <NavigationItem icon={icon} label={label} path={path} />
              ) : (
                <Menu icon={icon} label={label} routes={routes} />
              )}
            </ListItem>
          ))}
        </List>
        <Version />
      </Box>
    </Box>
  );
};

Sidebar.defaultProps = {
  bg: 'rgb(250, 251, 252)',
  borderRight: '2px',
  borderColor: 'blackAlpha.100',
  h: 'calc(100vh - 4rem)',
  maxW: '240px',
  minW: '240px',
  pb: 8,
  position: { base: 'fixed', lg: 'sticky' },
  top: 16,
  transition: 'margin 0.5s ease',
  w: '100%',
  zIndex: 'sticky',
};
