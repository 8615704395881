import { Box, BoxProps, Text } from '@chakra-ui/react';

export const NavButton: React.FC<BoxProps> = (props): JSX.Element => {
  const { children, ...rest } = props;

  return (
    <Box as="button" {...rest}>
      <Text fontSize="sm" fontWeight="bold">
        {children}
      </Text>
    </Box>
  );
};

NavButton.defaultProps = {
  borderBottom: '2px',
  borderColor: 'blackAlpha.100',
  cursor: 'pointer',
  py: 2,
  sx: {
    ':hover': {
      bg: 'gray.50',
    },
  },
  transition: 'background 0.2s ease',
  w: '100%',
};
