import { Button, Stack, StackDivider } from '@chakra-ui/react';
import { FileField } from 'components/inputs';
import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { IContractFormValues } from 'services/types.d';

import { ContractsFormValidationSchema } from './helpers';

export interface IContractsFormProps {
  /**
   * On Submit handler.
   */
  onSubmit: (
    v: IContractFormValues,
    helpers?: FormikHelpers<IContractFormValues>,
  ) => Promise<void>;
}

export const ContractsForm: React.FC<IContractsFormProps> = (
  props,
): JSX.Element => {
  const { onSubmit } = props;

  const { t } = useTranslation('contracts');

  return (
    <Formik
      initialValues={{
        investment_constancy: undefined,
        investment_attachment: undefined,
      }}
      onSubmit={onSubmit}
      validationSchema={ContractsFormValidationSchema}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <Stack
            divider={<StackDivider borderColor="blackAlpha.100" />}
            spacing={4}
          >
            <FileField
              helper="inputs.file.helpers-pdf"
              label={t('form.participation-constancy')}
              name="participation_constancy"
            />
            <FileField
              helper="inputs.file.helpers-pdf"
              label={t('form.investment-attachment')}
              name="investment_attachment"
            />
          </Stack>
          <Button
            colorScheme="blue"
            isDisabled={isSubmitting || !isValid}
            isFullWidth
            isLoading={isSubmitting}
            mt={12}
            size="sm"
            type="submit"
          >
            {t('form.button.submit')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
