import { useAsyncFn } from 'react-use';
import { IPaginationResponse, IQueryParams, IResource } from 'services/types.d';

export interface IFetchState<T> {
  /**
   * If something goes wrong during the HTTP request it will set this value to
   * an @type{Error} state, otherwise it will be @type{undefined}.
   */
  error?: Error;
  /**
   * During the HTTP request this value will be `true`, in case of an error
   * occurs or the request its resolved it will be set to `false`.
   */
  loading: boolean;
  /**
   * Paginated response from the API.
   */
  value?: IPaginationResponse<T>;
}

/**
 * Async generic function that retrieves a set of registries from the API.
 * @param {IQueryParams} params - HTTP query parameters
 *
 * @return {Promise<IPaginationResponse<T>>}
 */
export type IFetchFn<T> = (
  params?: IQueryParams,
) => Promise<IPaginationResponse<T>>;

/**
 * Creates a reusable hook that retrieves a set of registries from the API.
 * @param {IResource<T>} r - api resource for crud operations.
 * @returns {[IFetchState<T>, IFetchFn<T>]} - tuple of fetch state and fetch fn.
 */
export const useFind = <T>(r: IResource<T>): [IFetchState<T>, IFetchFn<T>] => {
  const [state, doFetch] = useAsyncFn(
    async (params?: IQueryParams): Promise<IPaginationResponse<T>> => {
      const response = await r.findAll(params);
      return response.data;
    },
  );

  return [state, doFetch];
};
