import { instance } from 'services';
import { IFindFnReturn, IQueryParams, IResponse } from 'services/types.d';

import { IKYBMoralPersonPayload, IKYBMoralPersonResource } from './types.d';

const CUSTOM_HEADERS = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};

export const KYBMoralResources = {
  findAll: (
    p?: IQueryParams,
  ): Promise<IFindFnReturn<IKYBMoralPersonResource>> =>
    instance.get('/kyb-moral/', { params: p }),

  findByID: (
    id: number | string,
  ): Promise<IResponse<IKYBMoralPersonResource>> =>
    instance.get(`/kyb-moral/${id}`),

  create: (
    params: IKYBMoralPersonPayload,
  ): Promise<IResponse<IKYBMoralPersonResource>> =>
    instance.post(`/kyb-moral/`, params),

  update: (
    id: number,
    params: IKYBMoralPersonPayload,
    isMultipart = false,
  ): Promise<IResponse<IKYBMoralPersonResource>> =>
    instance.patch(
      `/kyb-moral/${id}/`,
      params,
      isMultipart ? CUSTOM_HEADERS : {},
    ),

  delete: (id: number): Promise<IResponse<unknown>> =>
    instance.delete(`/kyb-moral/${id}/`),

  updateFiles: (
    id: number,
    params: FormData,
  ): Promise<IResponse<IKYBMoralPersonResource>> =>
    instance.patch(`/kyb-moral/${id}/`, params, CUSTOM_HEADERS),
};
