import { instance } from 'services';
import { IFindFnReturn, IQueryParams, IResponse } from 'services/types.d';

import { IContractPayload, IContractResource } from './types.d';

const CUSTOM_HEADERS = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};

export const ContractsResources = {
  create: (params: IContractPayload): Promise<IResponse<IContractResource>> =>
    instance.post(`/contract/`, params, CUSTOM_HEADERS),

  findAll: (p?: IQueryParams): Promise<IFindFnReturn<IContractResource>> =>
    instance.get('/contract/', { params: p }),

  update: (
    id: number,
    params: IContractPayload,
  ): Promise<IResponse<IContractResource>> =>
    instance.patch(`/contract/${id}/`, params, CUSTOM_HEADERS),

  delete: (id: number): Promise<IResponse<unknown>> =>
    instance.delete(`/contract/${id}/`),
};
