import { Flex, FlexProps } from '@chakra-ui/react';

export const Wrapper: React.FC<FlexProps> = (props): JSX.Element => {
  const { children, ...rest } = props;

  return <Flex {...rest}>{children}</Flex>;
};

Wrapper.defaultProps = {
  align: 'center',
  h: '100%',
  justify: 'center',
  position: 'relative',
  sx: {
    '&:hover': {
      cursor: 'pointer',
      '> #bluediv': {
        h: '2px',
        transition: 'width 0.5s ease',
        w: '100%',
      },
    },
  },
};
