import { SimpleGrid, SimpleGridProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IKYBMoralPersonResource } from 'services/resources/users/kyb-moral/types.d';

import {
  getId,
  getKybStage,
  getLegalRepresentativeFea,
  getOfficialIdNumber,
  getTaxId,
  getTaxIdCountryEmisor,
  KYBStagesEnum,
} from '../helpers';
import { Record } from './Record';

export interface ITaxInformationProps extends SimpleGridProps {
  /**
   * KYB moral user resource data.
   */
  kyb?: IKYBMoralPersonResource;
}

export const TaxInformation: React.FC<ITaxInformationProps> = (
  props,
): JSX.Element | null => {
  const { kyb, ...rest } = props;
  const { t } = useTranslation('kyb-moral');

  if (getKybStage(kyb) < KYBStagesEnum.COMPANY_INFORMATION) {
    return null;
  }

  return (
    <SimpleGrid mb={2} {...rest}>
      <Record
        description={getTaxId(kyb)}
        id={getId(kyb)}
        isEditable
        name="tax_id"
        title={t('page.detail.company-tax-information.tax-id.title')}
      />

      <Record
        description={getOfficialIdNumber(kyb)}
        id={getId(kyb)}
        isEditable
        name="official_id_number"
        title={t(
          'page.detail.company-tax-information.official-id-number.title',
        )}
      />

      <Record
        description={getLegalRepresentativeFea(kyb)}
        id={getId(kyb)}
        isEditable
        name="legal_representative_FEA"
        title={t(
          'page.detail.company-tax-information.legal-representative-fea.title',
        )}
      />

      <Record
        description={getTaxIdCountryEmisor(kyb)}
        id={getId(kyb)}
        isEditable
        name="tax_id_country_emisor"
        title={t(
          'page.detail.company-tax-information.tax-country-emisor.title',
        )}
      />
    </SimpleGrid>
  );
};

TaxInformation.defaultProps = {
  columns: [1, 1, 2, 3],
  spacingY: 6,
};
