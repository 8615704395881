import { SimpleGrid, SimpleGridProps } from '@chakra-ui/react';
import BlankInformation from 'images/blank-information.png';
import { useTranslation } from 'react-i18next';
import { IKYCPhysicalResource } from 'services/resources/users/kyc-physical/types.d';
import { IUserResource } from 'services/resources/users/user/types.d';

import {
  getEmail,
  getId,
  getKycStage,
  getPhone,
  KYCStagesEnum,
} from '../helpers';
import { EmptyBanner } from './EmptyBanner';
import { Record } from './Record';

export interface IContactInformationProps extends SimpleGridProps {
  /**
   * KYC physical resource data.
   */
  kyc?: IKYCPhysicalResource;
  /**
   * User resource data.
   */
  user?: IUserResource;
}

export const ContactInformation: React.FC<IContactInformationProps> = (
  props,
): JSX.Element => {
  const { kyc, user, ...rest } = props;

  const { t } = useTranslation('kyc-physical');

  if (getKycStage(kyc) < KYCStagesEnum.PHONE_NUMBER_SUCCESS) {
    return (
      <EmptyBanner
        cover={BlankInformation}
        mb={6}
        title={t('page.detail.contact.empty.title')}
      />
    );
  }

  return (
    <SimpleGrid {...rest}>
      <Record
        description={getEmail(user)}
        title={t('page.detail.contact.email')}
      />
      <Record
        description={getPhone(kyc)}
        id={getId(kyc)}
        isEditable
        name="phone"
        title={t('page.detail.contact.phone')}
      />
    </SimpleGrid>
  );
};

ContactInformation.defaultProps = {
  columns: [1, 1, 2, 3],
  spacingY: 6,
};
