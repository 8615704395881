export const HEADERS = [
  'HEADER.ID',
  'HEADER.USER',
  'HEADER.EMAIL',
  'HEADER.DEAL',
  'HEADER.AMOUNT',
  'HEADER.TOTAL',
  'HEADER.FEE',
  'HEADER.PROMO',
  'HEADER.TRADE_NAME',
  'HEADER.GROUP',
  'HEADER.STATUS',
  'HEADER.INVESTMENT_TYPE',
  'HEADER.DEPOSIT_DATE',
  'HEADER.ACCREDITATION_DATE',
  'HEADER.CREATED',
];
