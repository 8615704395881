import { QuerySuggestions } from 'components/elements';
import {
  CheckboxField,
  DateField,
  InputField,
  MoneyField,
  SelectField,
} from 'components/inputs';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import {
  dealTransform,
  emailTransform,
  genericTransform,
} from 'helpers/transform-helpers';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { InvestmentStatusResources } from 'services/resources/catalogs/investment-status';
import { DealResources } from 'services/resources/deal-flow/deal';
import { IInvestmentPayload } from 'services/resources/financial/investment/types.d';
import { UserResources } from 'services/resources/users/user';
import { ISelectOption } from 'types.d';

import { InvestmentFormValidationSchema } from './helpers';

export interface IInvestmentFormProps {
  /**
   * Form initial values, can be a complete or partial ´IInvestmentPayload´
   * object which will allow to support create and update requests.
   */
  initialValues: IInvestmentPayload;
  /**
   * On submit action, for this form this will be controlled from the outside
   * of this component thanks to the forward ref property.
   */
  onSubmit: (
    v: IInvestmentPayload,
    h?: FormikHelpers<IInvestmentPayload>,
  ) => void;
}

export const InvestmentForm = forwardRef<
  FormikProps<IInvestmentPayload>,
  IInvestmentFormProps
>((props, ref) => {
  const { initialValues, onSubmit } = props;
  const { t } = useTranslation(['investments', 'common']);

  return (
    <Formik
      initialValues={{ ...initialValues }}
      innerRef={ref}
      onSubmit={onSubmit}
      validationSchema={InvestmentFormValidationSchema}
    >
      <Form>
        <MoneyField label={t('form.input.total.label')} mb={4} name="total" />

        <MoneyField label={t('form.input.amount.label')} mb={4} name="amount" />

        <MoneyField label={t('form.input.fee.label')} mb={4} name="fee" />

        <DateField
          label={t('form.input.deposit-date.label')}
          mb={4}
          name="deposit_date"
        />

        <InputField label={t('form.input.promo.label')} mb={4} name="promo" />

        <QuerySuggestions
          query={InvestmentStatusResources.findAll}
          transform={genericTransform}
        >
          {({ loading, value }) => (
            <SelectField
              isLoading={loading}
              label={t('form.input.investment-status.label')}
              mb={4}
              name="investment_status"
              options={value as ISelectOption[]}
              placeholder="Select an option"
            />
          )}
        </QuerySuggestions>

        <QuerySuggestions
          query={DealResources.findAll}
          transform={dealTransform}
        >
          {({ loading, value }) => (
            <SelectField
              isLoading={loading}
              label={t('form.input.deal.label')}
              mb={4}
              name="deal"
              options={value as ISelectOption[]}
              placeholder="Select an option"
            />
          )}
        </QuerySuggestions>

        <QuerySuggestions
          params={{ is_accredited: true }}
          query={UserResources.findAll}
          transform={emailTransform}
        >
          {({ loading, value }) => (
            <SelectField
              isLoading={loading}
              label={t('form.input.user.label')}
              mb={4}
              name="user"
              options={value as ISelectOption[]}
              placeholder="Select an option"
            />
          )}
        </QuerySuggestions>

        <CheckboxField
          label={t('form.input.is-user-related.label')}
          mb={4}
          name="is_user_related_to_deal"
        />
        <CheckboxField
          label={t('form.input.has-exceed-max-udis.label')}
          mb={4}
          name="has_exceeds_max_udis"
        />

        <CheckboxField
          label={t('form.input.is-foreign-payment.label')}
          mb={4}
          name="is_foreign_payment"
        />

        <CheckboxField
          label={t('inputs.is-active.label', { ns: 'common' })}
          mb={4}
          name="is_active"
        />
      </Form>
    </Formik>
  );
});

InvestmentForm.displayName = 'InvestmentForm';
