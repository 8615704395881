import {
  Box,
  BoxProps,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useDebounce } from 'react-use';

export interface ISearchFilterProps extends Omit<BoxProps, 'onChange'> {
  /**
   * Interval in milliseconds that the debounce will be executed.
   */
  interval?: number;
  /**
   * On change callback.
   */
  onChange: (v?: string) => void;
  /**
   * Input placeholder.
   */
  placeholder?: string;
}

export const SearchFilter: React.FC<ISearchFilterProps> = (
  props,
): JSX.Element => {
  const { interval = 1500, onChange, placeholder, ...rest } = props;

  const [state, setState] = useState<string>('');
  const [debouncedValue, setDebouncedValue] = useState<string>('');

  useDebounce(
    () => {
      setDebouncedValue(state);
    },
    interval,
    [state],
  );

  useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue]);

  return (
    <Box {...rest}>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon as={FaSearch} color="blackAlpha" />
        </InputLeftElement>
        <Input
          onChange={({ currentTarget }) => setState(currentTarget?.value)}
          placeholder={placeholder}
          type="text"
          value={state}
        />
      </InputGroup>
    </Box>
  );
};
