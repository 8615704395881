import { CheckboxField, InputField } from 'components/inputs';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IIndustryPayload } from 'services/resources/preferences/types.d';

import { IndustryFormValidationSchema } from './helpers';

export interface IIndustryFormProps {
  /**
   * Form initial values, can be a complete or partial IIndustryPayload
   * object which will allow to support create and update requests.
   */
  initialValues: IIndustryPayload;
  /**
   * On submit action, for this form this will be controlled from the outside
   * of this component thanks to the forward ref property.
   */
  onSubmit: (v: IIndustryPayload, h?: FormikHelpers<IIndustryPayload>) => void;
}

export const IndustryForm = forwardRef<
  FormikProps<IIndustryPayload>,
  IIndustryFormProps
>((props, ref) => {
  const { initialValues, onSubmit } = props;
  const { t } = useTranslation(['industry', 'common']);

  return (
    <Formik
      initialValues={{ ...initialValues }}
      innerRef={ref}
      onSubmit={onSubmit}
      validationSchema={IndustryFormValidationSchema}
    >
      <Form>
        <InputField
          label={t('inputs.name.label', { ns: 'common' })}
          mb={4}
          name="name"
        />
        <InputField label={t('form.input.icon.label')} mb={4} name="icon" />
        <CheckboxField
          label={t('inputs.is-active.label', { ns: 'common' })}
          name="is_active"
        />
      </Form>
    </Formik>
  );
});

IndustryForm.displayName = 'IndustryForm';
