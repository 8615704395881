export const HEADERS = [
  'HEADER.ID',
  'HEADER.GROUP',
  'HEADER.USER',
  'HEADER.IS_ACCREDITED',
  'HEADER.GROUP.IS-ADMIN',
  'HEADER.GROUP.IS-OWNER',
  'HEADER.GROUP.IS-PENDING-EMAIL',
  'HEADER.CREATED',
];
