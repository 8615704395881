import { instance } from 'services';
import { IFindFnReturn, IQueryParams, IResponse } from 'services/types.d';

import { ICompanyPayload, ICompanyResource } from './types.d';

const CUSTOM_HEADERS = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};

export const CompanyResources = {
  findAll: (p?: IQueryParams): Promise<IFindFnReturn<ICompanyResource>> =>
    instance.get('/company/', { params: p }),

  findByID: (id: number | string): Promise<IResponse<ICompanyResource>> =>
    instance.get(`/company/${id}`),

  create: (params: ICompanyPayload): Promise<IResponse<ICompanyResource>> =>
    instance.post(`/company/`, params, CUSTOM_HEADERS),

  update: (
    id: number,
    params: ICompanyPayload,
    isMultipart = false,
  ): Promise<IResponse<ICompanyResource>> =>
    instance.patch(
      `/company/${id}/`,
      params,
      isMultipart ? CUSTOM_HEADERS : {},
    ),

  delete: (id: number): Promise<IResponse<unknown>> =>
    instance.delete(`/company/${id}/`),
};
