import { Box, Tag } from '@chakra-ui/react';
import { Divider, H2, Loading, PageHeadline } from 'components/elements';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { BankStatementResources } from 'services/resources/financial/bank-statements';
import { KYCPhysicalResources } from 'services/resources/users/kyc-physical';

import { AddressInformation } from './components/AddressInformation';
import { BankStatements } from './components/BankStatements';
import { Beneficiaries } from './components/Beneficiaries';
import { ContactInformation } from './components/ContactInformation';
import { DangerZone } from './components/DangerZone';
import { FinerioInformation } from './components/FinerioInformation';
import { HeaderInformation } from './components/HeaderInformation';
import { OfficialInformation } from './components/OfficialInformation';
import { OperationInformation } from './components/OperationInformation';
import { PersonalInformation } from './components/PersonalInformation';
import { ProfessionalInformation } from './components/ProfessionalInformation';
import { Record } from './components/Record';
import { References } from './components/References';
import {
  getGradeLevel,
  getId,
  getIsAccredited,
  getlastUpdate,
  getUserFullName,
} from './helpers';

export const DetailPage: React.FC = (): JSX.Element => {
  const { slug } = useParams<{ slug?: string }>();

  const { t } = useTranslation('kyc-physical');

  const { loading, value } = useAsync(async () => {
    const { data: kyc } = await KYCPhysicalResources.findByID(slug as string);
    const { data: usr } = await KYCPhysicalResources.findUser(slug as string);
    const { data: statements } = await BankStatementResources.findAll({
      email: usr.email,
    });
    return { kyc, usr, statements };
  }, [slug]);

  console.log('KASTAS', getlastUpdate(value?.usr));

  return (
    <>
      <PageHeadline
        quote={t('page.detail.quote')}
        title={t('page.detail.title', { value: getUserFullName(value) })}
      />

      <Divider mb={6} />

      {loading ? (
        <Loading isLoading />
      ) : (
        <Box mb={6} px={8}>
          <Tag colorScheme="blue" justify="center" mb={4}>
            {t('page.detail.header.is-accredited')}
          </Tag>

          <Tag colorScheme="blue" justify="center" mb={4} ml={4}>
            {t('page.detail.header.grade-title')} {getGradeLevel(value?.kyc)}
          </Tag>

          <Tag colorScheme="blue" justify="center" mb={4} ml={4}>
            {t('page.detail.header.person-type')}
          </Tag>

          <Tag colorScheme="blue" justify="center" mb={4} ml={4}>
            {t('page.detail.header.client-type')}
          </Tag>

          {getlastUpdate(value?.usr) ? (
            <Tag colorScheme="blue" justify="center" mb={4} ml={4}>
              {t('page.detail.header.last-update', {
                value: getlastUpdate(value?.usr),
              })}
            </Tag>
          ) : null}

          <HeaderInformation kyc={value?.kyc} mb={6} user={value?.usr} />

          <H2>{t('page.detail.personal-information.title')}</H2>
          <Divider mb={4} />

          <PersonalInformation kyc={value?.kyc} mb={6} />

          <H2>{t('page.detail.contact-information.title')}</H2>
          <Divider mb={4} />

          <ContactInformation kyc={value?.kyc} mb={6} user={value?.usr} />

          <H2>{t('page.detail.address.title')}</H2>
          <Divider mb={4} />

          <AddressInformation kyc={value?.kyc} mb={6} />

          <H2>{t('page.detail.professional.title')}</H2>
          <Divider mb={4} />

          <ProfessionalInformation kyc={value?.kyc} usr={value?.usr} mb={6} />

          <H2>{t('page.detail.official-information.title')}</H2>
          <Divider mb={4} />

          <OfficialInformation kyc={value?.kyc} mb={6} />

          <H2>{t('page.detail.bank-statements.title')}</H2>
          <Divider mb={4} />

          <BankStatements mb={6} statements={value?.statements?.results} />

          <H2>{t('page.detail.beneficiaries.title')}</H2>
          <Divider mb={4} />

          <Beneficiaries mb={6} user={value?.usr} />

          <H2>{t('page.detail.references.title')}</H2>
          <Divider mb={4} />

          <References mb={6} user={value?.usr} />

          <H2>{t('page.detail.operation.title')}</H2>
          <Divider mb={4} />

          <OperationInformation kyc={value?.kyc} mb={6} />

          <H2>{t('page.detail.finerio.title')}</H2>
          <Divider mb={4} />

          <FinerioInformation kyc={value?.kyc} />

          <H2 color="red">{t('page.detail.header.danger-zone')}</H2>
          <Divider mb={4} />

          <DangerZone
            kyc={value?.kyc}
            beneficiaries={value?.usr?.beneficiaries}
          />
        </Box>
      )}
    </>
  );
};
