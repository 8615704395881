import {
  FinancialForm,
  GeneralForm,
  InvestmentMemoForm,
  SecurityForm,
} from 'components/forms';
import fp from 'lodash/fp';

export const getGeneralInitialValues = fp.compose(
  fp.pick(['company', 'cover', 'slug', 'deadline', 'deal_status', 'podcast']),
  fp.get('deal'),
);

export const getFinancialInitialValues = fp.compose(
  fp.pick([
    'funding_goal',
    'min_funding_goal',
    'min_investment',
    'fee',
    'carry',
  ]),
  fp.get('deal'),
);

export const getSecurityTypeInitialValues = fp.compose(
  fp.pick([
    'security_type',
    'valuation_cap',
    'pre_money',
    'exchange',
    'exchange_type',
    'exchange_type_date',
    'usd_cap',
  ]),
  fp.get('deal'),
);

export const getInvestmentMemoInitialValues = fp.compose(
  fp.pick(['highlights', 'industry', 'oportunity', 'solution']),
  fp.get('deal'),
);

export const NODES = [
  {
    fn: getGeneralInitialValues,
    key: 'deal.general.title',
    props: { maxW: '512px' },
    UI: GeneralForm,
  },
  {
    fn: getFinancialInitialValues,
    key: 'deal.financial.title',
    props: { maxW: '512px' },
    UI: FinancialForm,
  },
  {
    fn: getSecurityTypeInitialValues,
    key: 'deal.security-type.title',
    props: { maxW: '512px' },
    UI: SecurityForm,
  },
  {
    fn: getInvestmentMemoInitialValues,
    key: 'deal.investment-memo.title',
    props: {},
    UI: InvestmentMemoForm,
  },
];
