import { CRUDProvider } from 'components/providers';
import { useTranslation } from 'react-i18next';
import { DealStatusResources } from 'services/resources/deal-flow/deal-status';

import { Page } from './components/Page';

export const DealStatusPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation('deal-status');

  return (
    <CRUDProvider
      allowPost
      quote={t('page.quote')}
      resource={DealStatusResources}
      title={t('page.title')}
    >
      <Page />
    </CRUDProvider>
  );
};
