import { Heading, HeadingProps } from '@chakra-ui/react';

export const H2: React.FC<HeadingProps> = (props): JSX.Element => {
  const { children, ...rest } = props;

  return (
    <Heading as="h2" {...rest}>
      {children}
    </Heading>
  );
};

H2.defaultProps = {
  fontSize: 'xl',
  fontWeight: 'bold',
  mb: 4,
};
