export const fonts = {
  heading: `'Roboto', sans-serif`,
  body: `'Roboto', sans-serif`,
  mono: `'Cormorant Garamond', serif`,
};

export const fontWeights = {
  regular: 400,
  medium: 500,
  bold: 700,
  black: 900,
};
