import { formatDate } from 'helpers';
import fp from 'lodash/fp';
import { IIndustryResource } from 'services/resources/preferences/types.d';

export const getName = fp.get(['company', 'name']);

export const getCreated = fp.compose(formatDate, fp.get('created'));

export const getIndustries = fp.compose(
  fp.map((item: IIndustryResource) => item?.name),
  fp.get(['company', 'industries']),
);

export const getSlug = fp.get('slug');
