import { BoxProps, Icon } from '@chakra-ui/react';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { FaCheck } from 'react-icons/fa';

import { Bullet } from './components/Bullet';
import { Container } from './components/Container';
import { Sizes } from './helpers';

export interface IProgressBulletsProps extends BoxProps {
  /**
   * Number of bullets to display.
   */
  bullets: number;
  /**
   * Selected bullet.
   */
  selected?: number;
  /**
   * Bullet size.
   */
  size?: keyof typeof Sizes;
}

export const ProgressBullets: React.FC<IProgressBulletsProps> = (
  props,
): JSX.Element | null => {
  const { bullets, selected = 1, size = 'md', ...rest } = props;

  if (bullets <= 0 || selected > bullets) return null;

  return (
    <Container maxWidth={Sizes[size]} {...rest}>
      {_.map(_.times(bullets), (num) => (
        <Bullet
          key={nanoid()}
          left={`${num * (100 / (bullets - 1))}%`}
          isSelected={selected === num + 1}
          isChecked={selected > num + 1}
        >
          {num + 1 < selected ? <Icon as={FaCheck} h={3} w={3} /> : num + 1}
        </Bullet>
      ))}
    </Container>
  );
};
