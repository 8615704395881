import { Button, ButtonProps, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { BsFileEarmarkSpreadsheet } from 'react-icons/bs';

export interface ICSVButtonProps extends ButtonProps {
  /**
   * Text label that the button will show.
   */
  label: string;
  /**
   * Download a user_group model csv file.
   */
  onClick: () => void;
}

export const CSVButton: React.FC<ICSVButtonProps> = (
  props,
): JSX.Element | null => {
  const { label, onClick, ...rest } = props;

  const { t } = useTranslation('common');

  return (
    <Button onClick={onClick} {...rest}>
      <Text>{t(label)}</Text>
    </Button>
  );
};

CSVButton.defaultProps = {
  colorScheme: 'green',
  rightIcon: <BsFileEarmarkSpreadsheet />,
};
