import { Text, TextProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import version from '../../../../../package.json';

export const Version: React.FC<TextProps> = (props): JSX.Element => {
  const { t } = useTranslation('common');

  return <Text {...props}>{t('version', { value: version?.version })}</Text>;
};

Version.defaultProps = {
  bottom: 2,
  color: 'blackAlpha.500',
  fontSize: 'xs',
  fontWeight: 'bold',
  left: '50%',
  position: 'absolute',
  transform: 'translateX(-50%)',
};
