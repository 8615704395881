import { instance } from 'services';
import { IFindFnReturn, IQueryParams, IResponse } from 'services/types.d';

import { IBeneficiaryPayload, IBeneficiaryResource } from './types.d';

export const BeneficiaryResources = {
  findAll: (p?: IQueryParams): Promise<IFindFnReturn<IBeneficiaryResource>> =>
    instance.get('/beneficiary/', { params: p }),

  create: (
    params: IBeneficiaryPayload,
  ): Promise<IResponse<IBeneficiaryResource>> =>
    instance.post(`/beneficiary/`, params),

  update: (
    id: number,
    params: IBeneficiaryPayload,
  ): Promise<IResponse<IBeneficiaryResource>> =>
    instance.patch(`/beneficiary/${id}/`, params),

  delete: (id: number): Promise<IResponse<unknown>> =>
    instance.delete(`/beneficiary/${id}/`),
};
