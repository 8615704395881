/* eslint-disable no-nested-ternary */
import {
  Flex,
  FlexProps,
  Icon,
  IconButton,
  Image,
  Spinner,
  Stack,
  StackProps,
  TableCellProps,
  TableColumnHeaderProps,
  Td as TableCell,
  Text,
  Th as TableHead,
} from '@chakra-ui/react';
import { TableButton } from 'components/elements';
import { getJournalActionIcon } from 'helpers';
import fp from 'lodash/fp';
import { FaCheckCircle } from 'react-icons/fa';
import { MdCancel } from 'react-icons/md';

export const Th: React.FC<TableColumnHeaderProps> = (props): JSX.Element => (
  <TableHead whiteSpace="nowrap" {...props} />
);

export const Td: React.FC<TableCellProps> = (props): JSX.Element => (
  <TableCell fontSize="sm" whiteSpace="nowrap" {...props} />
);

export const TdTag: React.FC<FlexProps> = (props): JSX.Element => {
  const { children, ...rest } = props;
  return (
    <Td>
      <Flex {...rest}>
        <Text align="center" fontSize="0.675rem" fontWeight="bold">
          {children}
        </Text>
      </Flex>
    </Td>
  );
};

TdTag.defaultProps = {
  align: 'center',
  bg: 'blue.100',
  justify: 'center',
  px: 4,
  py: '0.175rem',
  rounded: 'full',
};

export interface ITdActionsProps extends StackProps {
  /**
   * If `true` or `1`, it means that the listing is showing active resources,
   * in this case this component will render the `DELETE` and `UPDATE` buttons
   * otherwise it will render the `RESTORE` action.
   */
  isActive: boolean | number;
  /**
   * Background for a custom defined button.
   */
  customButtonBg?: string;
  /**
   * Icon for a custom defined button.
   */
  customButtonIcon?: React.ReactElement;
  /**
   * Custom button callback, can be undefined.
   */
  onCustomCallback?: () => void;
  /**
   * On delete event, when this action is trigger it will open the delete
   * modal, which in case that the user is secure of its action it will send
   * a `DELETE` request to the API.
   */
  onDelete?: () => void;
  /**
   * If true, it will indicate if only use the custom button.
   */
  onlyCustomAction?: boolean;
  /**
   * On open file event, when this action is trigger it will open a new
   * browser window to  download a document.
   */
  onOpenFile?: () => void;
  /**
   * On restore event, when this action is trigger it will make a HTTP PATCH
   * request in order to change the `is_active` attribute of the registry
   * from `false` to `true`.
   */
  onRestore?: () => void;
  /**
   * On update event, when this action is trigger it will open the form drawer,
   * which in case that the user is secure of its action it will send a `PATCH`
   * request to the API.
   */
  onUpdate?: () => void;
}

export const TdActions: React.FC<ITdActionsProps> = (props): JSX.Element => {
  const {
    isActive,
    customButtonBg,
    customButtonIcon,
    onCustomCallback,
    onDelete,
    onlyCustomAction = false,
    onOpenFile,
    onRestore,
    onUpdate,
    ...rest
  } = props;

  if (onlyCustomAction)
    return (
      <Stack as={TableCell} {...rest}>
        <IconButton
          aria-label="Custom Action"
          colorScheme={customButtonBg}
          icon={customButtonIcon}
          onClick={onCustomCallback}
          rounded="md"
          size="sm"
          type="button"
          variant="solid"
        />
      </Stack>
    );

  return (
    <Stack as={TableCell} {...rest}>
      {isActive ? (
        <>
          {!fp.isNil(onUpdate) ? (
            <TableButton action="UPDATE" onClick={onUpdate} />
          ) : null}
          {!fp.isNil(onDelete) ? (
            <TableButton action="DELETE" onClick={onDelete} />
          ) : null}
          {!fp.isNil(onOpenFile) ? (
            <TableButton action="FILE" onClick={onOpenFile} />
          ) : null}
          {!fp.isNil(onCustomCallback) ? (
            <IconButton
              aria-label="Custom Action"
              colorScheme={customButtonBg}
              icon={customButtonIcon}
              onClick={onCustomCallback}
              rounded="md"
              size="sm"
              type="button"
              variant="solid"
            />
          ) : null}
        </>
      ) : !fp.isNil(onRestore) ? (
        <TableButton action="RESTORE" onClick={onRestore} />
      ) : null}
    </Stack>
  );
};

TdActions.defaultProps = {
  bg: 'white',
  borderLeft: '1px',
  borderLeftColor: 'blackAlpha.100',
  isInline: true,
  position: { base: 'static', md: 'sticky' },
  right: 0,
  spacing: 2,
  top: 0,
};

export interface ITdBinProps extends TableCellProps {
  /**
   * If `true`, it will render a green icon, otherwise a red icon.
   */
  isCheck: boolean;
}

export const TdBin: React.FC<ITdBinProps> = (props): JSX.Element => {
  const { isCheck, ...rest } = props;

  const getIconProps = (v: boolean) => {
    if (v) return { as: FaCheckCircle, color: 'green.500', boxSize: 4 };
    return { as: MdCancel, color: 'red.500', boxSize: 4 };
  };

  return (
    <Td {...rest}>
      <Icon {...getIconProps(isCheck)} />
    </Td>
  );
};

export interface ITdLogoProps extends StackProps {
  /**
   * Image source path.
   */
  src: string;
}

export const TdLogo: React.FC<ITdLogoProps> = (props): JSX.Element => {
  const { children, src, ...rest } = props;

  return (
    <Stack as={TableCell} {...rest}>
      <Flex
        align="center"
        border="1px"
        borderColor="blackAlpha.200"
        h={12}
        justify="center"
        p={2}
        rounded="full"
        w={12}
      >
        <Image
          alt="logo"
          fallback={<Spinner />}
          fit="contain"
          h={10}
          src={src}
          rounded="full"
          w={10}
        />
      </Flex>
      <Text fontSize="sm" whiteSpace="nowrap">
        {children}
      </Text>
    </Stack>
  );
};

TdLogo.defaultProps = {
  align: 'center',
  isInline: true,
};

export interface ITdActionIconProps {
  /**
   * Journal action that will use as a reference tho show an icon.
   */
  action: string;
}

export const TdActionIcon: React.FC<ITdActionIconProps> = (
  props,
): JSX.Element => {
  const { action, ...rest } = props;

  return (
    <Td {...rest}>
      <Flex alignItems="center">
        <Icon {...getJournalActionIcon[action]} />
        <Text ml={4}>{action}</Text>
      </Flex>
    </Td>
  );
};
