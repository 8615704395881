import {
  FormErrorMessage as FEM,
  FormErrorMessageProps,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react';
import fp from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { AiFillWarning } from 'react-icons/ai';

export const FormErrorMessage: React.FC<FormErrorMessageProps> = (
  props,
): JSX.Element | null => {
  const { children, ...rest } = props;

  const { t } = useTranslation('errors');

  if (fp.isEmpty(children) || fp.isNil(children)) return null;

  return (
    <FEM color="blackAlpha.500" fontWeight="semibold" {...rest}>
      <Stack align="start" isInline justify="center">
        <Icon as={AiFillWarning} color="red" h={4} mt="-1px" w={4} />
        <Text align="start" as="span">
          {t(children as string)}
        </Text>
      </Stack>
    </FEM>
  );
};
