import { Checkbox, FormControl, FormControlProps } from '@chakra-ui/react';
import { FormErrorMessage, FormHelperText } from 'components/lib';
import { useField } from 'formik';

export type CheckboxSize = 'sm' | 'md' | 'lg';

export interface ICheckboxFieldProps extends FormControlProps {
  /**
   * Input helper text.
   */
  helper?: string;
  /**
   * Input label.
   */
  label?: string;
  /**
   * Input name.
   */
  name: string;
  /**
   * Checkbox element size.
   * @type CheckboxSize
   */
  size?: CheckboxSize;
}

export const CheckboxField: React.FC<ICheckboxFieldProps> = (
  props,
): JSX.Element => {
  const { children, helper, label, name, size = 'sm', ...rest } = props;

  const [field, meta] = useField(name);

  const isInvalid = !!meta.error && !!meta.touched;

  return (
    <FormControl isInvalid={isInvalid} name={name} {...rest}>
      <Checkbox
        color="blackAlpha.600"
        defaultIsChecked={field.value}
        fontWeight="medium"
        size={size}
        {...field}
      >
        {label || children}
      </Checkbox>
      <FormHelperText>{helper}</FormHelperText>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
