import axios from 'axios';
import { instance } from 'services';
import { IResponse } from 'services/types.d';

import {
  IAuthTokens,
  ILoginEmailPayload,
  ILoginPayload,
  IRefreshPayload,
} from './types.d';

export const AuthResources = {
  auth: (params: ILoginPayload): Promise<IResponse<IAuthTokens>> =>
    instance.post('/auth/jwt/create/', params),

  refresh: (params: IRefreshPayload): Promise<IResponse<IAuthTokens>> =>
    axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/refresh/`, params),

  prepare: (p: ILoginEmailPayload): Promise<IResponse<unknown>> =>
    instance.get('/user/prepare/', { params: p }),
};
