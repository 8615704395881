import { Link, LinkProps, Text } from '@chakra-ui/react';

import { BlueDiv } from './components/BlueDiv';
import { Wrapper } from './components/Wrapper';

export interface INavLinkProps extends LinkProps {
  /**
   * Router link to redirect on click events.
   */
  path: string;
}

export const NavLink: React.FC<INavLinkProps> = (props): JSX.Element => {
  const { children, path } = props;

  return (
    <Link href={path}>
      <Wrapper>
        <Text fontSize="sm" fontWeight="bold">
          {children}
        </Text>
        <BlueDiv />
      </Wrapper>
    </Link>
  );
};
