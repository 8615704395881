import { instance } from 'services';
import { IFindFnReturn, IQueryParams, IResponse } from 'services/types.d';

import { IIndustryPayload, IIndustryResource } from './types.d';

export const IndustryResources = {
  findAll: (p?: IQueryParams): Promise<IFindFnReturn<IIndustryResource>> =>
    instance.get('/industry/', { params: p }),

  create: (params: IIndustryPayload): Promise<IResponse<IIndustryResource>> =>
    instance.post(`/industry/`, params),

  update: (
    id: number,
    params: IIndustryPayload,
  ): Promise<IResponse<IIndustryResource>> =>
    instance.patch(`/industry/${id}/`, params),

  delete: (id: number): Promise<IResponse<unknown>> =>
    instance.delete(`/industry/${id}/`),
};
