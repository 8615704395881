import { Flex, Icon, Tr } from '@chakra-ui/react';
import { useGenericContext } from 'components/contexts/useGenericContext';
import { H1, Modal } from 'components/elements';
import { KYCUpdateForm } from 'components/forms';
import { Td, TdActions, TdBin } from 'components/lib';
import { CustomTable } from 'components/modules';
import {
  addErrorToast,
  addSuccessToast,
} from 'containers/ToastManager/store/slice';
import { FormikHelpers } from 'formik';
import { getId, getResults, isResultsEmpty } from 'helpers';
import fp from 'lodash/fp';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { RiFileEditFill } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useToggle } from 'react-use';
import { KYCPhysicalResources } from 'services/resources/users/kyc-physical';
import {
  IKYCHistoryResource,
  IKYCPhysicalPayload,
  IKYCPhysicalResource,
} from 'services/resources/users/kyc-physical/types.d';

import {
  getCompletedKYC,
  getCreated,
  getRisks,
  getUserFullName,
  HEADERS,
} from '../helpers';

export const Page: React.FC = (): JSX.Element => {
  const {
    active,
    defaultRestore,
    onPrepareDelete,
    registry,
    setRegistry,
    state,
  } = useGenericContext<IKYCHistoryResource>();

  const dispatch = useDispatch();

  const { path } = useRouteMatch();

  const [isOpen, toggle] = useToggle(false);

  const history = useHistory();

  const { t } = useTranslation('kyc-history');

  const handleOnSubmit = async (
    v: IKYCPhysicalPayload,
    helpers?: FormikHelpers<IKYCPhysicalPayload>,
  ) => {
    const {
      official_id_front: idf,
      official_id_back: idb,
      address_proof: ap,
      life_proof: lp,
    } = v;
    try {
      const payload = new FormData();
      if (!fp.isNil(idf))
        payload.append('official_id_front', idf as File, (idf as File).name);
      if (!fp.isNil(idb))
        payload.append('official_id_back', idb as File, (idb as File).name);
      if (!fp.isNil(ap))
        payload.append('address_proof', ap as File, (ap as File).name);
      if (!fp.isNil(lp)) {
        payload.append('life_proof', lp as File, (lp as File).name);
      }
      await KYCPhysicalResources.updateFiles(registry.id, payload);
      dispatch(addSuccessToast('toast.success.files'));
      toggle();
    } catch (err) {
      dispatch(addErrorToast('toast.errors.files.network'));
    } finally {
      helpers?.setSubmitting(false);
    }
  };

  return (
    <>
      <CustomTable
        isEmpty={isResultsEmpty(state)}
        headers={HEADERS}
        isLoading={state?.loading}
        mx={8}
      >
        {getResults(state)?.map((row: IKYCHistoryResource) => (
          <Tr key={nanoid()} position="relative">
            <Td>{getId(row)}</Td>
            <Td>{getUserFullName(row)}</Td>
            <TdBin isCheck={getRisks(row)} />
            <TdBin isCheck={getCompletedKYC(row)} />
            <Td>{getCreated(row)}</Td>
            <TdActions
              customButtonBg="orange"
              customButtonIcon={<Icon as={RiFileEditFill} color="white" />}
              isActive={active}
              onCustomCallback={() => {
                setRegistry(row);
                toggle();
              }}
              onDelete={() => onPrepareDelete(row)}
              onRestore={() => defaultRestore(row, { is_active: true })}
              onUpdate={() => history.push(`${path}/${row?.id}`)}
            />
          </Tr>
        ))}
      </CustomTable>

      <Modal isOpen={isOpen} onClose={toggle}>
        <Flex
          direction="column"
          h="100%"
          justify="center"
          maxW="512px"
          mx="auto"
        >
          <H1 mb={12}>{t('modal.title')}</H1>
          <KYCUpdateForm onSubmit={handleOnSubmit} />
        </Flex>
      </Modal>
    </>
  );
};
