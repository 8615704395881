import { Box, BoxProps, Icon } from '@chakra-ui/react';
import { GiHamburgerMenu } from 'react-icons/gi';

export interface IBurgerButtonProps extends Omit<BoxProps, 'onClick'> {
  /**
   * Callback to be triggered on click events.
   */
  onClick: () => void;
}

export const BurgerButton: React.FC<IBurgerButtonProps> = (
  props,
): JSX.Element => {
  const { onClick, ...rest } = props;

  return (
    <Box as="button" onClick={onClick} {...rest}>
      <Icon as={GiHamburgerMenu} boxSize={8} />
    </Box>
  );
};

BurgerButton.defaultProps = {
  cursor: 'pointer',
  sx: {
    ':hover': {
      transform: 'scale(1.1)',
    },
  },
  transition: 'all 0.2s ease',
};
