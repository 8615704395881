import { Tr } from '@chakra-ui/react';
import { useGenericContext } from 'components/contexts/useGenericContext';
import { BeneficiaryForm } from 'components/forms';
import { Td, TdActions } from 'components/lib';
import { CustomTable, FormDrawer } from 'components/modules';
import {
  addErrorToast,
  addSuccessToast,
} from 'containers/ToastManager/store/slice';
import { FormikHelpers, FormikProps } from 'formik';
import {
  getEmail,
  getFirstSurname,
  getId,
  getLastSurname,
  getName,
  getResults,
  isResultsEmpty,
} from 'helpers';
import { nanoid } from 'nanoid';
import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { BeneficiaryResources } from 'services/resources/users/beneficiary';
import {
  IBeneficiaryPayload,
  IBeneficiaryResource,
} from 'services/resources/users/beneficiary/types.d';

import { getCreated, getPercentage, HEADERS } from '../helpers';

export const Page: React.FC = (): JSX.Element => {
  const {
    active,
    defaultRestore,
    doFetch,
    isDrawerOpen,
    onPrepareDelete,
    onPrepareDrawer,
    params,
    registry,
    state,
    toggleDrawer,
  } = useGenericContext<IBeneficiaryResource>();

  const ref = useRef<FormikProps<IBeneficiaryPayload>>(null);

  const dispatch = useDispatch();

  const prepareOnSubmit = useCallback(() => {
    if (ref?.current) ref?.current?.submitForm();
  }, [ref]);

  const handleOnSubmit = useCallback(
    async (
      p: IBeneficiaryPayload,
      helpers?: FormikHelpers<IBeneficiaryPayload>,
    ) => {
      try {
        await BeneficiaryResources.create(p);
        await doFetch(params);

        dispatch(addSuccessToast('toast.success.beneficiary'));
        toggleDrawer();
      } catch (err) {
        const msg = {
          400: 'toast.errors.beneficiaries.max',
          404: 'toast.errors.beneficiaries.not-found',
        }[err.status];
        dispatch(addErrorToast(msg));
      } finally {
        helpers?.setSubmitting(false);
      }
    },
    [],
  );

  return (
    <>
      <CustomTable
        isEmpty={isResultsEmpty(state)}
        headers={HEADERS}
        isLoading={state?.loading}
        mx={8}
      >
        {getResults(state)?.map((row: IBeneficiaryResource) => (
          <Tr key={nanoid()} position="relative">
            <Td>{getId(row)}</Td>
            <Td>{getName(row)}</Td>
            <Td>{getFirstSurname(row)}</Td>
            <Td>{getLastSurname(row)}</Td>
            <Td>{getEmail(row)}</Td>
            <Td isNumeric>{getPercentage(row)}</Td>
            <Td>{getCreated(row)}</Td>
            <TdActions
              isActive={active}
              onDelete={() => onPrepareDelete(row)}
              onRestore={() => defaultRestore(row, { is_active: true })}
              onUpdate={() => onPrepareDrawer(row)}
            />
          </Tr>
        ))}
      </CustomTable>

      <FormDrawer
        isOpen={isDrawerOpen}
        isDisabled={!!ref?.current?.isSubmitting || !!ref?.current?.isValid}
        isLoading={!!ref.current?.isSubmitting}
        onClose={toggleDrawer}
        onSubmit={prepareOnSubmit}
      >
        <BeneficiaryForm
          initialValues={{ ...registry }}
          onSubmit={handleOnSubmit}
          ref={ref}
        />
      </FormDrawer>
    </>
  );
};
