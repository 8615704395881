import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import bankStatement from 'locales/en/bank-statement.json';
import beneficiaries from 'locales/en/beneficiaries.json';
import common from 'locales/en/common.json';
import companies from 'locales/en/companies.json';
import companyReport from 'locales/en/company-report.json';
import companyRound from 'locales/en/company-round.json';
import companyStage from 'locales/en/company-stage.json';
import contracts from 'locales/en/contracts.json';
import dealStatus from 'locales/en/deal-status.json';
import deals from 'locales/en/deals.json';
import differentiation from 'locales/en/differentiation.json';
import errors from 'locales/en/errors.json';
import finerio from 'locales/en/finerio.json';
import groups from 'locales/en/groups.json';
import home from 'locales/en/home.json';
import industry from 'locales/en/industry.json';
import investments from 'locales/en/investments.json';
import investors from 'locales/en/investors.json';
import journal from 'locales/en/journal.json';
import kybMoral from 'locales/en/kyb-moral.json';
import kycPhysical from 'locales/en/kyc-physical.json';
import login from 'locales/en/login.json';
import references from 'locales/en/references.json';
import siti from 'locales/en/siti.json';
import spotlight from 'locales/en/spotlight.json';
import tables from 'locales/en/tables.json';
import toasts from 'locales/en/toasts.json';
import userGroup from 'locales/en/user-group.json';
import valuations from 'locales/en/valuations.json';
// SPANISH
import bankStatementEs from 'locales/es/bank-statement.json';
import beneficiariesEs from 'locales/es/beneficiaries.json';
import commonEs from 'locales/es/common.json';
import companiesEs from 'locales/es/companies.json';
import companyReportEs from 'locales/es/company-report.json';
import companyRoundEs from 'locales/es/company-round.json';
import companyStageEs from 'locales/es/company-stage.json';
import contractsEs from 'locales/es/contracts.json';
import dealStatusEs from 'locales/es/deal-status.json';
import dealsEs from 'locales/es/deals.json';
import differentiationEs from 'locales/es/differentiation.json';
import errorsEs from 'locales/es/errors.json';
import finerioEs from 'locales/es/finerio.json';
import groupsEs from 'locales/es/groups.json';
import homeEs from 'locales/es/home.json';
import industryEs from 'locales/es/industry.json';
import investmentsEs from 'locales/es/investments.json';
import investorsEs from 'locales/es/investors.json';
import journalEs from 'locales/es/journal.json';
import kybMoralEs from 'locales/es/kyb-moral.json';
import kycPhysicalEs from 'locales/es/kyc-physical.json';
import loginEs from 'locales/es/login.json';
import referencesEs from 'locales/es/references.json';
import sitiEs from 'locales/es/siti.json';
import spotlightEs from 'locales/es/spotlight.json';
import tablesEs from 'locales/es/tables.json';
import toastsEs from 'locales/es/toasts.json';
import userGroupEs from 'locales/es/user-group.json';
import valuationsEs from 'locales/es/valuations.json';
import { initReactI18next } from 'react-i18next';

const translations = {
  en: {
    'bank-statement': bankStatement,
    beneficiaries,
    common,
    companies,
    'company-round': companyRound,
    'company-stage': companyStage,
    contracts,
    deals,
    'deal-status': dealStatus,
    differentiation,
    errors,
    finerio,
    groups,
    home,
    industry,
    investments,
    investors,
    journal,
    'kyc-physical': kycPhysical,
    'kyb-moral': kybMoral,
    login,
    references,
    spotlight,
    siti,
    tables,
    toasts,
    'user-group': userGroup,
    valuations,
    'company-report': companyReport,
  },
  es: {
    'bank-statement': bankStatementEs,
    beneficiaries: beneficiariesEs,
    common: commonEs,
    companies: companiesEs,
    'company-round': companyRoundEs,
    'company-stage': companyStageEs,
    contracts: contractsEs,
    deals: dealsEs,
    'deal-status': dealStatusEs,
    differentiation: differentiationEs,
    errors: errorsEs,
    finerio: finerioEs,
    groups: groupsEs,
    home: homeEs,
    industry: industryEs,
    investments: investmentsEs,
    investors: investorsEs,
    journal: journalEs,
    'kyc-physical': kycPhysicalEs,
    'kyb-moral': kybMoralEs,
    login: loginEs,
    references: referencesEs,
    spotlight: spotlightEs,
    siti: sitiEs,
    tables: tablesEs,
    toasts: toastsEs,
    'user-group': userGroupEs,
    valuations: valuationsEs,
    'company-report': companyReportEs,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'es',
    supportedLngs: ['en', 'es'],
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      caches: ['cookie'], // Where to store the detected language
    },
    ns: [
      'bank-statement',
      'beneficiaries',
      'common',
      'companies',
      'company-round',
      'company-report',
      'company-stage',
      'contracts',
      'deal-status',
      'differentiation',
      'errors',
      'finerio',
      'groups',
      'home',
      'industry',
      'investments',
      'investors',
      'journal',
      'kyc-physical',
      'kyb-moral',
      'login',
      'spotlight',
      'siti',
      'references',
      'tables',
      'toasts',
      'user-group',
      'valuations',
    ],
    interpolation: {
      escapeValue: true,
    },
    resources: translations,
    react: {
      transKeepBasicHtmlNodesFor: ['li', 'p', 'ol', 'ul', 'br', 'strong'],
      useSuspense: false,
    },
  });

export default i18n;
