import { instance } from 'services';
import { IFindFnReturn, IQueryParams, IResponse } from 'services/types.d';

import { IDealPayload, IDealResource } from './types.d';

const CUSTOM_HEADERS = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};

export const DealResources = {
  findAll: (p?: IQueryParams): Promise<IFindFnReturn<IDealResource>> =>
    instance.get('/deal/', { params: p }),

  findByID: (id: number | string): Promise<IResponse<IDealResource>> =>
    instance.get(`/deal/${id}`),

  create: (params: IDealPayload): Promise<IResponse<IDealResource>> =>
    instance.post(`/deal/`, params, CUSTOM_HEADERS),

  update: (
    id: number | string,
    params: IDealPayload,
    isMultipart = false,
  ): Promise<IResponse<IDealResource>> =>
    instance.patch(`/deal/${id}/`, params, isMultipart ? CUSTOM_HEADERS : {}),

  delete: (id: number): Promise<IResponse<unknown>> =>
    instance.delete(`/deal/${id}/`),
};
