import { CRUDProvider } from 'components/providers';
import { useTranslation } from 'react-i18next';
import { DifferentiationResources } from 'services/resources/preferences/differentiation';

import { Page } from './components/Page';

export const DifferentiationPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation('differentiation');

  return (
    <CRUDProvider
      allowPost
      quote={t('page.quote')}
      resource={DifferentiationResources}
      title={t('page.title')}
    >
      <Page />
    </CRUDProvider>
  );
};
