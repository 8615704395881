/* eslint-disable @typescript-eslint/no-explicit-any */
import fp from 'lodash/fp';
import { createSelector } from 'reselect';

import { IAuthSliceState, initialState } from './slice';

/**
 * Direct selector to the auth state domain,
 */
const selectAuthDomain = (state: IAuthSliceState): any =>
  state.auth || initialState;

/**
 * Default selector used by Auth.
 */
export const makeSelectAuth = createSelector(
  selectAuthDomain,
  (substate) => substate,
);

/**
 * Session selector.
 */
export const makeSelectSession = createSelector(
  makeSelectAuth,
  (substate) => substate.session,
);

/**
 * Refresh token selector.
 */
export const makeSelectRefreshToken = createSelector(
  makeSelectSession,
  (substate) => substate.refreshToken,
);

/**
 * If session is not `null` or `undefined` the selector will return `true`.
 */
export const makeSelectIsAuthenticated = createSelector(
  makeSelectSession,
  (substate) => !fp.isNil(substate),
);

/**
 * If session is `null` or `undefined` the selector will return `true`.
 */
export const makeSelectIsNotAuthenticated = createSelector(
  makeSelectSession,
  (session) => fp.isNil(session),
);
