import {
  isValidFileFormat,
  isValidFileFormatWithPDF,
  isValidFileSize,
} from 'helpers';
import fp from 'lodash/fp';
import * as Yup from 'yup';

export const DocumentValidationSchema = Yup.object().shape({
  life_proof: Yup.mixed()
    .test('file-size', 'errors.file-size', (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileSize(v.size);
    })
    .test('file-format', 'errors.file-format', (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileFormat(v.type);
    }),

  official_id_front: Yup.mixed()
    .test('file-size', 'errors.file-size', (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileSize(v.size);
    })
    .test('file-format', 'errors.file-format', (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileFormat(v.type);
    }),

  official_id_back: Yup.mixed()
    .test('file-size', 'errors.file-size', (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileSize(v.size);
    })
    .test('file-format', 'errors.file-format', (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileFormat(v.type);
    }),

  address_proof: Yup.mixed()
    .test('file-size', 'errors.file-size', (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileSize(v.size);
    })
    .test('file-format', 'errors.file-format-pdf', (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileFormatWithPDF(v.type);
    }),
});
