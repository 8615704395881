import { QuerySuggestions } from 'components/elements';
import {
  DateField,
  FileField,
  InputField,
  SelectField,
} from 'components/inputs';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { genericTransform } from 'helpers/transform-helpers';
import { forwardRef } from 'react';
import { SitiReportCodeResources } from 'services/resources/catalogs/siti-report-codes';
import { ISitiPayload } from 'services/resources/siti/types.d';
import { ISelectOption } from 'types.d';

export interface ISitiFormProps {
  /**
   * On submit action, for this form this will be controlled from the outside
   * of this component thanks to the forward ref property.
   */
  onSubmit: (v: ISitiPayload, h?: FormikHelpers<ISitiPayload>) => void;
}

export const SitiForm = forwardRef<FormikProps<ISitiPayload>, ISitiFormProps>(
  (props, ref) => {
    const { onSubmit } = props;

    return (
      <Formik initialValues={{}} innerRef={ref} onSubmit={onSubmit}>
        <Form>
          <QuerySuggestions
            query={SitiReportCodeResources.findAll}
            transform={genericTransform}
          >
            {({ loading, value }) => (
              <SelectField
                isLoading={loading}
                label="Report"
                mb={4}
                name="code"
                options={value as ISelectOption[]}
                placeholder="Report"
              />
            )}
          </QuerySuggestions>

          <DateField label="Period Start" mb={4} name="period_start" />

          <DateField label="Period End" mb={4} name="period_end" />

          <FileField
            helper="The file to upload must be a xlsx"
            label="File"
            name="file"
            mb={4}
          />

          <InputField label="Invoice" mb={4} name="invoice" />
        </Form>
      </Formik>
    );
  },
);

SitiForm.displayName = 'SitiForm';
