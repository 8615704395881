import { Box } from '@chakra-ui/react';
import { CKEditorField } from 'components/inputs';
import { Form, Formik, FormikProps } from 'formik';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IDealPayload } from 'services/resources/deal-flow/deal/types.d';
import { ICommonDealStateProps } from 'types.d';

import { INITIAL_VALUES, InvestmentMemoFormValidationSchema } from './helpers';

export const InvestmentMemoForm = forwardRef<
  FormikProps<IDealPayload>,
  ICommonDealStateProps
>((props, ref): JSX.Element => {
  const { initialValues = INITIAL_VALUES, onSubmit, ...rest } = props;

  const { t } = useTranslation('deals');

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...initialValues }}
      innerRef={ref}
      onSubmit={onSubmit}
      validationSchema={InvestmentMemoFormValidationSchema}
    >
      <Box as={Form} {...rest}>
        <CKEditorField
          label={t('forms.input.highlights.label')}
          mb={4}
          name="highlights"
        />
        <CKEditorField
          label={t('forms.input.industry.label')}
          mb={4}
          name="industry"
        />
        <CKEditorField
          label={t('forms.input.opportunity.label')}
          mb={4}
          name="oportunity"
        />
        <CKEditorField
          label={t('forms.input.solution.label')}
          name="solution"
        />
      </Box>
    </Formik>
  );
});

InvestmentMemoForm.displayName = 'InvestmentMemoForm';
