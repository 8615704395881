import { Icon, Stack, StackProps, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { IconType } from 'react-icons/lib';
import { useToggle } from 'react-use';
import { ISimpleNavigation } from 'types.d';

import { SubMenu } from './SubMenu';

export interface IMenuProps extends StackProps {
  /**
   * Navigation item icon.
   */
  icon?: IconType;
  /**
   * Navigation item label.
   */
  label: string;
  /**
   * Meny routes.
   */
  routes?: ISimpleNavigation[];
}

export const Menu: React.FC<IMenuProps> = (props): JSX.Element => {
  const { icon, label, routes, ...rest } = props;

  const { t } = useTranslation('common');

  const [isOpen, toggle] = useToggle(false);

  return (
    <>
      <Stack onClick={toggle} {...rest}>
        <Stack align="center" isInline spacing={4}>
          <Icon as={icon} boxSize={4} />
          <Text fontSize="sm">{t(label)}</Text>
        </Stack>
        <Icon as={isOpen ? FaChevronUp : FaChevronDown} boxSize={3} />
      </Stack>

      <SubMenu isOpen={isOpen} routes={routes} />
    </>
  );
};

Menu.defaultProps = {
  align: 'center',
  cursor: 'pointer',
  h: 12,
  isInline: true,
  justify: 'space-between',
  px: 8,
  sx: {
    ':hover': {
      bg: 'blackAlpha.100',
      color: 'blue.800',
      transition: 'background 0.5s ease',
    },
  },
};
