/* eslint-disable */
import { SessionCookies } from 'utils';
import _ from 'lodash';
import { instance } from 'services';

const session = SessionCookies.load();

export class CustomUpload {
  public loader: any;
  public token: string;

  constructor(loader) {
    this.loader = loader;
    this.token = `Bearer ${_.get(session, 'accessToken')}`;
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          const toBase64 = (file) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            });

          return toBase64(file).then((cFile) => {
            let data = new FormData();
            data.append('upload', file);
            return instance
              .post('/deal/upload/', data, {
                headers: {
                  Authorization: this.token,
                  'Content-Type': 'multipart/form-data',
                },
              })
              .then((response) => {
                if (response.status) {
                  this.loader.uploaded = true;
                  resolve({
                    default: response.data.url,
                  });
                } else {
                  reject(`Couldn't upload file: ${file.name}.`);
                }
              });
          });
        }),
    );
  }
}

export function DealUploadImagePlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new CustomUpload(loader);
  };
}
