/* eslint-disable */
import './styles/index.css';

import {
  FormControl,
  FormControlProps,
  FormLabelProps,
} from '@chakra-ui/react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useField, useFormikContext } from 'formik';
import { FormErrorMessage, FormHelperText, FormLabel } from 'components/lib';

import { DealUploadImagePlugin } from './helpers';

export interface ICKEditorFieldProps extends FormControlProps {
  /**
   * Input helper text.
   */
  helper?: string;
  /**
   * Input label.
   */
  label?: string;
  /**
   * Label color.
   */
  labelProps?: FormLabelProps;
  /**
   * Input name.
   */
  name: string;
}

export const CKEditorField: React.FC<ICKEditorFieldProps> = (
  props,
): JSX.Element => {
  const { helper, label, labelProps = {}, name, size = 'sm', ...rest } = props;

  const [field, meta, helpers] = useField(name);

  const { setFieldValue } = useFormikContext();

  const isInvalid = !!meta.error && !!meta.touched;

  return (
    <FormControl isInvalid={isInvalid} name={name} {...rest}>
      <FormLabel fontSize={size} htmlFor={name} {...labelProps}>
        {label}
      </FormLabel>
      <CKEditor
        data={field.value}
        editor={ClassicEditor}
        onChange={(event: any, editor: any) => {
          setFieldValue(field.name, editor.getData());
        }}
        onBlur={() => {
          if (!meta.touched) helpers.setTouched(true);
        }}
        config={{ extraPlugins: [DealUploadImagePlugin] }}
      />
      <FormHelperText>{helper}</FormHelperText>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
