import { Flex, Icon, Tr } from '@chakra-ui/react';
import { useGenericContext } from 'components/contexts/useGenericContext';
import { H1, Modal } from 'components/elements';
import { InvestmentForm } from 'components/forms';
import { ContractsForm } from 'components/forms/ContractsForm';
import { Td, TdActions, TdTag } from 'components/lib';
import { CustomTable, FormDrawer } from 'components/modules';
import {
  addErrorToast,
  addSuccessToast,
} from 'containers/ToastManager/store/slice';
import { FormikHelpers, FormikProps } from 'formik';
import { getId, getResults, isResultsEmpty } from 'helpers';
import fp from 'lodash/fp';
import { nanoid } from 'nanoid';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { RiFileEditFill } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { useToggle } from 'react-use';
import { ContractsResources } from 'services/resources/contracts';
import { IContractPayload } from 'services/resources/contracts/types.d';
import {
  IInvestmentPayload,
  IInvestmentResource,
} from 'services/resources/financial/investment/types.d';
import { IContractFormValues } from 'services/types.d';

import {
  getAccreditationDate,
  getAmount,
  getCompany,
  getCreated,
  getDepositDate,
  getFee,
  getGroupName,
  getInvestmentType,
  getName,
  getPromo,
  getStatus,
  getTotal,
  getTradeName,
  getUserEmail,
  HEADERS,
} from '../helpers';

export const Page: React.FC = (): JSX.Element => {
  const {
    active,
    defaultPatch,
    defaultRestore,
    isDrawerOpen,
    onPrepareDelete,
    onPrepareDrawer,
    registry,
    setRegistry,
    state,
    toggleDrawer,
  } = useGenericContext<IInvestmentResource>();

  const dispatch = useDispatch();

  const [isOpen, toggle] = useToggle(false);

  const { t } = useTranslation('contracts');

  const ref = useRef<FormikProps<IInvestmentPayload>>(null);

  const prepareFormValues = useCallback(
    () => ({
      ...registry,
      kyb: fp.get(['kyb', 'id'])(registry),
      user: fp.get(['user', 'id'])(registry),
      deal: fp.get(['deal', 'id'])(registry),
      investment_status: fp.get(['investment_status', 'id'])(registry),
      content_type: fp.get(['content_type', 'id'])(registry),
    }),
    [registry],
  );

  const prepareOnSubmit = useCallback(() => {
    if (ref?.current) ref?.current?.submitForm();
  }, [ref]);

  const handleOnSubmit = async (
    v: IContractFormValues,
    helpers?: FormikHelpers<IContractFormValues>,
  ) => {
    const { participation_constancy: pc, investment_attachment: ia } = v;
    try {
      const payload = new FormData();
      if (!fp.isNil(pc)) {
        payload.append('contract', pc as File, (pc as File).name);
        payload.append('investment', registry.id.toString());
        payload.append('contract_type', '1');
        payload.append('is_active', '1');
        await ContractsResources.create(payload as IContractPayload);
        payload.delete('contract');
      }
      if (!fp.isNil(ia)) {
        payload.append('contract', ia as File, (ia as File).name);
        payload.append('investment', registry.id.toString());
        payload.append('contract_type', '2');
        payload.append('is_active', '1');
        await ContractsResources.create(payload as IContractPayload);
        payload.delete('contract');
      }
      dispatch(addSuccessToast('toast.success.files'));
      toggle();
    } catch (err) {
      dispatch(addErrorToast('toast.errors.files.network'));
    } finally {
      helpers?.setSubmitting(false);
    }
  };

  return (
    <>
      <CustomTable
        isEmpty={isResultsEmpty(state)}
        headers={HEADERS}
        isLoading={state?.loading}
        mx={8}
      >
        {getResults(state)?.map((row: IInvestmentResource) => (
          <Tr key={nanoid()} position="relative">
            <Td>{getId(row)}</Td>
            <Td>{getName(row)}</Td>
            <Td>{getUserEmail(row)}</Td>
            <Td>{getCompany(row)}</Td>
            <Td>{getAmount(row)}</Td>
            <Td>{getTotal(row)}</Td>
            <Td>{getFee(row)}</Td>
            <Td>{getPromo(row)}</Td>
            <Td>{getTradeName(row)}</Td>
            <Td>{getGroupName(row)}</Td>
            <TdTag>{getStatus(row)}</TdTag>
            <TdTag>{getInvestmentType(row)}</TdTag>
            <Td>{getDepositDate(row)}</Td>
            <Td>{getAccreditationDate(row)}</Td>
            <Td>{getCreated(row)}</Td>
            <TdActions
              customButtonBg="orange"
              customButtonIcon={<Icon as={RiFileEditFill} color="white" />}
              isActive={active}
              onCustomCallback={() => {
                setRegistry(row);
                toggle();
              }}
              onDelete={() => onPrepareDelete(row)}
              onRestore={() => defaultRestore(row, { is_active: true })}
              onUpdate={() => onPrepareDrawer(row)}
            />
          </Tr>
        ))}
      </CustomTable>

      <Modal isOpen={isOpen} onClose={toggle}>
        <Flex
          direction="column"
          h="100%"
          justify="center"
          maxW="512px"
          mx="auto"
        >
          <H1 mb={12}>{t('form.modal.title')}</H1>
          <ContractsForm onSubmit={handleOnSubmit} />
        </Flex>
      </Modal>

      <FormDrawer
        isOpen={isDrawerOpen}
        isDisabled={!!ref?.current?.isSubmitting || !!ref?.current?.isValid}
        isLoading={!!ref.current?.isSubmitting}
        onClose={toggleDrawer}
        onSubmit={prepareOnSubmit}
      >
        <InvestmentForm
          initialValues={prepareFormValues()}
          onSubmit={defaultPatch}
          ref={ref}
        />
      </FormDrawer>
    </>
  );
};
