import { formatAmount, formatDate, getEmail, getUsername } from 'helpers';
import fp from 'lodash/fp';

export const getAccreditationDate = fp.compose(
  formatDate,
  fp.get('source_acc_date'),
);

export const getName = fp.compose(getUsername, fp.get('user'));

export const getUserEmail = fp.compose(getEmail, fp.get('user'));

export const getCompany = fp.get(['deal', 'company', 'name']);

export const getAmount = fp.compose(formatAmount, fp.get('amount'));

export const getTotal = fp.compose(formatAmount, fp.get('total'));

export const getFee = fp.compose(formatAmount, fp.get('fee'));

export const getPromo = fp.get('promo');

export const getStatus = fp.get(['investment_status', 'name']);

export const getDepositDate = fp.compose(formatDate, fp.get('deposit_date'));

export const getGroupName = fp.get('group');

export const getCreated = fp.compose(formatDate, fp.get('created'));

export const getInvestmentType = fp.get('investment_type');

export const getTradeName = fp.get(['kyb', 'trade_name']);
