import { ILogInMachineContext } from 'x-state/contexts';
import { assign, createMachine, StateMachine } from 'xstate';

import { LogInMachineEvent } from '../events';
import { LogInMachineNodes } from '../nodes';
import { LogInMachineSchema } from '../schemas';

export const getLogInMachine = (
  initial: string | symbol = LogInMachineNodes.IDLE,
): StateMachine<ILogInMachineContext, LogInMachineSchema, LogInMachineEvent> =>
  createMachine({
    id: 'logIn',
    context: { email: '' },
    initial,
    states: {
      [LogInMachineNodes.IDLE]: {
        on: {
          NEXT: {
            target: LogInMachineNodes.VERIFIED,
            actions: assign((_, state) => ({ ...state })),
          },
        },
      },
      [LogInMachineNodes.VERIFIED]: {
        type: 'final',
      },
    },
  });
