import { Flex, FlexProps } from '@chakra-ui/react';
import { H1 } from 'components/elements';

export interface IEmptyStateProps extends FlexProps {
  /**
   * Empty state i18n label.
   */
  description: string;
}

export const EmptyState: React.FC<IEmptyStateProps> = (props): JSX.Element => {
  const { description, ...rest } = props;
  return (
    <Flex {...rest}>
      <H1 fontSize="md">{description}</H1>
    </Flex>
  );
};

EmptyState.defaultProps = {
  align: 'center',
  direction: 'column',
  justify: 'center',
};
