import {
  IAuthSliceState,
  makeSelectIsAuthenticated,
  makeSelectIsNotAuthenticated,
} from 'containers/AuthManager/store';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';

const locationHelper = locationHelperBuilder({});

export const withAuthenticatedRedir = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: (state) =>
    makeSelectIsAuthenticated(state as IAuthSliceState),
  wrapperDisplayName: 'UserIsAuthenticated',
});

export const withNotAuthenticatedRedir = connectedRouterRedirect({
  redirectPath: (_, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || '/',

  allowRedirectBack: false,
  authenticatedSelector: (state) =>
    makeSelectIsNotAuthenticated(state as IAuthSliceState),
  wrapperDisplayName: 'UserIsNotAuthenticated',
});
