import { instance } from 'services';
import { IFindFnReturn, IQueryParams, IResponse } from 'services/types.d';

import { IReadOnlyResource } from './types.d';

export const BankResources = {
  findAll: (params?: IQueryParams): Promise<IFindFnReturn<IReadOnlyResource>> =>
    instance.get('/bank/', { params }),

  findByID: (id: number | string): Promise<IResponse<IReadOnlyResource>> =>
    instance.get(`/bank/${id}`),
};
