import * as Yup from 'yup';

export const INITIAL_VALUES = {
  highlights: '',
  industry: '',
  oportunity: '',
  solution: '',
};

export const InvestmentMemoFormValidationSchema = Yup.object().shape({
  highlights: Yup.string(),
  industry: Yup.string().nullable(),
  oportunity: Yup.string().nullable(),
  solution: Yup.string().nullable(),
});
