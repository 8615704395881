import {
  FormControl,
  FormControlProps,
  FormLabelProps,
  Input,
  InputGroup,
} from '@chakra-ui/react';
import { FormErrorMessage, FormHelperText, FormLabel } from 'components/lib';
import { useField } from 'formik';
import { CSSProperties } from 'react';
import NumberFormat from 'react-number-format';
import { theme } from 'styles';

import { InputSize } from './helpers';

export interface IMoneyFieldProps extends FormControlProps {
  /**
   * Callback function that returns the current value of the field to its parent in every onChange action..
   */
  callback?: (v: number) => void;
  /**
   * Input helper text.
   */
  helper?: string;
  /**
   * Custom input props.
   * @type CSSProperties
   */
  inputProps?: CSSProperties;
  /**
   * Input label.
   */
  label?: string;
  /**
   * Label color.
   */
  labelProps?: FormLabelProps;
  /**
   * Input name.
   */
  name: string;
  /**
   * Input placeholder.
   */
  placeholder?: string;
}

export const MoneyField: React.FC<IMoneyFieldProps> = (props): JSX.Element => {
  const {
    callback = () => {},
    helper,
    inputProps = { background: theme.colors.blue[50] },
    label,
    labelProps,
    name,
    placeholder,
    size = 'sm',
    ...rest
  } = props;

  const [field, meta, helpers] = useField(name);

  return (
    <FormControl isInvalid={!!meta.error} name={name} {...rest}>
      <FormLabel fontSize={size} htmlFor={name} {...labelProps}>
        {label}
      </FormLabel>
      <InputGroup>
        <NumberFormat
          allowNegative={false}
          customInput={Input}
          value={field.value}
          onValueChange={({ floatValue }) => {
            if (!meta.touched) helpers.setTouched(true);
            helpers.setValue(floatValue);
            callback((floatValue as number) || 0);
          }}
          placeholder={placeholder}
          prefix="$"
          style={{ ...inputProps, height: InputSize[size] }}
          thousandSeparator
        />
      </InputGroup>
      <FormHelperText>{helper}</FormHelperText>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
