import { CheckboxField, EmailField, InputField } from 'components/inputs';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IReferencePayload } from 'services/resources/users/reference/types.d';

import { ReferenceFormValidationSchema } from './helpers';

export interface IReferenceFormProps {
  /**
   * Form initial values, can be a complete or partial ´IReferencePayload´
   * object which will allow to support create and update requests.
   */
  initialValues: IReferencePayload;
  /**
   * On submit action, for this form this will be controlled from the outside
   * of this component thanks to the forward ref property.
   */
  onSubmit: (
    v: IReferencePayload,
    h?: FormikHelpers<IReferencePayload>,
  ) => void;
}

export const ReferenceForm = forwardRef<
  FormikProps<IReferencePayload>,
  IReferenceFormProps
>((props, ref) => {
  const { initialValues, onSubmit } = props;
  const { t } = useTranslation('common');

  return (
    <Formik
      initialValues={{ ...initialValues }}
      innerRef={ref}
      onSubmit={onSubmit}
      validationSchema={ReferenceFormValidationSchema}
    >
      <Form>
        <InputField label={t('inputs.name.label')} mb={4} name="name" />
        <InputField
          label={t('inputs.first-surname.label')}
          mb={4}
          name="first_surname"
        />
        <InputField
          label={t('inputs.last-surname.label')}
          mb={4}
          name="last_surname"
        />
        <EmailField mb={4} name="email" />
        <CheckboxField label={t('inputs.is-active.label')} name="is_active" />
      </Form>
    </Formik>
  );
});

ReferenceForm.displayName = 'ReferenceForm';
