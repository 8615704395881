import { CheckboxField, InputField } from 'components/inputs';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IDealStatusPayload } from 'services/resources/deal-flow/deal-status/types.d';

import { DealStatusFormValidationSchema } from './helpers';

export interface IDealStatusFormProps {
  /**
   * Form initial values, can be a complete or partial IDealStatusPayload
   * object which will allow to support create and update requests.
   */
  initialValues: IDealStatusPayload;
  /**
   * On submit action, for this form this will be controlled from the outside
   * of this component thanks to the forward ref property.
   */
  onSubmit: (
    v: IDealStatusPayload,
    h?: FormikHelpers<IDealStatusPayload>,
  ) => void;
}

export const DealStatusForm = forwardRef<
  FormikProps<IDealStatusPayload>,
  IDealStatusFormProps
>((props, ref) => {
  const { initialValues, onSubmit } = props;
  const { t } = useTranslation(['deal-status', 'common']);

  return (
    <Formik
      initialValues={{ ...initialValues }}
      innerRef={ref}
      onSubmit={onSubmit}
      validationSchema={DealStatusFormValidationSchema}
    >
      <Form>
        <InputField
          label={t('inputs.name.label', { ns: 'common' })}
          mb={4}
          name="name"
        />
        <InputField label={t('form.input.code.label')} mb={4} name="code" />
        <CheckboxField
          label={t('inputs.is-active.label', { ns: 'common' })}
          name="is_active"
        />
      </Form>
    </Formik>
  );
});

DealStatusForm.displayName = 'DealStatusForm';
