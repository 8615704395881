import { Box, BoxProps, Icon, Table, Tbody, Thead, Tr } from '@chakra-ui/react';
import { Td, TdActions, Th } from 'components/lib';
import { getAccountNumber, getBankName, getId, getStatement } from 'helpers';
import BlankInformation from 'images/blank-information.png';
import fp from 'lodash/fp';
import { nanoid } from 'nanoid';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MdFileDownload } from 'react-icons/md';
import { IBankStatementResource } from 'services/resources/financial/bank-statements/types.d';

import { EmptyBanner } from './EmptyBanner';

export interface IBankStatementsProps extends BoxProps {
  /**
   * The bank statements related to a user.
   */
  statements?: IBankStatementResource[];
}

export const BankStatements: React.FC<IBankStatementsProps> = (
  props,
): JSX.Element => {
  const { statements, ...rest } = props;

  const { t } = useTranslation('kyc-physical');

  const handleOnDownload = useCallback((row: IBankStatementResource) => {
    window.open(getStatement(row));
  }, []);

  if (fp.isEmpty(statements)) {
    return (
      <EmptyBanner
        cover={BlankInformation}
        mb={6}
        title={t('page.detail.bank.statements.empty.title')}
      />
    );
  }

  return (
    <Box {...rest}>
      <Table colorScheme="blackAlpha" variant="striped">
        <Thead>
          <Tr>
            <Th>{t('page.detail.table.title.id')}</Th>
            <Th>{t('page.detail.table.title.bank')}</Th>
            <Th>{t('page.detail.table.title.account-number')}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {statements?.map((row: IBankStatementResource) => (
            <Tr key={nanoid()}>
              <Td>{getId(row)}</Td>
              <Td>{getBankName(row)}</Td>
              <Td>{getAccountNumber(row)}</Td>
              <TdActions
                customButtonBg="orange"
                customButtonIcon={<Icon as={MdFileDownload} color="white" />}
                isActive
                onCustomCallback={() => handleOnDownload(row)}
                onlyCustomAction
              />
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

BankStatements.defaultProps = {
  overflow: 'scroll',
};
