export const HEADERS = [
  'HEADER.ID',
  'HEADER.COMPANY',
  'HEADER.VALUATION',
  'HEADER.NUMBER',
  'HEADER.DILUTION',
  'HEADER.EXCHANGE',
  'HEADER.VALUATION_TYPE',
  'HEADER.CREATED',
];
