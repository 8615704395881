import { IQueryParams } from 'services/types.d';

/**
 * Default HTTP query parameters, it will filter the data by its active
 * attribute and the ordering will be descendant.
 */
export const DEFAULT_QUERY_PARAMS: IQueryParams = {
  is_active: true,
  ordering: '-id',
  page: 1,
};
