import { Button, Flex, FlexProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export interface IDealFormButtonsProps extends FlexProps {
  /**
   * If `true`, then the button will be disabled due to an error state or a
   * submitting state.
   */
  isDisabled?: boolean;
  /**
   * If `true`, then the button will render a spinner due to a submitting
   * state.
   */
  isLoading?: boolean;
  /**
   * On click actions it will trigger the on submit event.
   */
  onSubmit: () => void;
}

export const DealFormButtons: React.FC<IDealFormButtonsProps> = (
  props,
): JSX.Element => {
  const { isDisabled = false, isLoading = false, onSubmit, ...rest } = props;

  const { t } = useTranslation('common');

  return (
    <Flex {...rest}>
      <Button
        colorScheme="blue"
        isDisabled={isDisabled}
        isLoading={isLoading}
        onClick={onSubmit}
        px={8}
        size="sm"
        type="button"
      >
        {t('button.continue')}
      </Button>
    </Flex>
  );
};

DealFormButtons.defaultProps = {
  direction: { base: 'column', md: 'row' },
  justify: 'flex-end',
};
