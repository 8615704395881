import Cookies, { CookiesStatic } from 'js-cookie';
import { IAuthTokens } from 'services/resources/auth/types.d';

export const SessionCookies = {
  destroy: (): void => {
    Cookies.remove('session');
  },
  load: (): CookiesStatic<IAuthTokens> => Cookies.getJSON('session'),
  store: (session: IAuthTokens): void => {
    Cookies.set('session', session);
  },
};
