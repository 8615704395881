import { isValidFileSize, isValidPDFFileFormat } from 'helpers';
import fp from 'lodash/fp';
import * as Yup from 'yup';

export const CompanyReportFormValidationSchema = Yup.object().shape({
  // quarter: Yup.string().required('errors.required'),

  report: Yup.mixed()
    .test('file-size', 'errors.file-size', (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileSize(v.size);
    })
    .test('file-format', 'errors.pdf-file-format', (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidPDFFileFormat(v.type);
    }),

  // year: Yup.string()
  //   .max(4, 'errors.max')
  //   .min(4, 'errors.min')
  //   .required('errors.required'),
});
