import { Box, BoxProps, Text } from '@chakra-ui/react';
import fp from 'lodash/fp';

export interface IRecordProps extends BoxProps {
  /**
   * Record description.
   */
  description?: null | number | string;
  /**
   * Record title.
   */
  title: string;
}

export const Record: React.FC<IRecordProps> = (props): JSX.Element => {
  const { description, title, ...rest } = props;

  return (
    <Box {...rest}>
      <Text fontSize="sm" fontWeight="bold">
        {title}
      </Text>
      <Text color="blackAlpha.700" fontWeight="medium">
        {!fp.isNil(description) ? description : '-'}
      </Text>
    </Box>
  );
};
