import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
} from '@chakra-ui/react';
import Art from 'images/logo.svg';
import { useTranslation } from 'react-i18next';

import { Logo } from './components/Logo';

export interface IFormDrawerProps extends DrawerProps {
  /**
   * If `true`, then the submit button will be disabled.
   */
  isDisabled: boolean;
  /**
   * If `true`, then the submit button will render a loading
   * Spinner.
   */
  isLoading: boolean;
  /**
   * If `true`, the component will be open.
   */
  isOpen: boolean;
  /**
   * If `true`, the component will be open.
   */
  adminPage?: boolean;
  /**
   * On click actions, this function clears the state and closes the drawer.
   */
  onClose: () => void;
  /**
   * On click actions, this function will invoke the formik on submit form
   * event.
   */
  onSubmit: () => void;
}

export const FormDrawer: React.FC<IFormDrawerProps> = (props): JSX.Element => {
  const {
    children,
    isDisabled,
    isLoading,
    isOpen,
    onClose,
    onSubmit,
    adminPage = false,
    ...rest
  } = props;

  const { t } = useTranslation('common');

  return (
    <Drawer isOpen={isOpen} onClose={onClose} {...rest}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Logo alt="logo" src={Art} />
        </DrawerHeader>

        <DrawerBody>{children}</DrawerBody>

        <DrawerFooter d="flex" flexDir="column">
          {!adminPage ? (
            <>
              <Button
                colorScheme="blue"
                isDisabled={isDisabled}
                isFullWidth
                isLoading={isLoading}
                mb={2}
                onClick={onSubmit}
                size="sm"
                type="submit"
              >
                {t('button.save')}
              </Button>
              <Button isFullWidth onClick={onClose} size="sm" variant="outline">
                {t('button.cancel')}
              </Button>
            </>
          ) : null}
        </DrawerFooter>

        <DrawerCloseButton />
      </DrawerContent>
    </Drawer>
  );
};

FormDrawer.defaultProps = {
  placement: 'right',
  size: 'sm',
};
