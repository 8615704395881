import {
  Button,
  FormControl,
  FormControlProps,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';
import { FormErrorMessage, FormHelperText, FormLabel } from 'components/lib';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

export interface IPasswordFieldProps extends FormControlProps {
  /**
   * Input helper text.
   */
  helper?: string;
  /**
   * Custom input props.
   */
  inputProps?: InputProps;
  /**
   * Input name.
   */
  name: string;
  /**
   * Input placeholder.
   */
  placeholder?: string;
}

export const PasswordField: React.FC<IPasswordFieldProps> = (
  props,
): JSX.Element => {
  const {
    helper,
    inputProps = { bg: 'blue.50' },
    name,
    placeholder,
    size = 'sm',
    ...rest
  } = props;

  const [field, meta] = useField(name);

  const [show, toggle] = useToggle(false);

  const { t } = useTranslation('common');

  const isInvalid = !!meta.error && !!meta.touched;

  return (
    <FormControl isInvalid={isInvalid} name={name} {...rest}>
      <FormLabel fontSize={size} htmlFor={name}>
        {t('inputs.password.label')}
      </FormLabel>
      <InputGroup>
        <Input
          placeholder={placeholder}
          size={size}
          type={show ? 'text' : 'password'}
          {...inputProps}
          {...field}
        />
        <InputRightElement width={20}>
          <Button colorScheme="blue" onClick={toggle} size="xs" variant="ghost">
            {t(`inputs.password.button.${show ? 'hide' : 'show'}`)}
          </Button>
        </InputRightElement>
      </InputGroup>
      <FormHelperText>{helper}</FormHelperText>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
