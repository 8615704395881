import { Icon, Tr } from '@chakra-ui/react';
import { useGenericContext } from 'components/contexts/useGenericContext';
import { BankStatementForm } from 'components/forms';
import { Td, TdActions } from 'components/lib';
import { CustomTable, FormDrawer } from 'components/modules';
import {
  addErrorToast,
  addSuccessToast,
} from 'containers/ToastManager/store/slice';
import { FormikHelpers, FormikProps } from 'formik';
import {
  getAccountNumber,
  getBankName,
  getId,
  getResults,
  getStatement,
  getUserEmail,
  isResultsEmpty,
} from 'helpers';
import fp from 'lodash/fp';
import { nanoid } from 'nanoid';
import { useCallback, useRef } from 'react';
import { MdFileDownload } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { BankStatementResources } from 'services/resources/financial/bank-statements';
import {
  IBankStatementPayload,
  IBankStatementResource,
} from 'services/resources/financial/bank-statements/types.d';

import { HEADERS } from '../helpers';

export const Page: React.FC = (): JSX.Element => {
  const {
    active,
    defaultRestore,
    doFetch,
    isDrawerOpen,
    onPrepareDelete,
    onPrepareDrawer,
    params,
    registry,
    state,
    toggleDrawer,
  } = useGenericContext<IBankStatementResource>();

  const ref = useRef<FormikProps<IBankStatementPayload>>(null);

  const dispatch = useDispatch();

  const handleOnDownload = useCallback((row: IBankStatementResource) => {
    window.open(getStatement(row));
  }, []);

  const prepareFormValues = useCallback(
    () => ({
      ...registry,
      bank: fp.get(['bank', 'id'])(registry),
      user: fp.get(['user', 'id'])(registry),
    }),
    [registry],
  );

  const prepareOnSubmit = useCallback(() => {
    if (ref?.current) ref?.current?.submitForm();
  }, [ref]);

  const handleOnSubmit = useCallback(
    async (
      { statement: s, ...v }: IBankStatementPayload,
      helpers?: FormikHelpers<IBankStatementPayload>,
    ) => {
      try {
        const p = new FormData();
        if (!fp.isString(s)) p.append('statement', s as File, (s as File).name);

        fp.compose(
          fp.each((k: string) => p.append(k, v[k])),
          fp.keys,
          fp.omitBy(fp.isNil),
        )(v);

        if (fp.compose(fp.isNil, getId)(v)) {
          await BankStatementResources.create(p as IBankStatementPayload);
        } else {
          await BankStatementResources.update(
            getId(v),
            p as IBankStatementPayload,
          );
        }

        await doFetch(params);

        dispatch(addSuccessToast('toast.success.files'));
        toggleDrawer();
      } catch (err) {
        dispatch(addErrorToast('toast.errors.files.network'));
      } finally {
        helpers?.setSubmitting(false);
      }
    },
    [],
  );

  return (
    <>
      <CustomTable
        isEmpty={isResultsEmpty(state)}
        headers={HEADERS}
        isLoading={state?.loading}
        mx={8}
      >
        {getResults(state)?.map((row: IBankStatementResource) => (
          <Tr key={nanoid()} position="relative">
            <Td>{getId(row)}</Td>
            <Td>{getUserEmail(row)}</Td>
            <Td>{getBankName(row)}</Td>
            <Td>{getAccountNumber(row)}</Td>
            <TdActions
              customButtonBg="orange"
              customButtonIcon={<Icon as={MdFileDownload} color="white" />}
              isActive={active}
              onCustomCallback={() => handleOnDownload(row)}
              onDelete={() => onPrepareDelete(row)}
              onRestore={() => defaultRestore(row, { is_active: true })}
              onUpdate={() => onPrepareDrawer(row)}
            />
          </Tr>
        ))}
      </CustomTable>

      <FormDrawer
        isOpen={isDrawerOpen}
        isDisabled={!!ref?.current?.isSubmitting || !!ref?.current?.isValid}
        isLoading={!!ref.current?.isSubmitting}
        onClose={toggleDrawer}
        onSubmit={prepareOnSubmit}
      >
        <BankStatementForm
          initialValues={prepareFormValues()}
          onSubmit={handleOnSubmit}
          ref={ref}
        />
      </FormDrawer>
    </>
  );
};
