import { Flex, FlexProps, Image, Skeleton, Stack } from '@chakra-ui/react';
import NotFoundCover from 'images/not-found.jpeg';
import { useTranslation } from 'react-i18next';
import { IKYBMoralPersonResource } from 'services/resources/users/kyb-moral/types.d';

import {
  getAvatar,
  getFirstSurname,
  getId,
  getLastSurname,
  getName,
} from '../helpers';
import { Record } from './Record';

export interface ILegalRepresentativeInformationProps extends FlexProps {
  /**
   * KYB moral user resource data.
   */
  kyb?: IKYBMoralPersonResource;
}
export const LegalRepresentativeInformation: React.FC<ILegalRepresentativeInformationProps> =
  (props): JSX.Element | null => {
    const { kyb, ...rest } = props;

    const { t } = useTranslation('kyb-moral');

    return (
      <Flex {...rest}>
        <Image
          alt="life-proof"
          fallback={<Skeleton h={32} rounded="md" w={32} />}
          fit="cover"
          h={32}
          src={getAvatar(kyb) || NotFoundCover}
          rounded="md"
          w={32}
        />
        <Stack pl={{ base: 0, md: 12 }} py={{ base: 6, md: 0 }}>
          <Record
            description={getName(kyb)}
            id={getId(kyb)}
            isEditable
            name="name"
            title={t(
              'page.detail.company-information.legal-representative.name.title',
            )}
          />

          <Stack isInline spacing={12}>
            <Record
              description={getFirstSurname(kyb)}
              id={getId(kyb)}
              isEditable
              name="first_surname"
              title={t(
                'page.detail.company-information.legal-representative.first-surname.title',
              )}
            />
            <Record
              description={getLastSurname(kyb)}
              id={getId(kyb)}
              isEditable
              name="last_surname"
              title={t(
                'page.detail.company-information.legal-representative.last-surname.title',
              )}
            />
          </Stack>
        </Stack>
      </Flex>
    );
  };

LegalRepresentativeInformation.defaultProps = {
  direction: 'row',
};
