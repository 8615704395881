import { Flex, FlexProps } from '@chakra-ui/react';
import { H1, IModalProps, Loading, Modal } from 'components/elements';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import { FinerioResources } from 'services/resources/finerio';
import { IFinerioAccountResource } from 'services/resources/finerio/types.d';

import { CreditInformation } from './components/CreditInformation';
import { GeneralInformation } from './components/GeneralInformation';
import { Transactions } from './components/Transactions';
import { getDetails, getTransactions } from './helpers';

export interface ITransactionsModalProps extends FlexProps, IModalProps {
  /**
   * Finerio connect account resource.
   * @type {IFinerioAccountResource}
   */
  registry?: IFinerioAccountResource;
}

export const TransactionsModal: React.FC<ITransactionsModalProps> = (
  props,
): JSX.Element => {
  const { isOpen, onClose, registry, ...rest } = props;

  const { t } = useTranslation('finerio');

  const { loading, value } = useAsync(async () => {
    const { data: details } = await FinerioResources.findAccountDetails(
      registry?.id as string,
    );
    const { data } = await FinerioResources.findTransactionsByAccount({
      account_id: registry?.id,
    });

    return { transactions: [...data?.data], details };
  }, [registry]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {loading ? (
        <Loading isLoading />
      ) : (
        <Flex {...rest}>
          <H1 fontSize="2xl" mb={8} textTransform="uppercase">
            {t('modal.title')}
          </H1>

          <GeneralInformation details={getDetails(value)} mb={12} />
          <CreditInformation details={getDetails(value)} mb={12} />
          <Transactions transactions={getTransactions(value)} w="100%" />
        </Flex>
      )}
    </Modal>
  );
};

TransactionsModal.defaultProps = {
  align: 'center',
  direction: 'column',
  h: '100%',
  justify: 'center',
  maxW: '1024px',
  mx: 'auto',
  py: 12,
  w: '100%',
};
