import { Flex, FlexProps } from '@chakra-ui/react';

export interface IFooterOptionsContainerProps extends FlexProps {
  /**
   * If `true`, the children elements will show.
   */
  isVisible: boolean;
}

export const FooterOptionsContainer: React.FC<IFooterOptionsContainerProps> = (
  props,
): JSX.Element | null => {
  const { isVisible, children, ...rest } = props;

  if (!isVisible) {
    return null;
  }

  return <Flex {...rest}>{children}</Flex>;
};

FooterOptionsContainer.defaultProps = {
  direction: { base: 'column', sm: 'row' },
  justifyContent: 'space-between',
};
