import { formatDate } from 'helpers';
import fp from 'lodash/fp';

export const getUserFullName = fp.compose(
  fp.trim,
  fp.join(' '),
  fp.values,
  fp.omitBy(fp.isNil),
  fp.pick(['name', 'first_surname', 'last_surname']),
);

export const getRisks = fp.get('risks');

export const getCompletedKYC = fp.get('is_kyc_completed');

export const getCreated = fp.compose(formatDate, fp.get('created'));
