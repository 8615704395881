export const HEADERS = [
  'HEADER.ID',
  'HEADER.NAME',
  'HEADER.IS_ACTIVE',
  'HEADER.CREATED',
];

export const HEADERS_WITH_CODE = [
  'HEADER.ID',
  'HEADER.NAME',
  'HEADER.CODE',
  'HEADER.IS_ACTIVE',
  'HEADER.CREATED',
];
