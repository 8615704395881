import { Box, BoxProps, SimpleGrid } from '@chakra-ui/react';
import BlankInformation from 'images/blank-information.png';
import { useTranslation } from 'react-i18next';
import { IKYCPhysicalResource } from 'services/resources/users/kyc-physical/types.d';

import {
  getId,
  getInvestmentReason,
  getInvestorConsent,
  getKycStage,
  getMonthlyAmount,
  getMonthlyOperations,
  getOperationReason,
  KYCStagesEnum,
  trimTextToWords,
} from '../helpers';
import { EmptyBanner } from './EmptyBanner';
import { Record } from './Record';

export interface IOperationInformationProps extends BoxProps {
  /**
   * KYC physical user resource data.
   */
  kyc?: IKYCPhysicalResource;
}

export const OperationInformation: React.FC<IOperationInformationProps> = (
  props,
): JSX.Element => {
  const { kyc, ...rest } = props;

  const { t } = useTranslation('kyc-physical');

  if (getKycStage(kyc) < KYCStagesEnum.ADDRESS_INFORMATION_SUCCESS) {
    return (
      <EmptyBanner
        cover={BlankInformation}
        mb={6}
        title={t('page.detail.address.empty.title')}
      />
    );
  }

  return (
    <Box {...rest}>
      <SimpleGrid columns={[1, 1, 2, 3]} spacingY={6}>
        <Record
          description={trimTextToWords(getInvestmentReason(kyc), 13)}
          id={getId(kyc)}
          isEditable
          name="investment_reason"
          title={t('page.detail.operation.investment-reason.title')}
        />

        <Record
          description={getInvestorConsent(kyc)}
          id={getId(kyc)}
          isEditable
          name="investment_consent"
          title={t('page.detail.operation.investor-consent.title')}
        />

        <Record
          description={getOperationReason(kyc)}
          id={getId(kyc)}
          isEditable
          name="operation_reason"
          title={t('page.detail.operation.reason.title')}
        />

        <Record
          description={getMonthlyAmount(kyc)}
          id={getId(kyc)}
          isEditable
          name="monthly_amount"
          title={t('page.detail.operation.monthly-amount.title')}
        />

        <Record
          description={getMonthlyOperations(kyc)}
          id={getId(kyc)}
          isEditable
          name="monthly_operations"
          title={t('page.detail.operation.monthly-operations.title')}
        />
      </SimpleGrid>
    </Box>
  );
};
