import { Box, BoxProps, Spinner } from '@chakra-ui/react';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { IChart } from 'types.d';

export interface IChartProps extends BoxProps {
  /**
   * Data that the chart will show.
   */
  data: IChart[];
  /**
   * Chart data that will show the axis X information.
   */
  dataKeyX: string;
  /**
   * Chart data that will show the axis Y information.
   */
  dataKeyY: string;
  /**
   * Chart height.
   */
  height: number;
  /**
   * If `true` the page will display a spinner component.
   */
  isLoading: boolean;
  /**
   * Chart margin
   */
  margin?: { bottom?: number; left?: number; right?: number; top?: number };
  /**
   * Chart color.
   */
  stroke: string;
  /**
   * Chart width.
   */
  width?: string;
}

export const Chart: React.FC<IChartProps> = (props): JSX.Element => {
  const {
    data,
    dataKeyY,
    dataKeyX,
    height,
    id,
    isLoading,
    margin,
    stroke,
    width = '100%',
    ...rest
  } = props;

  if (isLoading) return <Spinner mx={6} my={4} />;

  return (
    <Box {...rest}>
      <ResponsiveContainer height={height} width={width}>
        <AreaChart data={data} margin={margin}>
          <defs>
            <linearGradient id={id} x1="0" x2="0" y2="1" y1="0">
              <stop offset="5%" stopColor={stroke} stopOpacity={0.8} />
              <stop offset="95%" stopColor={stroke} stopOpacity={0} />
            </linearGradient>
          </defs>
          <Tooltip />
          <XAxis dataKey={dataKeyX} hide />
          <Area
            dataKey={dataKeyY}
            fillOpacity={1}
            fill={`url(#${id})`}
            stroke={stroke}
            type="monotone"
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};
