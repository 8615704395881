import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { ListingPage } from './pages';

export const KYCHistoryPages: React.FC = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route component={ListingPage} exact path={path} />
      {/* <Route component={DetailPage} path={`${path}/:slug`} /> */}
    </Switch>
  );
};
