import { isValidFileFormat, isValidFileSize } from 'helpers';
import fp from 'lodash/fp';
import * as Yup from 'yup';

export const GeneralFormValidationSchema = Yup.object().shape({
  company: Yup.string().required('errors.required'),
  cover: Yup.mixed()
    .test('file-size', 'errors.file-size', (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileSize(v.size);
    })
    .test('file-format', 'errors.file-format', (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileFormat(v.type);
    })
    .required('errors.required'),
  deadline: Yup.string()
    .matches(/[0-9]{4}-[0-9]{2}-[0-9]{2}/, 'errors.date')
    .required('errors.required'),
  podcast: Yup.string().url().nullable(),
  slug: Yup.string()
    .matches(/^[a-z0-9-]+$/, 'errors.slug')
    .required('errors.required'),
  deal_status: Yup.string().required('errors.required'),
});
