import {
  Box,
  BoxProps,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Icon,
  Radio,
  RadioGroup,
  Stack,
  Stat,
  StatHelpText,
  StatLabel,
  Text,
} from '@chakra-ui/react';
import { Modal } from 'components/elements';
import { RichElement } from 'components/elements/RichElements';
import {
  addErrorToast,
  addSuccessToast,
} from 'containers/ToastManager/store/slice';
import fp from 'lodash/fp';
import { useCallback } from 'react';
import { Trans } from 'react-i18next';
import { RiFileEditFill } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { useToggle } from 'react-use';
import { KYCPhysicalResources } from 'services/resources/users/kyc-physical';

export interface IRecordProps extends Omit<BoxProps, 'id'> {
  /**
   * Description that the component will display.
   */
  description: string;
  /**
   * KYC unique identifier.
   */
  id?: number;
  /**
   * If `true`, then the record will be editable.
   */
  isEditable?: boolean;
  /**
   * Name of the registry in DB on the KYC Physical Person model.
   */
  name?: string;
  /**
   * Title that the component will display.
   */
  title: string;
  /**
   * Title that the component will display.
   */
  isLink?: boolean;
  /**
   * Title that the component will display.
   */
  username?: string;
  /**
   * Title that the component will display.
   */
  accreditationDate?: string;
}

export const Record: React.FC<IRecordProps> = (props): JSX.Element => {
  const {
    description,
    id = 0,
    isEditable = false,
    name = '',
    title,
    isLink = false,
    username = '',
    accreditationDate = '',
    ...rest
  } = props;

  const dispatch = useDispatch();

  const [isOpen, toggle] = useToggle(false);

  const handleClick = (event) => {
    event.preventDefault();
    toggle();
  };

  const handleOnSubmit = useCallback(async (v: string) => {
    try {
      if (v === description || v === '-' || fp.isEmpty(v)) return;
      await KYCPhysicalResources.update(id, { [name]: v });
      dispatch(addSuccessToast('toast.success.network'));
    } catch (e) {
      dispatch(addErrorToast('toast.errors.network'));
    }
  }, []);

  const isFallback = fp.isNil(description) || fp.isEmpty(description);

  return (
    <>
      <Box {...rest}>
        <Text fontSize="sm" fontWeight="bold">
          {title}
        </Text>
        <Editable
          color="blackAlpha.700"
          defaultValue={!isFallback ? description : 'NA'}
          fontSize="md"
          fontWeight="medium"
          isDisabled={!isEditable}
          onSubmit={handleOnSubmit}
        >
          <EditablePreview cursor={isEditable ? 'pointer' : 'not-allowed'} />
          <EditableInput cursor={isEditable ? 'text' : 'not-allowed'} />
        </Editable>
        {isLink ? (
          <>
            <a href="https://google.com" onClick={handleClick}>
              <Icon as={RiFileEditFill} color="black" />
            </a>

            <Modal isOpen={isOpen} onClose={toggle}>
              <Flex
                direction="column"
                h="100%"
                justify="center"
                maxW="512px"
                mx="auto"
                mt={4}
                textAlign="left"
              >
                <Box {...rest}>
                  <RichElement fontSize="xs">
                    <Trans
                      i18nKey="compliance.stage.risks.contancy"
                      ns="common"
                      values={{ username }}
                    />
                  </RichElement>
                  <RichElement fontSize="xs" mt={4}>
                    <Trans
                      i18nKey="compliance.stage.risks.loss"
                      ns="common"
                      values={{ username, accreditationDate }}
                    />
                  </RichElement>

                  <RadioGroup defaultValue="1">
                    <Stack>
                      <Radio value="1" isDisabled>
                        Yes
                      </Radio>
                      <Radio value="2" isDisabled>
                        No
                      </Radio>
                    </Stack>
                  </RadioGroup>

                  <RichElement fontSize="xs" mt={4}>
                    <Trans
                      i18nKey="compliance.stage.risks.liquidity"
                      ns="common"
                      values={{ username, accreditationDate }}
                    />
                  </RichElement>

                  <RadioGroup defaultValue="1">
                    <Stack>
                      <Radio value="1" isDisabled>
                        Yes
                      </Radio>
                      <Radio value="2" isDisabled>
                        No
                      </Radio>
                    </Stack>
                  </RadioGroup>

                  <RichElement fontSize="xs" mt={4}>
                    <Trans
                      i18nKey="compliance.stage.risks.information"
                      ns="common"
                      values={{ username, accreditationDate }}
                    />
                  </RichElement>

                  <RadioGroup defaultValue="1">
                    <Stack>
                      <Radio value="1" isDisabled>
                        Yes
                      </Radio>
                      <Radio value="2" isDisabled>
                        No
                      </Radio>
                    </Stack>
                  </RadioGroup>

                  <RichElement fontSize="xs" mt={4}>
                    <Trans
                      i18nKey="compliance.stage.risks.performance"
                      ns="common"
                      values={{ username, accreditationDate }}
                    />
                  </RichElement>

                  <RadioGroup defaultValue="1">
                    <Stack>
                      <Radio value="1" isDisabled>
                        Yes
                      </Radio>
                      <Radio value="2" isDisabled>
                        No
                      </Radio>
                    </Stack>
                  </RadioGroup>

                  <RichElement fontSize="xs" mt={4}>
                    <Trans
                      i18nKey="compliance.stage.risks.approval"
                      ns="common"
                      values={{ username, accreditationDate }}
                    />
                  </RichElement>

                  <RadioGroup defaultValue="1">
                    <Stack>
                      <Radio value="1" isDisabled>
                        Yes
                      </Radio>
                      <Radio value="2" isDisabled>
                        No
                      </Radio>
                    </Stack>
                  </RadioGroup>

                  <RichElement fontSize="xs" mt={4}>
                    <Trans
                      i18nKey="compliance.stage.risks.advisory"
                      ns="common"
                      values={{ username, accreditationDate }}
                    />
                  </RichElement>

                  <RadioGroup defaultValue="1">
                    <Stack>
                      <Radio value="1" isDisabled>
                        Yes
                      </Radio>
                      <Radio value="2" isDisabled>
                        No
                      </Radio>
                    </Stack>
                  </RadioGroup>

                  <RichElement fontSize="xs" mt={4}>
                    <Trans
                      i18nKey="compliance.stage.risks.investment"
                      ns="common"
                      values={{ username, accreditationDate }}
                    />
                  </RichElement>

                  <RadioGroup defaultValue="1">
                    <Stack>
                      <Radio value="1" isDisabled>
                        Yes
                      </Radio>
                      <Radio value="2" isDisabled>
                        No
                      </Radio>
                    </Stack>
                  </RadioGroup>

                  <RichElement fontSize="xs" mt={4}>
                    <Trans
                      i18nKey="compliance.stage.risks.disclosed"
                      ns="common"
                      values={{ username, accreditationDate }}
                    />
                  </RichElement>

                  <RadioGroup defaultValue="1">
                    <Stack>
                      <Radio value="1" isDisabled>
                        Yes
                      </Radio>
                      <Radio value="2" isDisabled>
                        No
                      </Radio>
                    </Stack>
                  </RadioGroup>

                  <Stat mt={4}>
                    <StatLabel>signature Date</StatLabel>
                    {/* <StatNumber>{accreditationDate}</StatNumber> */}
                    <StatHelpText>{accreditationDate}</StatHelpText>
                  </Stat>
                </Box>
              </Flex>
            </Modal>
          </>
        ) : null}
      </Box>
    </>
  );
};
