import { Tr } from '@chakra-ui/react';
import { useGenericContext } from 'components/contexts/useGenericContext';
import { DealStatusForm } from 'components/forms';
import { Td, TdActions, TdTag } from 'components/lib';
import { CustomTable, FormDrawer } from 'components/modules';
import { FormikProps } from 'formik';
import { getId, getResults, isResultsEmpty } from 'helpers';
import { nanoid } from 'nanoid';
import { useCallback, useRef } from 'react';
import {
  IDealStatusPayload,
  IDealStatusResource,
} from 'services/resources/deal-flow/deal-status/types.d';

import { getCode, getCreated, getName, HEADERS } from '../helpers';

export const Page: React.FC = (): JSX.Element => {
  const {
    active,
    defaultPatch,
    defaultRestore,
    isDrawerOpen,
    onPrepareDelete,
    onPrepareDrawer,
    registry,
    state,
    toggleDrawer,
  } = useGenericContext<IDealStatusResource>();

  const ref = useRef<FormikProps<IDealStatusPayload>>(null);

  const prepareOnSubmit = useCallback(() => {
    if (ref?.current) ref?.current?.submitForm();
  }, [ref]);

  return (
    <>
      <CustomTable
        isEmpty={isResultsEmpty(state)}
        headers={HEADERS}
        isLoading={state?.loading}
        mx={8}
      >
        {getResults(state)?.map((row: IDealStatusResource) => (
          <Tr key={nanoid()} position="relative">
            <Td>{getId(row)}</Td>
            <Td>{getName(row)}</Td>
            <TdTag>{getCode(row)}</TdTag>
            <Td>{getCreated(row)}</Td>
            <TdActions
              isActive={active}
              onDelete={() => onPrepareDelete(row)}
              onRestore={() => defaultRestore(row, { is_active: true })}
              onUpdate={() => onPrepareDrawer(row)}
            />
          </Tr>
        ))}
      </CustomTable>

      <FormDrawer
        isOpen={isDrawerOpen}
        isDisabled={!!ref?.current?.isSubmitting || !!ref?.current?.isValid}
        isLoading={!!ref.current?.isSubmitting}
        onClose={toggleDrawer}
        onSubmit={prepareOnSubmit}
      >
        <DealStatusForm
          initialValues={{ ...registry }}
          onSubmit={defaultPatch}
          ref={ref}
        />
      </FormDrawer>
    </>
  );
};
