import {
  Flex,
  FlexProps,
  Image,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import NotFoundCover from 'images/not-found.jpeg';
import { Trans, useTranslation } from 'react-i18next';
import { IKYCPhysicalResource } from 'services/resources/users/kyc-physical/types.d';
import { IUserResource } from 'services/resources/users/user/types.d';

import {
  getAvatar,
  getFirstSurname,
  getId,
  getLastSurname,
  getLifeProof,
  getName,
  getRecordNumber,
} from '../helpers';
import { Record } from './Record';

export interface IHeaderInformationProps extends FlexProps {
  /**
   * KYC physical resource data.
   */
  kyc?: IKYCPhysicalResource;
  /**
   * User resource data.
   */
  user?: IUserResource;
}

export const HeaderInformation: React.FC<IHeaderInformationProps> = (
  props,
): JSX.Element => {
  const { kyc, user, ...rest } = props;

  const { t } = useTranslation('kyc-physical');

  return (
    <Flex {...rest}>
      <Image
        alt="life-proof"
        fallback={<Skeleton h={32} rounded="md" w={32} />}
        fit="cover"
        h={32}
        src={getLifeProof(kyc) || getAvatar(user) || NotFoundCover}
        rounded="md"
        w={32}
      />

      <Stack pl={{ base: 0, md: 12 }} py={{ base: 6, md: 0 }}>
        <Text fontSize="sm" fontWeight="bold">
          <Trans i18nKey="page.detail.header.record" ns="kyc-physical" />
          <Text as="span" color="blue">
            {getRecordNumber(kyc)}
          </Text>
        </Text>

        <Record
          description={getName(kyc)}
          id={getId(kyc)}
          isEditable
          name="name"
          title={t('page.detail.header.name')}
        />

        <Stack isInline spacing={12}>
          <Record
            description={getFirstSurname(kyc)}
            id={getId(kyc)}
            isEditable
            name="first_surname"
            title={t('page.detail.header.fisrt-surname')}
          />
          <Record
            description={getLastSurname(kyc)}
            id={getId(kyc)}
            isEditable
            name="last_surname"
            title={t('page.detail.header.last-surname')}
          />
        </Stack>
      </Stack>
    </Flex>
  );
};

HeaderInformation.defaultProps = {
  direction: { base: 'column', md: 'row' },
};
