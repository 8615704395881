import {
  Box,
  FormControl,
  FormControlProps,
  FormLabelProps,
  Image,
  Input,
} from '@chakra-ui/react';
import { FormErrorMessage, FormHelperText, FormLabel } from 'components/lib';
import { useField } from 'formik';
import fp from 'lodash/fp';
import { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomInput } from './components/CustomInput';
import { createImageURL, getImage, hasImage, isFromURL } from './helpers';

export interface IFileFieldProps extends FormControlProps {
  /**
   * If `true`, the component will show a preview of the image.
   */
  allowPreview?: boolean;
  /**
   * Input border color.
   */
  border?: string;
  /**
   * Input helpers message.
   */
  helper?: string;
  /**
   * Input label.
   */
  label?: string;
  /**
   * Label color.
   */
  labelProps?: FormLabelProps;
  /**
   * Input name.
   */
  name: string;
}

export const FileField: React.FC<IFileFieldProps> = (props): JSX.Element => {
  const {
    allowPreview = false,
    bg = 'blue.50',
    border = 'blackAlpha.100',
    helper = 'inputs.file.helpers',
    label,
    labelProps = {},
    name,
    size = 'sm',
    ...rest
  } = props;

  const [field, meta, helpers] = useField(name);

  const ref = useRef<HTMLInputElement>(null);

  const isInvalid = !!meta.error && !!meta.touched;

  const { t } = useTranslation('common');

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    helpers.setTouched(true);

    const file = fp.compose(fp.head, fp.get('target.files'))(e);
    const reader = new FileReader();
    reader.onloadend = () => {
      helpers.setValue(file);
    };
    reader.readAsDataURL(file);
  };

  const handleOnClear = () => {
    helpers.setValue(undefined);
  };

  const handleOnSelect = () => {
    fp.invoke('current.click')(ref);
  };

  return (
    <FormControl isInvalid={isInvalid} name={name} {...rest}>
      <FormLabel fontSize={size} htmlFor={name} {...labelProps}>
        {label}
      </FormLabel>
      {!hasImage(field) && allowPreview ? (
        <Box mb={4}>
          <Image
            alt="company-logo"
            fit="cover"
            h="100%"
            src={isFromURL(field) ? getImage(field) : createImageURL(field)}
            w="100%"
          />
        </Box>
      ) : null}

      <Input
        display="none"
        name={name}
        onChange={handleOnChange}
        ref={ref}
        type="file"
      />
      <CustomInput
        bg={bg}
        border={border}
        file={field.value}
        isInvalid={isInvalid}
        onClear={handleOnClear}
        onSelect={handleOnSelect}
        size={size}
      />
      <FormHelperText>{t(helper)}</FormHelperText>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
