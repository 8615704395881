import '../styles/index.css';

import { Box, BoxProps } from '@chakra-ui/react';
import ReactPaginate from 'react-paginate';

export interface IPaginationProps extends Omit<BoxProps, 'onClick'> {
  /**
   * On click event trigger when the user decides to change the current page.
   */
  onClick: (v: { selected: number }) => void;
  /**
   * Total of available pages for the current resource listing.
   */
  pages: number;
}

export const Pagination: React.FC<IPaginationProps> = (props): JSX.Element => {
  const { onClick, pages, ...rest } = props;

  return (
    <Box {...rest}>
      <ReactPaginate
        activeClassName="active"
        breakClassName="page-item"
        breakLabel="..."
        breakLinkClassName="page-link"
        containerClassName="pagination"
        marginPagesDisplayed={1}
        nextClassName="page-item"
        nextLabel=">"
        nextLinkClassName="page-link"
        onPageChange={onClick}
        pageClassName="page-item"
        pageCount={pages}
        pageLinkClassName="page-link"
        pageRangeDisplayed={1}
        previousClassName="page-item"
        previousLabel="<"
        previousLinkClassName="page-link"
      />
    </Box>
  );
};
