import { instance } from 'services';
import { IFindFnReturn, IQueryParams, IResponse } from 'services/types.d';

import { IUserGroupPayload, IUserGroupResource } from './types.d';

export const UserGroupResources = {
  findAll: (p?: IQueryParams): Promise<IFindFnReturn<IUserGroupResource>> =>
    instance.get('/group/get-user-group/', { params: p }),

  create: (params: IUserGroupPayload): Promise<IResponse<IUserGroupResource>> =>
    instance.post(`/group/get-user-group/`, params),

  update: (
    id: number,
    params: IUserGroupPayload,
  ): Promise<IResponse<IUserGroupResource>> =>
    instance.patch(`/group/get-user-group/${id}/`, params),

  delete: (id: number): Promise<IResponse<unknown>> =>
    instance.delete(`/group/user-group/?user_group_id=${id}`),
};
