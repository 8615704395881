import { CRUDProvider } from 'components/providers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GroupResources } from 'services/resources/group/admin-group';

import { Page } from './components/Page';

export const GroupPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation('user-group');
  return (
    <CRUDProvider
      quote={t('page.quote')}
      resource={GroupResources}
      title={t('page.title')}
      searchBy="name"
    >
      <Page />
    </CRUDProvider>
  );
};
