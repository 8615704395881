/* eslint-disable react/default-props-match-prop-types */
import {
  Box,
  BoxProps,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import NotFoundCover from 'images/people.svg';
import fp from 'lodash/fp';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IBeneficiaryResource } from 'services/resources/users/beneficiary/types.d';
import { IUserResource } from 'services/resources/users/user/types.d';

import {
  getBeneficiaries,
  getEmail,
  getFirstSurname,
  getId,
  getLastSurname,
  getName,
  getPercentage,
} from '../helpers';
import { EmptyBanner } from './EmptyBanner';

export interface IBeneficiariesProps extends BoxProps {
  /**
   * User resource data.
   */
  user?: IUserResource;
}

export const Beneficiaries: React.FC<IBeneficiariesProps> = (
  props,
): JSX.Element => {
  const { user, ...rest } = props;

  const { t } = useTranslation('kyc-physical');

  if (fp.isEmpty(getBeneficiaries(user))) {
    return (
      <EmptyBanner
        cover={NotFoundCover}
        mb={6}
        title={t('page.detail.beneficiaries.empty.title')}
      />
    );
  }

  return (
    <Box {...rest}>
      <Table colorScheme="blackAlpha" variant="striped">
        <Thead>
          <Tr>
            <Th>{t('page.detail.table.title.id')}</Th>
            <Th>{t('page.detail.table.title.name')}</Th>
            <Th>{t('page.detail.table.title.first-surname')}</Th>
            <Th>{t('page.detail.table.title.last-surname')}</Th>
            <Th>{t('page.detail.table.title.email')}</Th>
            <Th isNumeric>{t('page.detail.table.title.percentage')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {getBeneficiaries(user)?.map((item: IBeneficiaryResource) => (
            <Tr key={nanoid()}>
              <Td>{getId(item)}</Td>
              <Td>{getName(item)}</Td>
              <Td>{getFirstSurname(item)}</Td>
              <Td>{getLastSurname(item)}</Td>
              <Td>{getEmail(item)}</Td>
              <Td isNumeric>{getPercentage(item)}%</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

Beneficiaries.defaultProps = {
  overflow: 'scroll',
};
