import { CRUDProvider } from 'components/providers';
import { useTranslation } from 'react-i18next';
import { JournalResources } from 'services/resources/journal';

import { Page } from './components/Page';

export const JournalPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation('journal');

  return (
    <CRUDProvider
      allowMongoIDOrdering
      allowIsActive={false}
      journalActivityFilter
      quote={t('page.quote')}
      resource={JournalResources}
      searchBy="email"
      title={t('page.title')}
    >
      <Page />
    </CRUDProvider>
  );
};
