import { instance } from 'services';
import { IFindFnReturn, IQueryParams, IResponse } from 'services/types.d';

import { IDealStatusPayload, IDealStatusResource } from './types.d';

export const DealStatusResources = {
  findAll: (p?: IQueryParams): Promise<IFindFnReturn<IDealStatusResource>> =>
    instance.get('/deal-status/', { params: p }),

  create: (
    params: IDealStatusPayload,
  ): Promise<IResponse<IDealStatusResource>> =>
    instance.post(`/deal-status/`, params),

  update: (
    id: number,
    params: IDealStatusPayload,
  ): Promise<IResponse<IDealStatusResource>> =>
    instance.patch(`/deal-status/${id}/`, params),

  delete: (id: number): Promise<IResponse<unknown>> =>
    instance.delete(`/deal-status/${id}/`),
};
