import { useInjectReducer, useInjectSaga } from 'redux-injectors';

import { authManagerSagas, authReducer } from './store';

export function AuthManager(): null {
  useInjectReducer({ key: 'auth', reducer: authReducer });
  useInjectSaga({ key: 'auth', saga: authManagerSagas });

  return null;
}
