import { ChakraProvider } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import { ConnectedRouter } from 'connected-react-router';
import { App } from 'containers/App';
import i18n from 'locales';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { global, theme } from 'styles';
import { history } from 'utils/history';

import reportWebVitals from './reportWebVitals';
import { configureAppStore } from './store';

const MOUNT_NODE = document.getElementById('root');

const STORE = configureAppStore({}, history);

const Backoffice: React.FC = (): JSX.Element => (
  <Provider store={STORE}>
    <ConnectedRouter history={history}>
      <ChakraProvider resetCSS theme={theme}>
        <I18nextProvider i18n={i18n} />
        <Global styles={global} />
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </ChakraProvider>
    </ConnectedRouter>
  </Provider>
);

ReactDOM.render(<Backoffice />, MOUNT_NODE);

reportWebVitals();
