import {
  CompanyTypePage,
  ExchangePage,
  FinancialResourceOriginPage,
  GenderPage,
  InvestmentResourcePage,
  InvestmentStatusPage,
  JorunalActivityPage,
  KYCStagePage,
  OccupationPage,
  OccupationYearPage,
  SecurityTypePage,
  SpotlightTypePage,
  ValuationTypePage,
  VulnerableActivityPage,
} from 'containers/CatalogPages';
import {
  CompanyPage,
  DealPages,
  DealStatusPage,
  ValuationPage,
} from 'containers/DealFlowPages';
import { BankStatementPage, InvestmentPage } from 'containers/FinancialPages';
import { GroupPage, UserPage } from 'containers/GroupPages';
import { HomePage } from 'containers/HomePage';
import { JournalPage } from 'containers/JournalPage';
import { SpotlightPage } from 'containers/MarketingPages';
import { NotFoundPage } from 'containers/NotFoundPage';
import {
  CompanyRoundPage,
  CompanyStagePage,
  DifferentiationPage,
  IndustryPage,
} from 'containers/PreferencePages';
import { ReportsPage } from 'containers/ReportsPage';
import { SitiPage } from 'containers/SitiPage';
import {
  BeneficiaryPage,
  InvestorPage,
  KYBMoralPages,
  KYCHistoryPages,
  KYCPhysicalPages,
  ReferencePage,
} from 'containers/UserPages';
import { Route, Switch } from 'react-router-dom';

export const RouteTree: React.FC = (): JSX.Element => (
  <Switch>
    <Route exact path="/" component={HomePage} />
    <Route exact path="/investments" component={InvestmentPage} />
    <Route exact path="/industries" component={IndustryPage} />
    <Route exact path="/users" component={InvestorPage} />
    <Route exact path="/group-users" component={UserPage} />
    <Route exact path="/group-admin" component={GroupPage} />
    <Route exact path="/beneficiaries" component={BeneficiaryPage} />
    <Route exact path="/references" component={ReferencePage} />
    <Route exact path="/deal-status" component={DealStatusPage} />
    <Route exact path="/company-reports" component={ReportsPage} />
    <Route exact path="/differentiations" component={DifferentiationPage} />
    <Route exact path="/company-rounds" component={CompanyRoundPage} />
    <Route exact path="/company-stages" component={CompanyStagePage} />
    <Route exact path="/companies" component={CompanyPage} />
    <Route exact path="/valuations" component={ValuationPage} />
    <Route exact path="/spotlight" component={SpotlightPage} />
    <Route exact path="/siti" component={SitiPage} />
    <Route exact path="/journal" component={JournalPage} />
    <Route
      exact
      path="/catalogs/journal-activity"
      component={JorunalActivityPage}
    />
    <Route exact path="/catalogs/company-type" component={CompanyTypePage} />
    <Route exact path="/catalogs/exchange" component={ExchangePage} />
    <Route exact path="/catalogs/security-type" component={SecurityTypePage} />
    <Route exact path="/catalogs/gender" component={GenderPage} />
    <Route exact path="/catalogs/kyc-stage" component={KYCStagePage} />
    <Route exact path="/catalogs/occupation" component={OccupationPage} />
    <Route exact path="/bank-statement" component={BankStatementPage} />
    <Route path="/deals" component={DealPages} />
    <Route path="/kyc-physical-person" component={KYCPhysicalPages} />
    <Route path="/kyb-moral-person" component={KYBMoralPages} />
    <Route path="/kyc-history" component={KYCHistoryPages} />
    <Route
      exact
      path="/catalogs/occupation-year"
      component={OccupationYearPage}
    />
    <Route
      exact
      path="/catalogs/vulnerable-activity"
      component={VulnerableActivityPage}
    />
    <Route
      exact
      path="/catalogs/financial-resource-origin"
      component={FinancialResourceOriginPage}
    />
    <Route
      exact
      path="/catalogs/investment-resource"
      component={InvestmentResourcePage}
    />
    <Route
      exact
      path="/catalogs/investment-status"
      component={InvestmentStatusPage}
    />

    <Route
      exact
      path="/catalogs/spotlight-type"
      component={SpotlightTypePage}
    />
    <Route
      exact
      path="/catalogs/valuation-type"
      component={ValuationTypePage}
    />
    <Route component={NotFoundPage} />
  </Switch>
);

export default RouteTree;
