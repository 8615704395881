import * as Yup from 'yup';

export const ValuationFormValidationSchema = Yup.object().shape({
  description: Yup.number().required('errors.required'),
  dilution: Yup.number().required('errors.required'),
  company: Yup.string().required('errors.required'),
  exchange: Yup.string().required('errors.required'),
  is_active: Yup.bool().oneOf([false, true]).required('errors.required'),
  number: Yup.number().required('errors.required'),
  valuation_type: Yup.string().required('errors.required'),
});
