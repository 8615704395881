import { CRUDProvider } from 'components/providers';
import { useTranslation } from 'react-i18next';
import { UserResources } from 'services/resources/users/user';

import { Page } from './components/Page';

export const InvestorPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation('investors');

  return (
    <CRUDProvider
      allowUserFilters
      quote={t('page.quote')}
      resource={UserResources}
      searchBy="email__contains"
      title={t('page.title')}
    >
      <Page />
    </CRUDProvider>
  );
};
