import { Box, BoxProps, Button, Link } from '@chakra-ui/react';
import { IKYCPhysicalResource } from 'services/resources/users/kyc-physical/types.d';

export interface ILinkButtonProps extends BoxProps {
  /**
   * KYC physical user resource data.
   */
  path?: IKYCPhysicalResource;
  /**
   * Title that the button will show.
   */
  title: string;
}

export const LinkButton: React.FC<ILinkButtonProps> = (props): JSX.Element => {
  const { path, title, ...rest } = props;

  return (
    <Box {...rest}>
      <Link href={`${path}`} isExternal>
        <Button colorScheme="blue" px={8} py={2} size="xs">
          {title}
        </Button>
      </Link>
    </Box>
  );
};
