import { Box, BoxProps, Flex, useDisclosure } from '@chakra-ui/react';
import { Divider } from 'components/elements';
import HeaderLogo from 'images/logo.svg';
import { INavigation } from 'types.d';

import { BurgerButton } from './components/BurgerButton';
import { DesktopMenu } from './components/DesktopMenu';
import { Logo } from './components/Logo';
import { MobileMenu } from './components/MobileMenu';

export interface IHeaderProps extends BoxProps {
  /**
   * User avatar image to render in the UI, in case that the user doesn't have
   * set an avatar, it will display it username initials.
   */
  avatar?: string;
  /**
   * If true, the user navigation dropdown will not be rendered.
   */
  isFetching: boolean;
  /**
   * If `true`, the header will display its large screen navigation, otherwise
   * it will render the burger button that toggles a drawer.
   */
  isLgScreen: boolean;
  /**
   * On click events it will trigger the logout redux action and dispatch the
   * respective sagas in order to destroy the user's session.
   */
  onLogout: () => void;
  /**
   * Navigation routes that will be rendered in the header element or in the
   * drawer menu in case of mobile screen.
   */
  routes: INavigation[];
  /**
   * User's fullname if it has complete its profile, otherwise it will render
   * the user's email.
   */
  username?: string;
}

export const Header: React.FC<IHeaderProps> = (props): JSX.Element => {
  const {
    avatar,
    isFetching,
    isLgScreen,
    onLogout,
    routes,
    username,
    ...rest
  } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box as="header" {...rest}>
      <Flex as="nav" align="center" h="100%" justify="space-between" px={12}>
        <Logo alt="logo" src={HeaderLogo} />

        <DesktopMenu
          avatar={avatar}
          display={isLgScreen ? 'block' : 'none'}
          isFetching={isFetching}
          onLogout={onLogout}
          routes={routes}
          username={username}
        />

        <BurgerButton
          display={isLgScreen ? 'none' : 'block'}
          onClick={onOpen}
        />
      </Flex>

      <Divider opacity={1} zIndex="docked" />

      <MobileMenu
        isFetching={isFetching}
        isOpen={isOpen}
        navigation={routes}
        onClose={onClose}
        onLogout={onLogout}
        username={username}
      />
    </Box>
  );
};

Header.defaultProps = {
  bg: 'white',
  h: 16,
  mb: '2px',
  position: 'fixed',
  w: '100%',
  zIndex: 'overlay',
};
