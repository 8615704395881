import { Box, BoxProps, SimpleGrid } from '@chakra-ui/react';
import BlankInformation from 'images/blank-information.png';
import fp from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { IKYCPhysicalResource } from 'services/resources/users/kyc-physical/types.d';

import {
  getAddressProof,
  getCity,
  getColony,
  getExteriorNumber,
  getId,
  getInteriorNumber,
  getIpAddress,
  getKycStage,
  getMunicipality,
  getResidenceCountry,
  getStreet,
  getZipCode,
  KYCStagesEnum,
} from '../helpers';
import { EmptyBanner } from './EmptyBanner';
import { LinkButton } from './LinkButton';
import { Record } from './Record';

export interface IAddressInformationProps extends BoxProps {
  /**
   * KYC physical user resource data.
   */
  kyc?: IKYCPhysicalResource;
}

export const AddressInformation: React.FC<IAddressInformationProps> = (
  props,
): JSX.Element => {
  const { kyc, ...rest } = props;

  const { t } = useTranslation('kyc-physical');

  if (getKycStage(kyc) < KYCStagesEnum.ADDRESS_INFORMATION_SUCCESS) {
    return (
      <EmptyBanner
        cover={BlankInformation}
        mb={6}
        title={t('page.detail.address.empty.title')}
      />
    );
  }

  return (
    <Box {...rest}>
      <SimpleGrid columns={[1, 1, 2, 3]} spacingY={6}>
        <Record
          description={getStreet(kyc)}
          id={getId(kyc)}
          isEditable
          name="street"
          title={t('page.detail.address.street.title')}
        />
        <Record
          description={getExteriorNumber(kyc)}
          id={getId(kyc)}
          isEditable
          name="ext_num"
          title={t('page.detail.address.street.number')}
        />
        <Record
          description={getInteriorNumber(kyc)}
          id={getId(kyc)}
          isEditable
          name="int_num"
          title={t('page.detail.address.street.int-number')}
        />
        <Record
          description={getInteriorNumber(kyc)}
          id={getId(kyc)}
          isEditable
          name="int_num"
          title={t('page.detail.address.suite.number')}
        />
        <Record
          description={getMunicipality(kyc)}
          id={getId(kyc)}
          isEditable
          name="municipality"
          title={t('page.detail.address.municipality')}
        />
        <Record
          description={getColony(kyc)}
          id={getId(kyc)}
          isEditable
          name="colony"
          title={t('page.detail.address.colony')}
        />
        <Record
          description={getCity(kyc)}
          id={getId(kyc)}
          isEditable
          name="city"
          title={t('page.detail.address.state')}
        />
        <Record
          description={getResidenceCountry(kyc)}
          id={getId(kyc)}
          isEditable
          name="residence_country"
          title={t('page.detail.address.country')}
        />
        <Record
          description={getZipCode(kyc)}
          id={getId(kyc)}
          isEditable
          name="zip_code"
          title={t('page.detail.address.zip.code')}
        />

        <Record
          description={getIpAddress(kyc)}
          id={getId(kyc)}
          isEditable
          name="ip_address"
          title={t('page.detail.address.ip-address.code')}
        />
      </SimpleGrid>

      {!fp.isNil(getAddressProof(kyc)) ? (
        <LinkButton
          mt={4}
          path={getAddressProof(kyc)}
          title={t('page.detail.address.address-proof.button')}
        />
      ) : null}
    </Box>
  );
};
