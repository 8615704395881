import { QuerySuggestions } from 'components/elements';
import {
  CheckboxField,
  FileField,
  InputField,
  SelectField,
} from 'components/inputs';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { emailTransform, genericTransform } from 'helpers/transform-helpers';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BankResources } from 'services/resources/catalogs/bank';
import { IBankStatementPayload } from 'services/resources/financial/bank-statements/types.d';
import { UserResources } from 'services/resources/users/user';
import { ISelectOption } from 'types.d';

import { BankStatementFormValidationSchema } from './helpers';

export interface IBankStatementFormProps {
  /**
   * Form initial values, can be a complete or partial ´IBankStatementPayload´
   * object which will allow to support create and update requests.
   */
  initialValues: IBankStatementPayload;
  /**
   * On submit action, for this form this will be controlled from the outside
   * of this component thanks to the forward ref property.
   */
  onSubmit: (
    v: IBankStatementPayload,
    h?: FormikHelpers<IBankStatementPayload>,
  ) => void;
}

export const BankStatementForm = forwardRef<
  FormikProps<IBankStatementPayload>,
  IBankStatementFormProps
>((props, ref) => {
  const { initialValues, onSubmit } = props;
  const { t } = useTranslation('bank-statement');

  return (
    <Formik
      initialValues={{ ...initialValues }}
      innerRef={ref}
      onSubmit={onSubmit}
      validationSchema={BankStatementFormValidationSchema}
    >
      <Form>
        <InputField
          label={t('form.input.account-number.label')}
          mb={4}
          name="account_number"
        />
        <QuerySuggestions
          query={BankResources.findAll}
          transform={genericTransform}
        >
          {({ loading, value }) => (
            <SelectField
              isLoading={loading}
              label={t('form.input.bank.label')}
              mb={4}
              name="bank"
              options={value as ISelectOption[]}
              placeholder={t('form.select.default-placeholder')}
            />
          )}
        </QuerySuggestions>
        <QuerySuggestions
          query={UserResources.findAll}
          transform={emailTransform}
          params={{ is_accredited: true }}
        >
          {({ loading, value }) => (
            <SelectField
              isLoading={loading}
              label={t('form.input.user.label')}
              mb={4}
              name="user"
              options={value as ISelectOption[]}
              placeholder={t('form.select.default-placeholder')}
            />
          )}
        </QuerySuggestions>
        <FileField
          helper="inputs.file.helpers"
          label={t('form.input.statement.label')}
          mb={4}
          name="statement"
        />
        <CheckboxField
          label={t('form.input.is-active.label')}
          name="is_active"
        />
      </Form>
    </Formik>
  );
});

BankStatementForm.displayName = 'BankStatementForm';
