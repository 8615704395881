import { QuerySuggestions } from 'components/elements';
import {
  CheckboxField,
  InputField,
  MoneyField,
  SelectField,
} from 'components/inputs';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { genericTransform } from 'helpers/transform-helpers';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ExchangeResources } from 'services/resources/catalogs/exchange';
import { ValuationTypeResources } from 'services/resources/catalogs/valuation-type';
import { CompanyResources } from 'services/resources/deal-flow/company';
import { IValuationPayload } from 'services/resources/deal-flow/valuation/types.d';
import { ISelectOption } from 'types.d';

import { ValuationFormValidationSchema } from './helpers';

export interface IValuationFormProps {
  /**
   * Form initial values, can be a complete or partial IValuationPayload
   * object which will allow to support create and update requests.
   */
  initialValues: IValuationPayload;
  /**
   * On submit action, for this form this will be controlled from the outside
   * of this component thanks to the forward ref property.
   */
  onSubmit: (
    v: IValuationPayload,
    h?: FormikHelpers<IValuationPayload>,
  ) => void;
}

export const ValuationForm = forwardRef<
  FormikProps<IValuationPayload>,
  IValuationFormProps
>((props, ref) => {
  const { initialValues, onSubmit } = props;
  const { t } = useTranslation(['valuations', 'common']);

  return (
    <Formik
      initialValues={{ ...initialValues }}
      innerRef={ref}
      onSubmit={onSubmit}
      validationSchema={ValuationFormValidationSchema}
    >
      <Form>
        <QuerySuggestions
          query={CompanyResources.findAll}
          transform={genericTransform}
        >
          {({ loading, value }) => (
            <SelectField
              isLoading={loading}
              label={t('form.input.company.label')}
              mb={4}
              name="company"
              options={value as ISelectOption[]}
              placeholder={t('form.select.default-placeholder', {
                ns: 'common',
              })}
            />
          )}
        </QuerySuggestions>

        <MoneyField
          label={t('form.input.valuation.label')}
          mb={4}
          name="description"
        />

        <InputField
          label={t('form.input.valuation-number.label')}
          mb={4}
          name="number"
          type="number"
        />

        <InputField
          label={t('form.input.dilution.label')}
          mb={4}
          name="dilution"
          type="number"
        />

        <QuerySuggestions
          query={ExchangeResources.findAll}
          transform={genericTransform}
        >
          {({ loading, value }) => (
            <SelectField
              isLoading={loading}
              label={t('form.input.exchange.label')}
              mb={4}
              name="exchange"
              options={value as ISelectOption[]}
              placeholder={t('form.select.default-placeholder', {
                ns: 'common',
              })}
            />
          )}
        </QuerySuggestions>

        <QuerySuggestions
          query={ValuationTypeResources.findAll}
          transform={genericTransform}
        >
          {({ loading, value }) => (
            <SelectField
              isLoading={loading}
              label={t('form.input.valuation-type.label')}
              mb={4}
              name="valuation_type"
              options={value as ISelectOption[]}
              placeholder={t('form.select.default-placeholder', {
                ns: 'common',
              })}
            />
          )}
        </QuerySuggestions>

        <CheckboxField
          label={t('inputs.is-active.label', { ns: 'common' })}
          name="is_active"
        />
      </Form>
    </Formik>
  );
});

ValuationForm.displayName = 'ValuationForm';
