/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: remove all the `any` types in this file.

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import fp from 'lodash/fp';
import { v4 } from 'uuid';

export interface IToastSliceState {
  toasts: any[];
}

export const initialState = [];

const toastManaggerSlice = createSlice({
  name: 'toasts',
  initialState,
  reducers: {
    addToast: {
      reducer(state, action: PayloadAction<any>) {
        state.push(action?.payload as never);
      },
      prepare(payload) {
        return { payload: fp.merge({ id: v4() })(payload) };
      },
    },
    removeToast(state) {
      state.splice(0, state.length);
    },
  },
});

export const { addToast, removeToast } = toastManaggerSlice.actions;

export const addErrorToast = fp.curry((message) =>
  addToast({ description: message, status: 'error' }),
);

export const addWarningToast = fp.curry((message) =>
  addToast({ description: message, status: 'warning' }),
);

export const addSuccessToast = fp.curry((message) =>
  addToast({ description: message, status: 'success' }),
);

export const { reducer } = toastManaggerSlice;
