import fp from 'lodash/fp';
import validator from 'validator';

const LOCALE = 'en-US';

export const isAlphaSpace = fp.compose(
  fp.curry((str: string) => {
    if (fp.isNil(str) || fp.isEmpty(str)) return true;
    return validator.isAlpha(str, LOCALE);
  }),
  fp.join(''),
  fp.split(' '),
);
