import { Link, List, ListItem, ListProps, Stack, Text } from '@chakra-ui/react';
import { Divider } from 'components/elements';
import fp from 'lodash/fp';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { Link as ReachLink } from 'react-router-dom';
import { ISimpleNavigation } from 'types.d';

export interface ISubMenuProps extends ListProps {
  /**
   * If `true` the dropdown will be show the elements
   */
  isOpen: boolean;
  /**
   * Many routes.
   */
  routes?: ISimpleNavigation[];
}

export const SubMenu: React.FC<ISubMenuProps> = (props): JSX.Element | null => {
  const { isOpen, routes, ...rest } = props;

  const { t } = useTranslation('common');

  if (!isOpen || fp.isEmpty(routes)) return null;

  return (
    <List {...rest}>
      {routes?.map(({ label, path }) => (
        <ListItem key={nanoid()}>
          <Link as={ReachLink} to={path as string}>
            <Stack
              align="center"
              bg="blackAlpha.50"
              cursor="pointer"
              h={12}
              isInline
              pl={14}
              sx={{
                ':hover': {
                  bg: 'blackAlpha.200',
                  transition: 'background 0.5s ease',
                },
              }}
            >
              <Text fontSize="sm">{t(label)}</Text>
            </Stack>
          </Link>
        </ListItem>
      ))}
      <Divider />
    </List>
  );
};
