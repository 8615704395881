import { getGenericContext } from 'components/contexts/useGenericContext';
import {
  CSVButton,
  Divider,
  FooterOptionsContainer,
  PageHeadline,
} from 'components/elements';
import { useFind } from 'components/hooks';
import { FormikHelpers } from 'formik';
import { getId, USER_GROUP_CSV } from 'helpers';
import FileDownload from 'js-file-download';
import fp from 'lodash/fp';
import {
  ReactNode,
  ReactNodeArray,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useSetState, useToggle } from 'react-use';
import { GroupResources } from 'services/resources/group/admin-group';
import { ICRUDResource, IFilterParams, IQueryParams } from 'services/types.d';
import { DEFAULT_QUERY_PARAMS } from 'utils';

import { Actions } from './components/Actions';
import { DeleteModal } from './components/DeleteModal';
import { Filters } from './components/Filters';
import { Pagination } from './components/Pagination';
import { SearchParams } from './helpers';

export interface ICRUDProviderProps<T> {
  /**
   * If `true`, the provider will render a button that will allow the user to
   * perform a POST operation.
   */
  allowPost?: boolean;
  /**
   * If `true`, it will render the `is_active`filter.
   */
  allowIsActive?: boolean;
  /**
   * If `true`, it will render the `ascending` and `descending` ordering based
   * in the MongoDB results.
   */
  allowMongoIDOrdering?: boolean;
  /**
   * If `true`, it will render the `is_accredited`, `is_staff` and
   * `is_superuser` filters.
   */
  allowUserFilters?: boolean;
  /**
   * If `true`, the page filters will be removed.
   */
  allowRemoveFilters?: boolean;
  /**
   * If `true`, it will render the `is_owner`, `is_admin` and
   * `is_superuser` filters.
   */
  allowUserGroupFilters?: boolean;
  /**
   * Children opaque data structure, commonly this component will be the
   * table in which will be displayed the listing resources.
   */
  children: ReactNode | ReactNodeArray;
  /**
   * If `true`, it will render the company filter in order to retrieve items
   * based on the company ID.
   */
  companyFilter?: boolean;
  /**
   * If `true`, it will render the group name filter in order to retrieve items
   * based on the group ID.
   */
  groupFilter?: boolean;
  /**
   * If `true`, the CSVButton component will be show in the corresponding page.
   */
  csvButton?: boolean;
  /**
   * Overrides the default post action when clicking the create button.
   */
  customPostAction?: () => void;
  /**
   * If `true`, it will render the journal activity filter in order to retrieve
   * items based on the journal activity ID.
   */
  journalActivityFilter?: boolean;
  /**
   * Text that will appear below the title, can be a helper message, a subtitle
   * or `undefined`, in this case, the component will not going to be rendered.
   */
  quote?: string;
  /**
   * Resource to perfom CRUD operations, this is the main object of this
   * context provider component, make shure that your resource is of type
   * @type{ICRUDResource}, if you only need read-only utilities use the
   * ReadOnly component..
   */
  resource: ICRUDResource<T>;
  /**
   * Search parameter, this prop must be a key of the `SearchParams` enum.
   */
  searchBy?: keyof typeof SearchParams;
  /**
   * Headline title, commonly will be the name of the page which its rendering
   * this components.
   */
  title: string;
}

export const CRUDProvider = <T,>(props: ICRUDProviderProps<T>): JSX.Element => {
  const {
    allowPost = false,
    children,
    companyFilter = false,
    groupFilter = false,
    customPostAction,
    csvButton,
    journalActivityFilter = false,
    quote,
    resource,
    searchBy,
    title,
    allowIsActive = true,
    allowMongoIDOrdering = false,
    allowRemoveFilters = false,
    allowUserFilters = false,
    allowUserGroupFilters = false,
  } = props;

  const [active, setActive] = useState<number>(1);

  const [isDrawerOpen, toggleDrawer] = useToggle(false);

  const [isModalOpen, toggleModal] = useToggle(false);

  const [ordering, setOrdering] = useState<string>('-id');

  const [orderingMongoID, setOrderingMongoId] = useState<string>('_id');

  const [params, setParams] = useSetState<IQueryParams>(DEFAULT_QUERY_PARAMS);

  const [registry, setRegistry] = useState<T>({} as T);

  const [state, doFetch] = useFind<T>(resource);

  const [userFilters, setUserFilters] = useState<Partial<IFilterParams>>({});

  const [userGroupFilters, setUserGroupFilters] = useState<
    Partial<IFilterParams>
  >({});

  const onChangePage = useCallback(
    (v: { selected: number }) => setParams({ page: v?.selected + 1 }),
    [],
  );

  const onSearchRegistry = useCallback(
    (v?: string) => setParams({ [searchBy as string]: v }),
    [],
  );

  const onFilterByCompany = useCallback(
    (v?: string) => setParams({ company_id: v }),
    [],
  );

  const onFilterByJournalActivity = useCallback(
    (v?: string) => setParams({ _activity_id: v }),
    [],
  );

  const onFilterByGroupName = useCallback(
    (v?: string) => setParams({ group__id: v }),
    [],
  );

  const onSetActiveFilter = useCallback((v: string) => {
    setParams({ is_active: parseInt(v, 10) });
    setActive(parseInt(v, 10));
  }, []);

  const onSetOrdering = useCallback((v: string) => {
    setParams({ ordering: v });
    setOrdering(v);
    setOrderingMongoId(v);
  }, []);

  const onSetUserFilters = useCallback((v: string[]) => {
    if (fp.isEmpty(v)) setUserFilters({});
    else setUserFilters(v.reduce((a, e) => ({ ...a, [e]: 1 }), {}));
  }, []);

  const onSetUserGroupFilters = useCallback((v: string[]) => {
    if (fp.isEmpty(v)) setUserGroupFilters({});
    else setUserGroupFilters(v.reduce((a, e) => ({ ...a, [e]: 1 }), {}));
  }, []);

  const onPrepareCreate = useCallback(() => {
    setRegistry({} as T);
    toggleDrawer();
  }, []);

  const onPrepareDelete = useCallback((v: T) => {
    setRegistry(v);
    toggleModal();
  }, []);

  const onPrepareDrawer = useCallback((v: T) => {
    setRegistry(v);
    toggleDrawer();
  }, []);

  const defaultDelete = useCallback(async () => {
    await resource.delete(getId(registry));
    await doFetch(params);
    toggleModal();
  }, [params, registry]);

  const defaultPatch = useCallback(
    async (v: Partial<T>, h?: FormikHelpers<Partial<T>>) => {
      if (fp.compose(fp.isNil, getId)(v)) await resource.create(v);
      else await resource.update(getId(v), v);

      await doFetch(params);
      toggleDrawer();
      h?.setSubmitting(false);
    },
    [params],
  );

  const defaultRestore = useCallback(
    async (r: T, v: Partial<T>) => {
      await resource.update(getId(r), v);
      await doFetch(params);
    },
    [params],
  );

  const exportToCsv = useCallback(async () => {
    const { data } = await GroupResources.exportData();
    FileDownload(data as Blob, USER_GROUP_CSV);
  }, []);

  const Context = getGenericContext<T>();

  useEffect(() => {
    doFetch({ ...params, ...userFilters });
  }, [params, userFilters]);

  useEffect(() => {
    doFetch({ ...params, ...userGroupFilters });
  }, [params, userGroupFilters]);

  return (
    <Context.Provider
      value={{
        active,
        defaultDelete,
        defaultPatch,
        defaultRestore,
        doFetch,
        isDrawerOpen,
        isModalOpen,
        onFilterByCompany,
        onFilterByJournalActivity,
        onFilterByGroupName,
        onPrepareCreate,
        onPrepareDelete,
        onPrepareDrawer,
        onSearchRegistry,
        onSetActiveFilter,
        onSetOrdering,
        onSetUserFilters,
        onSetUserGroupFilters,
        ordering,
        orderingMongoID,
        params,
        registry,
        setRegistry,
        state,
        toggleDrawer,
        toggleModal,
        userFilters,
      }}
    >
      <PageHeadline quote={quote} title={title} />
      <Divider mb={6} />

      <Filters
        allowIsActive={allowIsActive}
        allowMongoIDOrdering={allowMongoIDOrdering}
        allowUserFilters={allowUserFilters}
        allowUserGroupFilters={allowUserGroupFilters}
        allowRemoveFilters={allowRemoveFilters}
      />

      <Divider mb={6} />

      <Actions
        allowPost={allowPost}
        companyFilter={companyFilter}
        groupFilter={groupFilter}
        customPostAction={customPostAction}
        journalActivityFilter={journalActivityFilter}
        searchBy={searchBy}
        mb={6}
        mx={8}
      />

      {children}

      {csvButton ? (
        <FooterOptionsContainer isVisible>
          <Pagination
            mx={8}
            my={6}
            onClick={onChangePage}
            pages={fp.get(['value', 'count'])(state) / 20}
          />

          <CSVButton
            disabled={state?.loading}
            label="button.csv"
            mx={8}
            my={6}
            onClick={exportToCsv}
          />
        </FooterOptionsContainer>
      ) : (
        <Pagination
          mx={8}
          my={6}
          onClick={onChangePage}
          pages={fp.get(['value', 'count'])(state) / 20}
        />
      )}

      <DeleteModal />
    </Context.Provider>
  );
};
