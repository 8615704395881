import { SimpleGrid, SimpleGridProps } from '@chakra-ui/react';
import BlankInformation from 'images/blank-information.png';
import { useTranslation } from 'react-i18next';
import { IKYCPhysicalResource } from 'services/resources/users/kyc-physical/types.d';

import {
  getBirthCountry,
  getBirthDate,
  getFederalEntity,
  getGender,
  getId,
  getKeyRegistry,
  getKycStage,
  getNationality,
  getPoliticallyExposed,
  getPoliticallyExposedDescription,
  getTaxId,
  KYCStagesEnum,
} from '../helpers';
import { EmptyBanner } from './EmptyBanner';
import { Record } from './Record';

export interface IPersonalInformationProps extends SimpleGridProps {
  /**
   * KYC physical user resource data.
   */
  kyc?: IKYCPhysicalResource;
}

export const PersonalInformation: React.FC<IPersonalInformationProps> = (
  props,
): JSX.Element => {
  const { kyc, ...rest } = props;
  const { t } = useTranslation('kyc-physical');

  if (getKycStage(kyc) < KYCStagesEnum.PERSONAL_INFORMATION_SUCCESS) {
    return (
      <EmptyBanner
        cover={BlankInformation}
        mb={6}
        title={t('page.detail.personal-information.empty.title')}
      />
    );
  }

  return (
    <SimpleGrid {...rest}>
      <Record
        description={getTaxId(kyc)}
        id={getId(kyc)}
        isEditable
        name="tax_id"
        title={t('page.detail.personal-information.tax-id')}
      />

      <Record
        description={getKeyRegistry(kyc)}
        id={getId(kyc)}
        isEditable
        name="key_registry"
        title={t('page.detail.personal-information.key-registry')}
      />

      <Record
        description={getFederalEntity(kyc)}
        id={getId(kyc)}
        isEditable
        name="federal-entity"
        title={t('page.detail.personal-information.federal-entity')}
      />

      <Record
        description={getBirthCountry(kyc)}
        id={getId(kyc)}
        isEditable
        name="birth_country"
        title={t('page.detail.personal-information.birth-country')}
      />

      <Record
        description={getNationality(kyc)}
        id={getId(kyc)}
        isEditable
        name="nationality"
        title={t('page.detail.personal-information.nationality')}
      />

      <Record
        description={getBirthDate(kyc)}
        id={getId(kyc)}
        isEditable
        name="birth_date"
        title={t('page.detail.personal-information.birth-date')}
      />

      <Record
        description={getGender(kyc) === 1 ? 'Male' : ('Female' as string)}
        title={t('page.detail.personal-information.gender')}
      />

      <Record
        description={
          getPoliticallyExposed(kyc) === true
            ? t('page.detail.boolean.respose.true')
            : t('page.detail.boolean.respose.false')
        }
        title={t('page.detail.personal-information.politically-exposed')}
      />

      <Record
        description={getPoliticallyExposedDescription(kyc)}
        title={t(
          'page.detail.personal-information.politically-exposed-description',
        )}
      />
    </SimpleGrid>
  );
};

PersonalInformation.defaultProps = {
  columns: [1, 1, 2, 3],
  spacingY: 6,
};
