import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { history } from 'utils/history';

export const createReducer = (injectedReducers = {}): Reducer => {
  const rootReducer = combineReducers({
    router: connectRouter(history),
    ...injectedReducers,
  });

  return rootReducer;
};
