import { CheckboxField, EmailField, InputField } from 'components/inputs';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IBeneficiaryPayload } from 'services/resources/users/beneficiary/types.d';

import { BeneficiaryFormValidationSchema } from './helpers';

export interface IBeneficiaryFormProps {
  /**
   * Form initial values, can be a complete or partial ´IBeneficiaryPayload´
   * object which will allow to support create and update requests.
   */
  initialValues: IBeneficiaryPayload;
  /**
   * On submit action, for this form this will be controlled from the outside
   * of this component thanks to the forward ref property.
   */
  onSubmit: (
    v: IBeneficiaryPayload,
    h?: FormikHelpers<IBeneficiaryPayload>,
  ) => void;
}

export const BeneficiaryForm = forwardRef<
  FormikProps<IBeneficiaryPayload>,
  IBeneficiaryFormProps
>((props, ref) => {
  const { initialValues, onSubmit } = props;
  const { t } = useTranslation(['beneficiaries', 'common']);

  return (
    <Formik
      initialValues={{ ...initialValues }}
      innerRef={ref}
      onSubmit={onSubmit}
      validationSchema={BeneficiaryFormValidationSchema}
    >
      <Form>
        <InputField
          label={t('inputs.name.label', { ns: 'common' })}
          mb={4}
          name="name"
        />
        <InputField
          label={t('inputs.first-surname.label', { ns: 'common' })}
          mb={4}
          name="first_surname"
        />
        <InputField
          label={t('inputs.last-surname.label', { ns: 'common' })}
          mb={4}
          name="last_surname"
        />
        <EmailField mb={4} name="email" />
        <InputField
          label={t('form.input.percentage.label')}
          mb={4}
          name="percentage"
          type="number"
        />
        <InputField mb={4} name="bind" label="Bind to user (optional)" />
        <CheckboxField
          label={t('inputs.is-active.label', { ns: 'common' })}
          name="is_active"
        />
      </Form>
    </Formik>
  );
});

BeneficiaryForm.displayName = 'BeneficiaryForm';
