import { isValidFileFormatWithPDF, isValidFileSize } from 'helpers';
import fp from 'lodash/fp';
import * as Yup from 'yup';

export const BankStatementFormValidationSchema = Yup.object().shape({
  account_number: Yup.string()
    .max(150, 'errors.max')
    .required('errors.required'),
  bank: Yup.string().required('errors.required'),
  is_active: Yup.bool().oneOf([false, true]).required('errors.required'),
  statement: Yup.mixed()
    .test('file-size', 'errors.file-size', (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileSize(v.size);
    })
    .test('file-format', 'errors.file-format', (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileFormatWithPDF(v.type);
    })
    .required('errors.required'),
  user: Yup.string().required('errors.required'),
});
