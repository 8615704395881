import { Tr } from '@chakra-ui/react';
import { useGenericContext } from 'components/contexts/useGenericContext';
import { Td, TdBin } from 'components/lib';
import { CustomTable } from 'components/modules';
import { getId, getResults, isResultsEmpty } from 'helpers';
import { nanoid } from 'nanoid';
import React from 'react';
import { IUserGroupResource } from 'services/resources/group/user-group/types.d';

import {
  getCreated,
  getGroupName,
  getIsAccredited,
  getIsAdmin,
  getIsOwner,
  getUserEmail,
  HEADERS,
} from '../helpers';

export const Page: React.FC = (): JSX.Element => {
  const { state } = useGenericContext<IUserGroupResource>();

  return (
    <>
      <CustomTable
        isEmpty={isResultsEmpty(state)}
        headers={HEADERS}
        isLoading={state?.loading}
        mx={8}
      >
        {getResults(state)?.map((row: IUserGroupResource) => (
          <Tr key={nanoid()} position="relative">
            <Td>{getId(row)}</Td>
            <Td>{getGroupName(row)}</Td>
            {row?.pending_email ? (
              <Td>{row?.pending_email}</Td>
            ) : (
              <Td>{getUserEmail(row)}</Td>
            )}
            <TdBin isCheck={getIsAccredited(row)} />
            <TdBin isCheck={getIsAdmin(row)} />
            <TdBin isCheck={getIsOwner(row)} />
            <TdBin isCheck={row?.is_pending as boolean} />
            <Td>{getCreated(row)}</Td>
          </Tr>
        ))}
      </CustomTable>
    </>
  );
};
