import { instance } from 'services';
import { IFindFnReturn, IQueryParams, IResponse } from 'services/types.d';

import { ICompanyReportPayload, ICompanyReportResource } from './types.d';

const CUSTOM_HEADERS = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};

export const CompanyReportResources = {
  findAll: (p?: IQueryParams): Promise<IFindFnReturn<ICompanyReportResource>> =>
    instance.get('/company-report/', { params: p }),

  create: (
    params: ICompanyReportPayload,
  ): Promise<IResponse<ICompanyReportResource>> =>
    instance.post('/company-report/', params, CUSTOM_HEADERS),

  update: (
    id: number,
    params: ICompanyReportPayload,
  ): Promise<IResponse<ICompanyReportResource>> =>
    instance.patch(`/company-report/${id}/`, params, CUSTOM_HEADERS),

  delete: (id: number): Promise<IResponse<unknown>> =>
    instance.delete(`/company-report/${id}/`),
};
