import { formatAmount, formatDate } from 'helpers';
import fp from 'lodash/fp';

export const getCompanyName = fp.get(['company', 'name']);

export const getValuation = fp.compose(formatAmount, fp.get('description'));

export const getNumber = fp.get('number');

export const getDilution = fp.get('dilution');

export const getExchange = fp.get(['exchange', 'name']);

export const getValuationType = fp.get(['valuation_type', 'name']);

export const getCreated = fp.compose(formatDate, fp.get('created'));
