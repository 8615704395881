import { push } from 'connected-react-router';
import { addErrorToast } from 'containers/ToastManager/store/slice';
import fp from 'lodash/fp';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { AuthResources } from 'services/resources/auth';
import { ILoginPayload } from 'services/resources/auth/types.d';
import { setAuthorizationHeader } from 'services/utils/headers';
import { SagaType } from 'types.d';
import { SessionCookies } from 'utils';

import { makeSelectSession } from './selectors';
import { authActions } from './slice';

function* watchSessionCookies(): SagaType {
  try {
    const cookie = yield call(SessionCookies.load);

    if (fp.isEmpty(cookie)) return;

    yield call(setAuthorizationHeader, fp.get('accessToken')(cookie));

    yield put(authActions.setSession(cookie));
  } catch (err) {
    yield put(addErrorToast('toast.errors.tokens'));
  }
}

function* watchLogin(action: {
  payload: ILoginPayload;
  type: string;
}): SagaType {
  try {
    const response = yield call(AuthResources.auth, action?.payload);

    const accessToken = fp.get(['data', 'access'])(response);

    const refreshToken = fp.get(['data', 'refresh'])(response);

    yield call(SessionCookies.store, { accessToken, refreshToken });
    yield call(setAuthorizationHeader, accessToken);

    yield put(authActions.setSession({ accessToken, refreshToken }));
    yield put(authActions.authSuccess());

    yield put(push('/'));
  } catch (err) {
    yield put(authActions.authFailure());
    yield put(addErrorToast('toast.errors.login'));
  }
}

function* watchLogout(): SagaType {
  yield call(SessionCookies.destroy);
  yield call(setAuthorizationHeader, null);

  yield put(authActions.setSession(null));
}

function* watchRefreshToken(): SagaType {
  try {
    const session = yield select(makeSelectSession);

    const response = yield call(AuthResources.refresh, {
      refresh: fp.get('refreshToken')(session),
    });

    const accessToken = fp.get(['data', 'access'])(response);

    const refreshToken = fp.get(['data', 'refresh'])(response);

    yield call(SessionCookies.store, { accessToken, refreshToken });
    yield call(setAuthorizationHeader, accessToken);

    yield put(authActions.setSession({ accessToken, refreshToken }));
    yield put(authActions.refreshSuccess());
  } catch (err) {
    yield put(authActions.refreshFailure());
    yield call(watchLogout);
    yield put(addErrorToast('toast.errors.session'));
  }
}

export function* authManagerSagas(): SagaType {
  yield takeLatest(authActions.auth.type, watchLogin);
  yield takeLatest(authActions.logout.type, watchLogout);
  yield takeLatest(authActions.refresh.type, watchRefreshToken);

  yield call(watchSessionCookies);
}
