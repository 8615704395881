import { isValidFileSize, isValidPDFFileFormat } from 'helpers';
import fp from 'lodash/fp';
import * as Yup from 'yup';

export const ContractsFormValidationSchema = Yup.object().shape({
  participation_constancy: Yup.mixed()
    .test('file-size', 'errors.file-size', (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileSize(v.size);
    })
    .test('file-format', 'errors.pdf-file-format', (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidPDFFileFormat(v.type);
    }),

  investment_attachment: Yup.mixed()
    .test('file-size', 'errors.file-size', (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileSize(v.size);
    })
    .test('file-format', 'errors.pdf-file-format', (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidPDFFileFormat(v.type);
    }),
});
