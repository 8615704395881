/* eslint-disable @typescript-eslint/no-explicit-any */
import { Children, ReactElement } from 'react';
import { useAsync } from 'react-use';
import { IFindFnReturn, IQueryParams } from 'services/types.d';
import { IChildrenAsyncFn } from 'types.d';

export interface IQuerySuggestionsProps<T> {
  /**
   * Component children.
   */
  children({ error, loading, value }: IChildrenAsyncFn): ReactElement;
  /**
   * Custom query parameter that the resource can filter.
   */
  params?: IQueryParams;
  /**
   * Async function that will retrieve data from the API.
   */
  query: (params?: IQueryParams) => Promise<IFindFnReturn<T>>;
  /**
   * Function that will transform the response in case that is needed.
   */
  transform?: (r: T[]) => any;
}

export const QuerySuggestions = <T,>(
  props: IQuerySuggestionsProps<T>,
): JSX.Element => {
  const { children, params = {}, query, transform = (r: T[]) => r } = props;

  const { error, loading, value } = useAsync(async () => {
    const response = await query({
      is_active: true,
      ordering: '-id',
      page_size: 20000,
      ...params,
    });
    const result = transform(response?.data?.results);
    return result;
  }, []);

  const renderedChildren = children({ error, loading, value });
  return renderedChildren && Children.only(renderedChildren);
};
