import fp from 'lodash/fp';
import {
  FaFingerprint,
  FaMoneyBillWave,
  FaPen,
  FaUserCheck,
  FaUserMinus,
  FaUserPlus,
  FaUsers,
} from 'react-icons/fa';

export const getId = fp.get('id');

export const getEmail = fp.get('email');

export const getName = fp.get('name');

export const getFirstSurname = fp.get('first_surname');

export const getLastSurname = fp.get('last_surname');

export const getResults = fp.get(['value', 'results']);

export const getCompanyLogo = fp.get('logo');

export const getAccountNumber = fp.get('account_number');

export const getStatement = fp.get('statement');

export const getReport = fp.get('report');

export const getCreationDate = fp.get('created');

export const getIsActive = fp.get('is_active');

export const getQuarter = fp.get(['quarter', 'name']);

export const getUpdatedDate = fp.get('updated');

export const getCompanyId = fp.get(['company', 'name']);

export const getYear = fp.get('year');

export const getBankName = fp.get(['bank', 'name']);

export const getUserEmail = fp.get(['user', 'email']);

export const isResultsEmpty = fp.compose(fp.isEmpty, getResults);

export const getJournalActionIcon = {
  NEW_USER: { as: FaUserPlus, boxSize: 4, color: 'black' },
  USER_JOINED_GROUP: { as: FaUsers, boxSize: 4, color: 'black' },
  USER_ACCREDITED: { as: FaUserCheck, boxSize: 4, color: 'black' },
  NEW_INVESTMENT: {
    as: FaMoneyBillWave,
    boxSize: 4,
    color: 'black',
  },
  LOGIN: {
    as: FaFingerprint,
    boxSize: 4,
    color: 'black',
  },
  USER_CREATED_GROUP: {
    as: FaUsers,
    boxSize: 4,
    color: 'black',
  },
  USER_CHANGED_GROUP: {
    as: FaPen,
    boxSize: 4,
    color: 'black',
  },
  USER_BANED_OTHER: {
    as: FaUserMinus,
    boxSize: 4,
    color: 'black',
  },
  USER_INVESTED_FROM_GROUP: {
    as: FaMoneyBillWave,
    boxSize: 4,
    color: 'black',
  },
};
