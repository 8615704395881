import { Avatar, Box, Stack, StackProps } from '@chakra-ui/react';
import { ClampText } from 'components/elements/ClampText';

export interface IItemProps extends StackProps {
  /**
   * User's avatar.
   */
  avatar: string;
  /**
   * Date when the user was created.
   */
  created: string;
  /**
   * User's email.
   */
  email: string;
}

export const Item: React.FC<IItemProps> = (props): JSX.Element => {
  const { avatar, created, email, ...rest } = props;

  return (
    <Stack {...rest}>
      <Avatar name={email} size="sm" src={avatar} />
      <Box mb={2} w="100%">
        <ClampText fontSize="xs" fontWeight="bold">
          {email}
        </ClampText>
        <ClampText color="blackAlpha.600" fontSize="xs" fontWeight="medium">
          {created}
        </ClampText>
      </Box>
    </Stack>
  );
};

Item.defaultProps = {
  align: 'center',
  isInline: true,
  justify: 'space-between',
  position: 'relative',
  w: '100%',
};
