/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  withAuthenticatedRedir,
  withNotAuthenticatedRedir,
} from 'components/hocs';
import { AuthManager } from 'containers/AuthManager';
import { LoginPage } from 'containers/LogInPage';
import { ToastManager } from 'containers/ToastManager';
import { ComponentType } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Root as R } from './components/Root';

const Login = withNotAuthenticatedRedir(LoginPage as ComponentType<any>);
const Root = withAuthenticatedRedir(R as ComponentType<any>);

export const App: React.FC = (): JSX.Element => (
  <>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route path="/" component={Root} />
    </Switch>

    <AuthManager />
    <ToastManager />
  </>
);
