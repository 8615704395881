import { Button } from '@chakra-ui/react';
import { EmailField } from 'components/inputs';
import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { ILoginEmailPayload } from 'services/resources/auth/types.d';

import { EmailFormValidationSchema } from '../helpers';

export interface IEmailFormProps {
  /**
   * On submit handler (email verification).
   */
  onSubmit: (
    v: ILoginEmailPayload,
    helpers?: FormikHelpers<ILoginEmailPayload>,
  ) => void;
}

export const EmailForm: React.FC<IEmailFormProps> = (props): JSX.Element => {
  const { onSubmit } = props;

  const { t } = useTranslation('login');

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={onSubmit}
      validationSchema={EmailFormValidationSchema}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <EmailField mb={4} name="email" size="md" />
          <Button
            colorScheme="blue"
            isDisabled={isSubmitting || !isValid}
            isFullWidth
            isLoading={isSubmitting}
            size="md"
            type="submit"
          >
            {t('button.continue')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
