import { IconButton, IconButtonProps } from '@chakra-ui/react';

import { ACTIONS, ButtonActionsEnum } from './helpers';

export interface ITableButtonProps extends Omit<IconButtonProps, 'aria-label'> {
  /**
   * Button action, can be `DELETE`, `RESTORE` or `ÙPDATE.
   */
  action?: keyof typeof ButtonActionsEnum;
  /**
   * On click event, it will trigger a delete, update or restore action.
   */
  onClick: () => void;
}

export const TableButton: React.FC<ITableButtonProps> = (
  props,
): JSX.Element => {
  const { action, onClick, ...rest } = props;

  const { color, icon, id } = ACTIONS[action as keyof typeof ACTIONS];

  return (
    <IconButton
      aria-label={id}
      colorScheme={color}
      icon={icon}
      onClick={onClick}
      rounded="md"
      {...rest}
    />
  );
};

TableButton.defaultProps = {
  size: 'sm',
  type: 'button',
  variant: 'solid',
};
