import {
  Modal as M,
  ModalBody,
  ModalCloseButton,
  ModalContent,
} from '@chakra-ui/react';
import { use100vh } from 'react-div-100vh';

export interface IModalProps {
  /**
   * If `true` the modal will open.
   */
  isOpen: boolean;
  /**
   * Handler that close the modal component
   */
  onClose: () => void;
}

export const Modal: React.FC<IModalProps> = (props): JSX.Element => {
  const { children, isOpen, onClose, ...rest } = props;

  const height = use100vh();

  return (
    <M isOpen={isOpen} onClose={onClose} {...rest}>
      <ModalContent
        bg="rgba(255, 255, 255, 0.95)"
        height={`calc(${height}px - 4rem)`}
        backdropFilter="blur(4px)"
        mb={0}
        minWidth="100%"
        mt={16}
        overflow="hidden"
        overflowY="scroll"
        position="relative"
      >
        <ModalCloseButton color="black" />
        <ModalBody py={0} textAlign="center">
          {children}
        </ModalBody>
      </ModalContent>
    </M>
  );
};
