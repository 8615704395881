import { formatDate, getEmail, getName } from 'helpers';
import fp from 'lodash/fp';

export const getGroupName = fp.compose(getName, fp.get('group'));

export const getUserEmail = fp.compose(getEmail, fp.get('user'));

export const getIsOwner = fp.get('is_owner');

export const getIsAdmin = fp.get('is_admin');

export const getIsAccredited = fp.get(['user', 'is_accredited']);

export const getCreated = fp.compose(formatDate, fp.get('created'));
