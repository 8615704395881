import { Flex, FlexProps } from '@chakra-ui/react';

export interface IBulletProps extends FlexProps {
  /**
   * If `true`, the bullet is checked.
   */
  isChecked: boolean;
  /**
   * If `true`, the bullet is currently selected.
   */
  isSelected: boolean;
}

export const Bullet: React.FC<IBulletProps> = (props): JSX.Element => {
  const { children, isChecked, isSelected, ...rest } = props;

  // eslint-disable-next-line no-nested-ternary
  const bg = isChecked ? 'green.300' : isSelected ? 'blue' : 'white';
  const color = isSelected || isChecked ? 'white' : 'blue';

  return (
    <Flex
      bg={bg}
      borderColor={isChecked ? 'transparent' : 'blue'}
      color={color}
      {...rest}
    >
      {children}
    </Flex>
  );
};

Bullet.defaultProps = {
  align: 'center',
  borderWidth: '1px',
  borderRadius: '50%',
  fontSize: 'sm',
  fontWeight: 'bold',
  h: 5,
  justify: 'center',
  overflow: 'visible',
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  w: 5,
};
