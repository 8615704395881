import { CRUDProvider } from 'components/providers';
import { useTranslation } from 'react-i18next';
import { BankStatementResources } from 'services/resources/financial/bank-statements';

import { Page } from './components/Page';

export const BankStatementPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation('bank-statement');

  return (
    <CRUDProvider
      allowPost
      quote={t('page.quote')}
      resource={BankStatementResources}
      searchBy="email"
      title={t('page.title')}
    >
      <Page />
    </CRUDProvider>
  );
};
