import { QuerySuggestions } from 'components/elements';
import {
  CheckboxField,
  DateField,
  InputField,
  SelectField,
  TextField,
} from 'components/inputs';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { genericTransform } from 'helpers/transform-helpers';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SpotlightTypeResources } from 'services/resources/catalogs/spotlight-type';
import { ISpotlightPayload } from 'services/resources/spotlight/types.d';
import { ISelectOption } from 'types.d';

import { SpotlightFormValidationSchema } from './helpers';

export interface ISpotlightFormProps {
  /**
   * Form initial values, can be a complete or partial ISpotlightPayload
   * object which will allow to support create and update requests.
   */
  initialValues: ISpotlightPayload;
  /**
   * On submit action, for this form this will be controlled from the outside
   * of this component thanks to the forward ref property.
   */
  onSubmit: (
    v: ISpotlightPayload,
    h?: FormikHelpers<ISpotlightPayload>,
  ) => void;
}

export const SpotlightForm = forwardRef<
  FormikProps<ISpotlightPayload>,
  ISpotlightFormProps
>((props, ref) => {
  const { initialValues, onSubmit } = props;
  const { t } = useTranslation(['spotlight', 'common']);

  return (
    <Formik
      initialValues={{ ...initialValues }}
      innerRef={ref}
      onSubmit={onSubmit}
      validationSchema={SpotlightFormValidationSchema}
    >
      <Form>
        <InputField label={t('form.input.title.label')} mb={4} name="title" />

        <TextField
          label={t('form.input.description.label')}
          mb={4}
          name="description"
        />

        <InputField label={t('form.input.link.label')} mb={4} name="link" />

        <DateField
          label={t('form.input.expiration-date.label')}
          mb={4}
          name="expiration_date"
        />

        <QuerySuggestions
          query={SpotlightTypeResources.findAll}
          transform={genericTransform}
        >
          {({ loading, value }) => (
            <SelectField
              isLoading={loading}
              label={t('form.input.spotlight-type.label')}
              mb={4}
              name="spotlight_type"
              options={value as ISelectOption[]}
              placeholder={t('form.select.default-placeholder', {
                ns: 'common',
              })}
            />
          )}
        </QuerySuggestions>

        <CheckboxField label="Is Active" name="is_active" />
      </Form>
    </Formik>
  );
});

SpotlightForm.displayName = 'SpotlightForm';
