import { instance } from 'services';
import { IFindFnReturn, IQueryParams, IResponse } from 'services/types.d';

import { IValuationPayload, IValuationResource } from './types.d';

export const ValuationResources = {
  findAll: (p?: IQueryParams): Promise<IFindFnReturn<IValuationResource>> =>
    instance.get('/valuation/', { params: p }),

  create: (params: IValuationPayload): Promise<IResponse<IValuationResource>> =>
    instance.post(`/valuation/`, params),

  update: (
    id: number,
    params: IValuationPayload,
  ): Promise<IResponse<IValuationResource>> =>
    instance.patch(`/valuation/${id}/`, params),

  delete: (id: number): Promise<IResponse<unknown>> =>
    instance.delete(`/valuation/${id}/`),
};
