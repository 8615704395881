import * as Yup from 'yup';

export const EmailFormValidationSchema = Yup.object().shape({
  email: Yup.string().email('errors.email').required('errors.required'),
});

export const PasswordFormValidationSchema = Yup.object().shape({
  email: Yup.string().email('errors.email').required('errors.required'),
  password: Yup.string().required('errors.required'),
});
