import { instance } from 'services';
import { IFindFnReturn, IQueryParams, IResponse } from 'services/types.d';

import {
  IKYCHistoryResource,
  IKYCPhysicalPayload,
  IKYCPhysicalResource,
} from './types.d';

const CUSTOM_HEADERS = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};

export const KYCHistoryResources = {
  findAll: (p?: IQueryParams): Promise<IFindFnReturn<IKYCHistoryResource>> =>
    instance.get('/kyc-history/', { params: p }),

  create: (
    params: IKYCPhysicalPayload,
  ): Promise<IResponse<IKYCPhysicalResource>> =>
    instance.post(`/kyc-physical/`, params),

  update: (
    id: number,
    params: IKYCPhysicalPayload,
    isMultipart = false,
  ): Promise<IResponse<IKYCPhysicalResource>> =>
    instance.patch(
      `/kyc-physical/${id}/`,
      params,
      isMultipart ? CUSTOM_HEADERS : {},
    ),

  delete: (id: number): Promise<IResponse<unknown>> =>
    instance.delete(`/kyc-physical/${id}/`),
};
