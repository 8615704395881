import { Box, BoxProps } from '@chakra-ui/react';

export const Divider: React.FC<BoxProps> = (props): JSX.Element => (
  <Box {...props} />
);

Divider.defaultProps = {
  borderBottom: '2px',
  borderColor: 'blackAlpha.100',
};
