import { Box, BoxProps, Table, Tbody, Thead, Tr } from '@chakra-ui/react';
import { EmptyContainer, Loading } from 'components/elements';
import { Th } from 'components/lib';
import { nanoid } from 'nanoid';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface ICustomTableProps extends BoxProps {
  /**
   * Table custom headers, if the parent component doesn't send any value, then
   * the empty layout will be displayed. This prop will be an array of strings
   * where each element must be an i18n key from the `tables` namespace.
   */
  headers?: string[];
  /**
   * If `true`, then the rendered component will be an empty container that
   * will notify the user that there are not results for its request.
   */
  isEmpty: boolean;
  /**
   * If `true`, then the table will display an overlay layout with a spinner
   * as a loading state.
   */
  isLoading: boolean;
}

export const CustomTable: React.FC<ICustomTableProps> = (
  props,
): JSX.Element => {
  const { children, headers = [], isEmpty, isLoading, ...rest } = props;

  const { t } = useTranslation('tables');

  const [height, setHeight] = useState<number>(0);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref?.current) setHeight(ref?.current?.offsetHeight);
  }, [ref?.current]);

  if (!isLoading && isEmpty) return <EmptyContainer mx={8} />;

  return (
    <Box ref={ref} {...rest}>
      <Box maxH={`${height}px`} minH="300px" overflowX="scroll">
        <Table position="relative">
          <Thead bg="white" position="sticky" top={0} zIndex="docked">
            <Tr>
              {headers.map((header) => (
                <Th key={nanoid()}>{t(header)}</Th>
              ))}
              <Th />
            </Tr>
          </Thead>
          <Tbody>{children}</Tbody>
        </Table>
      </Box>
      <Loading isLoading={isLoading} />
    </Box>
  );
};

CustomTable.defaultProps = {
  flex: 1,
  position: 'relative',
};
