export enum SearchParams {
  'company__name' = 'company__name',
  'email__contains' = 'email__contains',
  'code__name' = 'code',
  email = 'email',
  name = 'name',
  'user__email__contains' = 'user__email__contains',
  slug = 'slug',
}

export enum SearchPlaceholders {
  'company__name' = 'Company',
  'email__contains' = 'Email',
  'code__name' = 'Code',
  email = 'Email',
  name = 'Name',
  slug = 'SEO Slug',
}
