import { format, parseISO } from 'date-fns';
import es from 'date-fns/locale/es';
import fp from 'lodash/fp';
import numeral from 'numeral';

export const DEFAULT_FORMAT = "d 'de' MMMM yyyy";

export const DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";

export const SIMPLE_DATE_FORMAT = 'yyyy-MM-dd';

export const formatDate = (
  s?: number | string,
  f: string = DEFAULT_FORMAT,
): string => {
  if (fp.isNil(s)) return '-';
  return format(new Date(parseISO(s as string)), f, {
    locale: es,
  });
};

export const formatAmount = fp.curry((value) => numeral(value).format('$0,0'));

export const formatPercentage = fp.curry((value) =>
  numeral(value).format('0%'),
);

export const getPastDate = (
  initialDay: Date,
  daySubstract: number,
  dateFormat = DATETIME_FORMAT,
): string => {
  const filter = initialDay.setDate(initialDay.getDate() - daySubstract);
  return format(filter, dateFormat);
};

export const userWeeklyRegistries = fp.compose(
  fp.map(([key, v]) => ({
    day: key,
    registries: v.length,
    pv: 2400,
    amt: 2400,
  })),
  fp.entries,
  fp.groupBy('created'),
  fp.map((item: { created: string }) => ({
    created: new Date(item.created).toLocaleString('en-us', {
      weekday: 'long',
    }),
  })),
);

export const userWeeklyAccreditations = fp.compose(
  fp.map(([key, v]) => ({
    day: key,
    registries: v.length,
    pv: 2400,
    amt: 2400,
  })),
  fp.entries,
  fp.groupBy('accreditation_date'),
  fp.map((item: { accreditation_date?: string }) => ({
    accreditation_date: new Date(
      item?.accreditation_date as string,
    ).toLocaleString('en-us', {
      weekday: 'long',
    }),
  })),
);

export const investmentWeeklyRegistries = fp.compose(
  fp.map(([key, v]) => ({
    day: key,
    registries: v.length,
    pv: 2400,
    amt: 2400,
  })),
  fp.entries,
  fp.groupBy('created'),
  fp.map((item: { created: string }) => ({
    created: new Date(item.created).toLocaleString('en-us', {
      weekday: 'long',
    }),
  })),
);

export const groupMonthlyRegistries = fp.compose(
  fp.map(([key, v]) => ({
    day: key,
    registries: v.length,
    pv: 2400,
    amt: 2400,
  })),
  fp.entries,
  fp.groupBy('created'),
  fp.map((item: { created: string }) => ({
    created: new Date(item.created).toLocaleString('en-us', {
      weekday: 'long',
    }),
  })),
);
