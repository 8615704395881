import { Box, BoxProps } from '@chakra-ui/react';

export interface IContainerProps extends BoxProps {
  /**
   * If true, the container will take the 100% available width, otherwise its
   * max-width will be set to 1200px.
   */
  isFluid?: boolean;
}

export const Container: React.FC<IContainerProps> = (props): JSX.Element => {
  const { children, isFluid = false, ...rest } = props;

  return (
    <Box
      {...(isFluid ? { maxW: '100%', px: 2 } : { maxW: '1200px', px: 4 })}
      {...rest}
    >
      {children}
    </Box>
  );
};

Container.defaultProps = {
  mx: 'auto',
  position: 'relative',
  w: '100%',
};
