import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { DetailPage, ListingPage } from './pages';

export const KYBMoralPages: React.FC = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route component={ListingPage} exact path={path} />
      <Route component={DetailPage} path={`${path}/:slug`} />
    </Switch>
  );
};
