import { Image, ImageProps, Link, Spinner } from '@chakra-ui/react';
import { Link as ReachLink } from 'react-router-dom';

export const Logo: React.FC<ImageProps> = (props): JSX.Element => (
  <Link as={ReachLink} to="/">
    <Image fallback={<Spinner />} {...props} />
  </Link>
);

Logo.defaultProps = {
  fit: 'cover',
};
