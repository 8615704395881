import { Box, BoxProps, SimpleGrid } from '@chakra-ui/react';
import { H2 } from 'components/elements';
import { useTranslation } from 'react-i18next';
import { IFinerioAccountDetailResource } from 'services/resources/finerio/types.d';

import {
  getAnnualPercentageRate,
  getAvailableBalance,
  getClosingDate,
  getCreditLimit,
  getDueDate,
  getMinimumPayment,
  getNonInterestPayment,
  getStatementBalance,
} from '../helpers';
import { Record } from './Record';

export interface ICreditInformationProps extends BoxProps {
  /**
   * Account details, such as card number, credit limit, due dates, etc.
   * @type {IFinerioAccountDetailResource}
   */
  details?: IFinerioAccountDetailResource;
}

export const CreditInformation: React.FC<ICreditInformationProps> = (
  props,
): JSX.Element => {
  const { details, ...rest } = props;

  const { t } = useTranslation('finerio');

  return (
    <Box {...rest}>
      <H2 mb={8} textTransform="uppercase">
        {t('modal.credit.title')}
      </H2>

      <SimpleGrid columns={[1, 1, 2, 3]} spacing={4}>
        <Record
          description={getCreditLimit(details)}
          title={t('modal.credit.limit')}
        />
        <Record
          description={getStatementBalance(details)}
          title={t('modal.credit.statement-balance')}
        />
        <Record
          description={getAvailableBalance(details)}
          title={t('modal.credit.available-balance')}
        />
        <Record
          description={getNonInterestPayment(details)}
          title={t('modal.credit.non-interest-payment')}
        />
        <Record
          description={getMinimumPayment(details)}
          title={t('modal.credit.minimum-payment')}
        />
        <Record
          description={getAnnualPercentageRate(details)}
          title={t('modal.credit.anual-percentage')}
        />
        <Record
          description={getDueDate(details)}
          title={t('modal.credit.due-date')}
        />
        <Record
          description={getClosingDate(details)}
          title={t('modal.credit.closing-date')}
        />
      </SimpleGrid>
    </Box>
  );
};

CreditInformation.defaultProps = {
  w: '100%',
};
