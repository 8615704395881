/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Stack, StackProps } from '@chakra-ui/react';
import { useGenericContext } from 'components/contexts/useGenericContext';
import { QuerySuggestions, SearchFilter } from 'components/elements';
import { genericTransform, groupTransform } from 'helpers/transform-helpers';
import fp from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { JournalActivityResources } from 'services/resources/catalogs/journal-activity';
import { CompanyResources } from 'services/resources/deal-flow/company';
import { GroupResources } from 'services/resources/group/admin-group';

import { SearchParams, SearchPlaceholders } from '../helpers';

export interface IActionsProps extends StackProps {
  /**
   * If `true`, the provider will render a button that will allow the user to
   * perform a POST operation.
   */
  allowPost?: boolean;
  /**
   * If `true`, it will render the company filter in order to retrieve items
   * based on the company ID.
   */
  companyFilter?: boolean;
  /**
   * If `true`, it will render the group filter in order to retrieve items
   * based on the group name.
   */
  groupFilter?: boolean;
  /**
   * Overrides the default post action when clicking the create button.
   */
  customPostAction?: () => void;
  /**
   * If `true`, it will render the journal activity filter in order to retrieve
   * items based on the journal activity ID.
   */
  journalActivityFilter?: boolean;
  /**
   * Search parameter, this prop must be a key of the `SearchParams` enum.
   */
  searchBy?: keyof typeof SearchParams;
}

export const Actions: React.FC<IActionsProps> = (props): JSX.Element => {
  const {
    allowPost,
    companyFilter,
    groupFilter,
    customPostAction,
    journalActivityFilter,
    searchBy,
    ...rest
  } = props;

  const {
    onPrepareCreate: preparePost,
    onFilterByCompany,
    onFilterByJournalActivity,
    onFilterByGroupName,
    onSearchRegistry,
  } = useGenericContext();

  const { t } = useTranslation('common');

  return (
    <Stack
      justify={fp.isNil(searchBy) ? 'flex-end' : 'space-between'}
      {...rest}
    >
      <Stack isInline>
        <SearchFilter
          display={!fp.isNil(searchBy) ? 'block' : 'none'}
          onChange={onSearchRegistry}
          placeholder={t('inputs.search-by', {
            value: SearchPlaceholders[searchBy as keyof typeof SearchParams],
          })}
          w={{ base: 'auto', md: '320px' }}
        />

        {companyFilter ? (
          <QuerySuggestions
            query={CompanyResources.findAll}
            transform={genericTransform}
          >
            {({ loading, value }) => (
              <Box
                as={Select}
                isClearable
                isLoading={loading}
                onChange={(v: any) => onFilterByCompany(v?.value)}
                options={value}
                w={{ base: 'auto', md: '320px' }}
                zIndex="dropdown"
              />
            )}
          </QuerySuggestions>
        ) : null}

        {groupFilter ? (
          <QuerySuggestions
            query={GroupResources.findAllNames}
            transform={groupTransform}
          >
            {({ loading, value }) => (
              <Box
                as={Select}
                isClearable
                isLoading={loading}
                onChange={(v: any) => onFilterByGroupName(v?.value)}
                options={value}
                w={{ base: 'auto', md: '320px' }}
                zIndex="dropdown"
              />
            )}
          </QuerySuggestions>
        ) : null}

        {journalActivityFilter ? (
          <QuerySuggestions
            query={JournalActivityResources.findAll}
            transform={genericTransform}
          >
            {({ loading, value }) => (
              <Box
                as={Select}
                isClearable
                isLoading={loading}
                onChange={(v: any) => onFilterByJournalActivity(v?.value)}
                options={value}
                w={{ base: 'auto', md: '320px' }}
                zIndex="dropdown"
              />
            )}
          </QuerySuggestions>
        ) : null}
      </Stack>

      {allowPost ? (
        <Button
          colorScheme="green"
          onClick={fp.isNil(customPostAction) ? preparePost : customPostAction}
          px={8}
          size="sm"
          type="button"
        >
          {t('button.create')}
        </Button>
      ) : null}
    </Stack>
  );
};

Actions.defaultProps = {
  align: 'center',
  isInline: true,
};
