import { formatDate } from 'helpers';
import fp from 'lodash/fp';

export const getCode = fp.get('code');

export const getCreated = fp.compose(formatDate, fp.get('created'));

export const getInvoice = fp.get('invoice');

export const getPeriodStart = fp.compose(formatDate, fp.get('period_start'));

export const getPeriodEnd = fp.compose(formatDate, fp.get('period_end'));
