import { CheckboxField, InputField, SelectField } from 'components/inputs';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IGroupPayload } from 'services/resources/group/admin-group/types.d';

import { GroupFormValidationSchema } from './helpers';

export interface IGroupFormProps {
  /**
   * Form initial values, can be a complete or partial IGroupPayload
   * object which will allow to support create and update requests.
   */
  initialValues: IGroupPayload;
  /**
   * On submit action, for this form this will be controlled from the outside
   * of this component thanks to the forward ref property.
   */
  onSubmit: (v: IGroupPayload, h?: FormikHelpers<IGroupPayload>) => void;
}

export const GroupForm = forwardRef<
  FormikProps<IGroupPayload>,
  IGroupFormProps
>((props, ref) => {
  const { initialValues, onSubmit } = props;
  const { t } = useTranslation(['groups', 'common']);

  return (
    <Formik
      initialValues={{
        id: initialValues.id,
        name: initialValues.name,
        description: initialValues.description,
        is_private: initialValues.is_private,
        request_private: initialValues.request_private,
      }}
      innerRef={ref}
      onSubmit={onSubmit}
      validationSchema={GroupFormValidationSchema}
    >
      <Form>
        <InputField
          label={t('inputs.name.label', { ns: 'common' })}
          mb={4}
          name="name"
        />

        <InputField
          label={t('form.input.description.label', { ns: 'common' })}
          mb={4}
          name="description"
        />

        <CheckboxField
          label={t('form.input.is-private.label', { ns: 'groups' })}
          name="is_private"
        />

        <CheckboxField
          label={t('form.input.request-private.label', { ns: 'groups' })}
          name="request_private"
        />
      </Form>
    </Formik>
  );
});

GroupForm.displayName = 'GroupForm';
