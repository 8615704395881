import { instance } from 'services';
import { IFinerioFindFnReturn, IResponse } from 'services/types.d';

import {
  IFinerioAccountDetailResource,
  IFinerioAccountResource,
  IFinerioCredentialResource,
  IFinerioQueryParams,
  IFinerioTransactionResource,
} from './types.d';

export const FinerioResources = {
  findCredentials: (
    p?: IFinerioQueryParams,
  ): Promise<IFinerioFindFnReturn<IFinerioCredentialResource>> =>
    instance.get('/finerio/credential/', { params: p }),

  findAccountsByCredential: (
    p?: IFinerioQueryParams,
  ): Promise<IFinerioFindFnReturn<IFinerioAccountResource>> =>
    instance.get(`/finerio/account/`, { params: p }),

  findTransactionsByAccount: (
    p?: IFinerioQueryParams,
  ): Promise<IFinerioFindFnReturn<IFinerioTransactionResource>> =>
    instance.get(`/finerio/transaction/`, { params: p }),

  findAccountDetails: (
    id: string,
  ): Promise<IResponse<IFinerioAccountDetailResource>> =>
    instance.get(`/finerio/account/${id}/detail`),
};
