import {
  Avatar,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  List,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react';
import { NavButton } from 'components/elements';
import HeaderLogo from 'images/logo.svg';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { INavigation } from 'types.d';

import { Logo } from './Logo';

export interface IMobileMenuProps extends Omit<DrawerProps, 'children'> {
  /**
   * User avatar image to render in the UI, in case that the user doesn't have
   * set an avatar, it will display it username initials.
   */
  avatar?: string;
  /**
   * If true, the user navigation dropdown will not be rendered.
   */
  isFetching: boolean;
  /**
   * Navigation routes that will be rendered in the header element or in the
   * drawer menu in case of mobile screen.
   */
  navigation: INavigation[];
  /**
   * On click events it will trigger the logout redux action and dispatch the
   * respective sagas in order to destroy the user's session.
   */
  onLogout: () => void;
  /**
   * User's fullname if it has complete its profile, otherwise it will render
   * the user's email.
   */
  username?: string;
}

export const MobileMenu: React.FC<IMobileMenuProps> = (props): JSX.Element => {
  const {
    avatar,
    isFetching,
    isOpen,
    onClose,
    onLogout,
    navigation,
    username,
    ...rest
  } = props;

  const { t } = useTranslation('common');

  return (
    <Drawer isOpen={isOpen} onClose={onClose} {...rest}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />

        <DrawerHeader borderBottomWidth="2px" borderColor="blackAlpha.100">
          <Logo alt="logo" src={HeaderLogo} width="160px" />
        </DrawerHeader>

        <DrawerBody p={0}>
          <List>
            {!isFetching ? (
              <ListItem borderBottom="2px" borderColor="blackAlpha.100" py={2}>
                <Stack align="center" isInline justify="center" mx={2}>
                  <Avatar
                    bg="blue.200"
                    name={username}
                    size="sm"
                    src={avatar}
                  />
                  <Text fontSize="sm" fontWeight="medium">
                    {username}
                  </Text>
                </Stack>
              </ListItem>
            ) : null}

            {navigation.map((item) => (
              <ListItem h="100%" key={nanoid()}>
                <NavButton>{t(item?.label)}</NavButton>
              </ListItem>
            ))}
          </List>
        </DrawerBody>

        <DrawerFooter px={0}>
          <NavButton borderTop="2px" onClick={onLogout}>
            {t('header.logout')}
          </NavButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

MobileMenu.defaultProps = {
  placement: 'right',
};
