import { Box, BoxProps } from '@chakra-ui/react';
import { formatDate } from 'helpers';
import { nanoid } from 'nanoid';
import { IUserResource } from 'services/resources/users/user/types.d';

import { Item } from './components/Item';

export interface IUserListProps extends BoxProps {
  /**
   * Date key that will be displayed on the listing.
   */
  dateKey: string;
  /**
   * List of users registered by the filtered date.
   */
  registries?: IUserResource[];
}

export const UserList: React.FC<IUserListProps> = (props): JSX.Element => {
  const { dateKey, registries, ...rest } = props;

  return (
    <Box {...rest}>
      {registries?.map((item) => (
        <Item
          avatar={item?.avatar as string}
          created={formatDate(item[dateKey])}
          email={item?.email as string}
          key={nanoid()}
          mb={4}
        />
      ))}
    </Box>
  );
};

UserList.defaultProps = {
  maxH: '128px',
  overflow: 'scroll',
};
