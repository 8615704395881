import * as Yup from 'yup';

export const InvestmentFormValidationSchema = Yup.object().shape({
  amount: Yup.number().required('errors.required'),
  deal: Yup.string().required('errors.required'),
  deposit_date: Yup.string().nullable(),
  fee: Yup.number().required('errors.required'),
  has_exceeds_max_udis: Yup.bool().oneOf([false, true]),
  investment_status: Yup.number().required('errors.required'),
  is_active: Yup.bool().oneOf([false, true]),
  is_foreign_payment: Yup.bool().oneOf([false, true]),
  is_user_related_to_deal: Yup.bool().oneOf([false, true]),
  promo: Yup.string().nullable(),
  total: Yup.number().required('errors.required'),
  user: Yup.string().required('errors.required'),
});
