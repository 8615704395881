import { FormikHelpers } from 'formik';
import {
  ILoginEmailPayload,
  ILoginPayload,
} from 'services/resources/auth/types.d';
import { LogInMachineNodes } from 'x-state/nodes';

import { EmailForm } from './components/EmailForm';
import { PasswordForm } from './components/PasswordForm';

export interface ILogInFormProps {
  /**
   * Form initial values (full login payload).
   */
  initialValues: ILoginPayload;
  /**
   * Investment machine current node.
   */
  node: string;
  /**
   * On submit handler.
   */
  onEmailSubmit: (
    v: ILoginEmailPayload,
    helpers?: FormikHelpers<ILoginEmailPayload>,
  ) => Promise<void>;
  /**
   * On submit handler (full login payload).
   */
  onSubmit: (v: ILoginPayload, helpers?: FormikHelpers<ILoginPayload>) => void;
}

export const LogInForm: React.FC<ILogInFormProps> = (props): JSX.Element => {
  const { initialValues, node, onEmailSubmit, onSubmit } = props;

  if (node !== LogInMachineNodes.VERIFIED)
    return <EmailForm onSubmit={onEmailSubmit} />;

  return <PasswordForm initialValues={initialValues} onSubmit={onSubmit} />;
};
