import { CRUDProvider } from 'components/providers';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { DealResources } from 'services/resources/deal-flow/deal';

import { Page } from './components/Page';

export const ListingPage: React.FC = (): JSX.Element => {
  const history = useHistory();

  const { path } = useRouteMatch();

  const { t } = useTranslation('deals');

  return (
    <CRUDProvider
      allowPost
      customPostAction={() => history.push(`${path}/create`)}
      quote={t('page.quote')}
      resource={DealResources}
      searchBy="slug"
      title={t('page.title')}
    >
      <Page />
    </CRUDProvider>
  );
};
