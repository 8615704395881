import { CRUDProvider } from 'components/providers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserGroupResources } from 'services/resources/group/user-group/user-group';

import { Page } from './components/Page';

export const UserPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation('user-group');
  return (
    <CRUDProvider
      allowUserGroupFilters
      allowIsActive={false}
      csvButton
      quote={t('page.quote')}
      groupFilter
      resource={UserGroupResources}
      searchBy="email"
      title={t('page.title')}
    >
      <Page />
    </CRUDProvider>
  );
};
