import {
  Box,
  BoxProps,
  Editable,
  EditableInput,
  EditablePreview,
  Text,
} from '@chakra-ui/react';
import {
  addErrorToast,
  addSuccessToast,
} from 'containers/ToastManager/store/slice';
import fp from 'lodash/fp';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { KYBMoralResources } from 'services/resources/users/kyb-moral';

export interface IRecordProps extends Omit<BoxProps, 'id'> {
  /**
   * Description that the component will display.
   */
  description: string;
  /**
   * KYC unique identifier.
   */
  id?: number;
  /**
   * If `true`, then the record will be editable.
   */
  isEditable?: boolean;
  /**
   * Name of the registry in DB on the KYC Physical Person model.
   */
  name?: string;
  /**
   * Title that the component will display.
   */
  title: string;
}

export const Record: React.FC<IRecordProps> = (props): JSX.Element => {
  const {
    description,
    id = 0,
    isEditable = false,
    name = '',
    title,
    ...rest
  } = props;

  const dispatch = useDispatch();

  const handleOnSubmit = useCallback(async (v: string) => {
    try {
      if (v === description || v === '-' || fp.isEmpty(v)) return;
      await KYBMoralResources.update(id, { [name]: v });
      dispatch(addSuccessToast('toast.success.network'));
    } catch (e) {
      dispatch(addErrorToast('toast.errors.network'));
    }
  }, []);

  const isFallback = fp.isNil(description) || fp.isEmpty(description);

  return (
    <Box {...rest}>
      <Text fontSize="sm" fontWeight="bold">
        {title}
      </Text>
      <Editable
        color="blackAlpha.700"
        defaultValue={!isFallback ? description : '-'}
        fontSize="md"
        fontWeight="medium"
        isDisabled={!isEditable}
        onSubmit={handleOnSubmit}
      >
        <EditablePreview cursor={isEditable ? 'pointer' : 'not-allowed'} />
        <EditableInput cursor={isEditable ? 'text' : 'not-allowed'} />
      </Editable>
    </Box>
  );
};
