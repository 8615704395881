import { CRUDProvider } from 'components/providers';
import { useTranslation } from 'react-i18next';
import { KYCPhysicalResources } from 'services/resources/users/kyc-physical';

import { Page } from './components/Page';

export const ListingPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation('kyc-physical');

  return (
    <CRUDProvider
      quote={t('page.quote')}
      resource={KYCPhysicalResources}
      searchBy="email"
      title={t('page.title')}
    >
      <Page />
    </CRUDProvider>
  );
};
