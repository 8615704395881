import styled from '@emotion/styled';
import { Container } from 'components/elements';
import get from 'lodash/fp/get';

export const Paper = styled(Container)`
  background: white;
  box-shadow: ${({ theme }) => get('shadows.md')(theme)};
  display: flex;
  position: relative;
  z-index: ${({ theme }) => get('zIndices.docked')(theme)};

  @media (min-width: 768px) {
    &::after,
    &::before {
      content: '';
      display: block;
      height: 100px;
      position: absolute;
      width: 100px;
    }

    &:after {
      background: white;
      right: 0;
      top: 0;
    }

    &:before {
      background: ${({ theme }) => get('colors.blue.500')(theme)};
      border-radius: 50%;
      right: -50px;
      top: -50px;
      z-index: ${({ theme }) => get('zIndices.base')(theme)};
    }
  }
`;
