import { List, ListItem, ListProps } from '@chakra-ui/react';
import { NavLink } from 'components/elements';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { INavigation } from 'types.d';

import { UserNavigation } from './UserNavigation';

export interface IDesktopMenuProps extends ListProps {
  /**
   * User avatar image to render in the UI, in case that the user doesn't have
   * set an avatar, it will display it username initials.
   */
  avatar?: string;
  /**
   * If true, the user navigation dropdown will not be rendered.
   */
  isFetching: boolean;
  /**
   * On click events it will trigger the logout redux action and dispatch the
   * respective sagas in order to destroy the user's session.
   */
  onLogout: () => void;
  /**
   * Navigation routes that will be rendered in the header element or in the
   * drawer menu in case of mobile screen.
   */
  routes: INavigation[];
  /**
   * User's fullname if it has complete its profile, otherwise it will render
   * the user's email.
   */
  username?: string;
}

export const DesktopMenu: React.FC<IDesktopMenuProps> = (
  props,
): JSX.Element | null => {
  const { avatar, isFetching, onLogout, routes, username, ...rest } = props;

  const { t } = useTranslation('header');

  return (
    <List {...rest}>
      {routes.map(({ label, path }) => (
        <ListItem h="100%" key={nanoid()} mr={4}>
          <NavLink path={path as string}>{t(label)}</NavLink>
        </ListItem>
      ))}
      <ListItem d={!isFetching ? 'list-item' : 'none'} h="100%">
        <UserNavigation
          avatar={avatar}
          navigation={[]}
          onLogout={onLogout}
          username={username}
        />
      </ListItem>
    </List>
  );
};

DesktopMenu.defaultProps = {
  alignItems: 'center',
  display: 'flex',
  h: '100%',
};
