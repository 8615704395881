/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Image, Stack, Text } from '@chakra-ui/react';
import { components } from 'react-select';

export interface ISingleValueProps {
  /**
   * Select option value.
   */
  data: any;
}

export const SingleValue: React.FC<ISingleValueProps> = (
  props,
): JSX.Element => {
  const { data, ...rest } = props;
  return (
    <components.SingleValue {...rest}>
      <Stack isInline align="center">
        <Image src={data?.icon} />
        <Text color="gray.800" fontSize="sm">
          {data?.value}
        </Text>
      </Stack>
    </components.SingleValue>
  );
};
