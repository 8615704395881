import { formatDate } from 'helpers';
import fp from 'lodash/fp';

export const getTitle = fp.get('title');

export const getDescription = fp.get('description');

export const getLink = fp.get('link');

export const getExpirationDate = fp.compose(
  formatDate,
  fp.get('expiration_date'),
);

export const getSpotlightType = fp.get(['spotlight_type', 'name']);

export const getCreated = fp.compose(formatDate, fp.get('created'));
