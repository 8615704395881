import * as Yup from 'yup';

export const SpotlightFormValidationSchema = Yup.object().shape({
  description: Yup.string().required('errors.required'),
  expiration_date: Yup.string().required('errors.required'),
  is_active: Yup.bool().oneOf([false, true]).required('errors.required'),
  link: Yup.string().nullable(),
  title: Yup.string().required('errors.required'),
  spotlight_type: Yup.string().required('errors.required'),
});
