import { Box, BoxProps, SimpleGrid } from '@chakra-ui/react';
import BlankInformation from 'images/blank-information.png';
import fp from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { IKYCPhysicalResource } from 'services/resources/users/kyc-physical/types.d';
import { IUserResource } from 'services/resources/users/user/types.d';

import {
  FINANCIAL_RESOURCES,
  getAccreditationDate,
  getActivity,
  getAddressProof,
  getExternalTaxId,
  getExternalTaxIdCountry,
  getFinancialResources,
  getFirstSurname,
  getId,
  getInvestmentResource,
  getKycStage,
  getLastSurname,
  getName,
  getOccupation,
  getOccupationYear,
  getRisks,
  getVulnerableActivity,
  INVESTMENT_RESOURCES,
  KYCStagesEnum,
  OCCUPATION_YEAR,
  OCCUPATIONS,
  VULNERABILITY_ACTIVITIES,
} from '../helpers';
import { EmptyBanner } from './EmptyBanner';
import { LinkButton } from './LinkButton';
import { Record } from './Record';

export interface IProfessionalInformationProps extends BoxProps {
  /**
   * KYC physical user resource data.
   */
  kyc?: IKYCPhysicalResource;
  /**
   * user resource data.
   */
  usr?: IUserResource;
}

export const ProfessionalInformation: React.FC<IProfessionalInformationProps> =
  (props): JSX.Element => {
    const { kyc, usr, ...rest } = props;

    const { t } = useTranslation('kyc-physical');

    if (getKycStage(kyc) < KYCStagesEnum.ADDRESS_INFORMATION_SUCCESS) {
      return (
        <EmptyBanner
          cover={BlankInformation}
          mb={6}
          title={t('page.detail.address.empty.title')}
        />
      );
    }

    return (
      <Box {...rest}>
        <SimpleGrid columns={[1, 1, 2, 3]} spacingY={6}>
          <Record
            description={getRisks(kyc) ? 'accepted' : 'refused'}
            id={getId(kyc)}
            isEditable
            name="risks"
            title={t('page.detail.professional.risks.title')}
            isLink
            accreditationDate={getAccreditationDate(usr)}
            username={`${getName(kyc)} ${getFirstSurname(kyc)} ${getLastSurname(
              kyc,
            )}`}
          />
          <Record
            description={
              getFinancialResources(kyc)
                ? FINANCIAL_RESOURCES[getFinancialResources(kyc)]
                : '-'
            }
            id={getId(kyc)}
            isEditable
            name="financial_resource"
            title={t('page.detail.professional.resource-origin.title')}
          />
          <Record
            description={
              getOccupation(kyc) ? OCCUPATIONS[getOccupation(kyc)] : '-'
            }
            id={getId(kyc)}
            isEditable
            name="occupation"
            title={t('page.detail.professional.occupation.title')}
          />

          <Record
            description={
              getOccupationYear(kyc)
                ? OCCUPATION_YEAR[getOccupationYear(kyc)]
                : null
            }
            id={getId(kyc)}
            isEditable
            name="occupation_year"
            title={t('page.detail.professional.occupation-year.title')}
          />

          <Record
            description={
              getVulnerableActivity(kyc)
                ? VULNERABILITY_ACTIVITIES[getVulnerableActivity(kyc)]
                : null
            }
            id={getId(kyc)}
            isEditable
            name="vulnerable_activities"
            title={t('page.detail.professional.vulnerability.title')}
          />
          <Record
            description={getActivity(kyc)}
            id={getId(kyc)}
            isEditable
            name="activity"
            title={t('page.detail.professional.activity.title')}
          />
          <Record
            description={getExternalTaxId(kyc)}
            id={getId(kyc)}
            isEditable
            name="external_tax_id"
            title={t('page.detail.professional.external-tax.title')}
          />
          <Record
            description={getExternalTaxIdCountry(kyc)}
            id={getId(kyc)}
            isEditable
            name="external_tax_id_country"
            title={t('page.detail.professional.external-tax-country.title')}
          />
          <Record
            description={
              getInvestmentResource(kyc)
                ? INVESTMENT_RESOURCES[getInvestmentResource(kyc)]
                : null
            }
            id={getId(kyc)}
            isEditable
            name="investment_resource"
            title={t('page.detail.professional.investment-resources.title')}
          />
        </SimpleGrid>

        {!fp.isNil(getAddressProof(kyc)) ? (
          <LinkButton
            mt={4}
            path={getAddressProof(kyc)}
            title={t('page.detail.address.address-proof.button')}
          />
        ) : null}
      </Box>
    );
  };
