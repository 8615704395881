import { IInputFieldProps, InputField } from 'components/inputs/InputField';
import { useTranslation } from 'react-i18next';

export type IEmailFieldProps = Omit<IInputFieldProps, 'label'>;

export const EmailField: React.FC<IEmailFieldProps> = (props): JSX.Element => {
  const { t } = useTranslation('common');

  return <InputField label={t('inputs.email.label')} type="email" {...props} />;
};
