import { isValidFileFormatWithPDF, isValidFileSize } from 'helpers';
import fp from 'lodash/fp';
import * as Yup from 'yup';

export const CompanyFormValidationSchema = Yup.object().shape({
  agent: Yup.string().nullable(),
  agent_email: Yup.string().email('errors.email').nullable(),
  business_name: Yup.string().nullable(),
  company_type: Yup.string().required('errors.required'),
  country: Yup.string().required('errors.required'),
  decklink: Yup.mixed()
    .test('file-size', 'errors.file-size', (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileSize(v.size);
    })
    .test('file-format', 'errors.file-format-pdf', (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileFormatWithPDF(v.type);
    }),
  description: Yup.string().required('errors.required'),
  differentiations: Yup.array()
    .of(Yup.string())
    .min(1, 'errors.min.items')
    .required('errors.required'),
  facebooklink: Yup.string().url('errors.url').nullable(),
  industries: Yup.array()
    .of(Yup.string())
    .min(1, 'errors.min.items')
    .required('errors.required'),
  instagramlink: Yup.string().url('errors.url').nullable(),
  is_active: Yup.bool().oneOf([false, true]).required('errors.required'),
  linkedinlink: Yup.string().url('errors.url').nullable(),
  logo: Yup.string().required('errors.required'),
  name: Yup.string().required('errors.required'),
  round: Yup.string().required('errors.required'),
  stage: Yup.string().required('errors.required'),
  twitterlink: Yup.string().url('errors.url').nullable(),
  youtubelink: Yup.string().url('errors.url').nullable(),
  webpagelink: Yup.string().url('errors.url').nullable(),
});
