import { instance } from 'services';
import { IFindFnReturn, IQueryParams, IResponse } from 'services/types.d';

import { IBankStatementPayload, IBankStatementResource } from './types.d';

const CUSTOM_HEADERS = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};

export const BankStatementResources = {
  create: (
    params: IBankStatementPayload,
  ): Promise<IResponse<IBankStatementResource>> =>
    instance.post(`/bank-statement/`, params, CUSTOM_HEADERS),

  findAll: (p?: IQueryParams): Promise<IFindFnReturn<IBankStatementResource>> =>
    instance.get('/bank-statement/', { params: p }),

  update: (
    id: number,
    params: IBankStatementPayload,
  ): Promise<IResponse<IBankStatementResource>> =>
    instance.patch(`/bank-statement/${id}/`, params, CUSTOM_HEADERS),

  delete: (id: number): Promise<IResponse<unknown>> =>
    instance.delete(`/bank-statement/${id}/`),
};
