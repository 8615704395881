import {
  FormControl,
  FormControlProps,
  FormLabelProps,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react';
import { FormErrorMessage, FormHelperText, FormLabel } from 'components/lib';
import { useField } from 'formik';

export interface ITextFieldProps extends FormControlProps {
  /**
   * Input helper text.
   */
  helper?: string;
  /**
   * Custom textarea props.
   */
  inputProps?: TextareaProps;
  /**
   * Input label.
   */
  label?: string;
  /**
   * Label color.
   */
  labelProps?: FormLabelProps;
  /**
   * Input name.
   */
  name: string;
  /**
   * Input placeholder.
   */
  placeholder?: string;
}

export const TextField: React.FC<ITextFieldProps> = (props): JSX.Element => {
  const {
    helper,
    inputProps = { bg: 'blue.50' },
    label,
    labelProps = {},
    name,
    placeholder,
    size = 'sm',
    ...rest
  } = props;

  const [field, meta] = useField(name);

  const isInvalid = !!meta.error && !!meta.touched;

  return (
    <FormControl isInvalid={isInvalid} name={name} {...rest}>
      <FormLabel fontSize={size} htmlFor={name} {...labelProps}>
        {label}
      </FormLabel>
      <Textarea
        placeholder={placeholder}
        size={size}
        {...inputProps}
        {...field}
      />
      <FormHelperText>{helper}</FormHelperText>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
