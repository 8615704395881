import { CRUDProvider } from 'components/providers';
import { useTranslation } from 'react-i18next';
import { KYBMoralResources } from 'services/resources/users/kyb-moral';

import { Page } from './components/Page';

export const ListingPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation('kyb-moral');

  return (
    <CRUDProvider
      quote={t('page.listing.quote')}
      resource={KYBMoralResources}
      searchBy="email"
      title={t('page.listing.title')}
    >
      <Page />
    </CRUDProvider>
  );
};
