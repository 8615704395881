import { Box, SimpleGrid, SimpleGridProps, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IKYBMoralPersonResource } from 'services/resources/users/kyb-moral/types.d';

import {
  getActivity,
  getId,
  getIncorporationDate,
  getKybStage,
  getNationality,
  getTradeName,
  homePhone,
  KYBStagesEnum,
} from '../helpers';
import { LegalRepresentativeInformation } from './LegalRepresentativeInformation';
import { Record } from './Record';

export interface ICompanyInformationProps extends SimpleGridProps {
  /**
   * KYB moral user resource data.
   */
  kyb?: IKYBMoralPersonResource;
}

export const CompanyInformation: React.FC<ICompanyInformationProps> = (
  props,
): JSX.Element | null => {
  const { kyb, ...rest } = props;
  const { t } = useTranslation('kyb-moral');

  if (getKybStage(kyb) < KYBStagesEnum.COMPANY_INFORMATION) {
    return null;
  }

  return (
    <>
      <SimpleGrid mb={2} {...rest}>
        <Record
          description={getTradeName(kyb)}
          id={getId(kyb)}
          isEditable
          name="trade name"
          title={t('page.detail.company-information.trade-name.title')}
        />

        <Record
          description={getIncorporationDate(kyb)}
          id={getId(kyb)}
          isEditable
          name="incorporation_date"
          title={t('page.detail.company-information.incorporation-date.title')}
        />

        <Record
          description={homePhone(kyb)}
          id={getId(kyb)}
          isEditable
          name="home_phone"
          title={t('page.detail.company-information.home-phone.title')}
        />

        <Record
          description={getNationality(kyb)}
          id={getId(kyb)}
          isEditable
          name="nationality"
          title={t('page.detail.company-information.nationality.title')}
        />
      </SimpleGrid>

      <Text fontSize="sm" fontWeight="bold">
        {t('page.detail.company-information.activity.title')}
      </Text>
      <Box
        h="200px"
        mb={4}
        overflow="scroll"
        w={{ base: '550px', md: '800px' }}
      >
        <Text mb={4}>{getActivity(kyb)}</Text>
      </Box>

      <Text mb={4} fontWeight="semibold">
        {t('page.detail.company-information.subtitle')}
      </Text>
      <LegalRepresentativeInformation kyb={kyb} />
    </>
  );
};

CompanyInformation.defaultProps = {
  columns: [1, 1, 2, 3],
  spacingY: 6,
};
