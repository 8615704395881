import { Button, Flex, FlexProps, Icon, Text } from '@chakra-ui/react';
import { useGenericContext } from 'components/contexts/useGenericContext';
import { Modal } from 'components/elements';
import { getId } from 'helpers';
import { useTranslation } from 'react-i18next';
import { VscWarning } from 'react-icons/vsc';

export const DeleteModal: React.FC<FlexProps> = (props): JSX.Element => {
  const { defaultDelete, isModalOpen, registry, state, toggleModal } =
    useGenericContext();

  const { t } = useTranslation('common');

  return (
    <Modal isOpen={isModalOpen} onClose={toggleModal}>
      <Flex {...props}>
        <Icon as={VscWarning} boxSize={24} color="red" mb={2} />

        <Text fontSize="sm" mb={2}>
          {t('delete.quote', { value: getId(registry) || 0 })}
        </Text>

        <Text as="strong" color="blackAlpha.400" fontSize="xs" mb={8}>
          {t('delete.reminder')}
        </Text>

        <Flex
          direction={{ base: 'column-reverse', lg: 'row' }}
          justify="space-around"
          w="100%"
        >
          <Button
            isDisabled={state?.loading}
            onClick={toggleModal}
            px={8}
            size="sm"
            variant="outline"
            w={{ base: '100%', lg: 'auto' }}
          >
            {t('button.cancel')}
          </Button>
          <Button
            colorScheme="red"
            isDisabled={state?.loading}
            isLoading={state?.loading}
            mb={2}
            onClick={defaultDelete}
            px={8}
            size="sm"
            w={{ base: '100%', lg: 'auto' }}
          >
            {t('button.delete')}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

DeleteModal.defaultProps = {
  align: 'center',
  direction: 'column',
  h: '100%',
  justify: 'center',
  maxW: '512px',
  mx: 'auto',
  w: '100%',
};
