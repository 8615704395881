import { IDealPayload } from 'services/resources/deal-flow/deal/types.d';
import { assign, createMachine, StateMachine } from 'xstate';

import { DealMachineEvent } from '../events';
import { DealMachineNodes } from '../nodes';
import { DealMachineSchema } from '../schemas';

const CONTEXT = {
  carry: 0,
  exchange: 1,
  fee: 0,
  funding_goal: 0,
  is_active: true,
  min_investment: 0,
  raised: 0,
  exchange_type: 0,
  exchange_type_date: '',
  usd_cap: 0,
};

export const getDealMachine = (
  initial: string | symbol = DealMachineNodes.GENERAL,
): StateMachine<IDealPayload, DealMachineSchema, DealMachineEvent> =>
  createMachine({
    id: 'deal',
    context: CONTEXT as IDealPayload,
    initial,
    states: {
      [DealMachineNodes.GENERAL]: {
        on: {
          NEXT: {
            target: DealMachineNodes.FINANCIAL,
            actions: assign((_, state) => ({ ...state })),
          },
        },
      },
      [DealMachineNodes.FINANCIAL]: {
        on: {
          NEXT: {
            target: DealMachineNodes.SECURITY_TYPE,
            actions: assign((_, state) => ({ ...state })),
          },
        },
      },
      [DealMachineNodes.SECURITY_TYPE]: {
        on: {
          NEXT: {
            target: DealMachineNodes.INVESTMENT_MEMO,
            actions: assign((_, state) => ({ ...state })),
          },
        },
      },
      [DealMachineNodes.INVESTMENT_MEMO]: {
        type: 'final',
      },
    },
  });
