import { Divider, Flex, FlexProps, Icon, Stack, Text } from '@chakra-ui/react';
import { Chart, H1, UserList } from 'components/elements';
import fp from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { FaUsers } from 'react-icons/fa';
import { IUserResource } from 'services/resources/users/user/types.d';
import { IChart } from 'types.d';

import { EmptyState } from './components';
import { DateKeyEnum } from './helpers';

export interface IUserChartCardProps extends FlexProps {
  /**
   * Total of registries that the chart will display.
   */
  count: number;
  /**
   * Data that the chart will display.
   */
  data: IChart[];
  /**
   * Date key that will display on the listing, it could be "accreditation_date"
   * or "created".
   */
  dateKey: string;
  /**
   * If `true`, then the container will display a spinner component.
   */
  isLoading: boolean;
  /**
   * User's listing title.
   */
  listingTitle: string;
  /**
   * List of registries fetched by the given date.
   */
  registries?: IUserResource[];
  /**
   * Card subtitle.
   */
  subtitle: string;
  /**
   * Chart color.
   */
  stroke: string;
  /**
   * Card title.
   */
  title: string;
}

export const UseChartCard: React.FC<IUserChartCardProps> = (
  props,
): JSX.Element => {
  const {
    count,
    data,
    dateKey,
    id,
    isLoading,
    listingTitle,
    registries,
    subtitle,
    stroke,
    title,
    ...rest
  } = props;

  const { t } = useTranslation('home');

  return (
    <Flex {...rest}>
      <Stack align="center" isInline mx={6} my={2} spacing={4}>
        <Icon as={FaUsers} boxSize={6} />
        <H1 fontSize="sm">{title}</H1>
      </Stack>

      <Divider colorScheme="blackAlpha.100" mb={6} />

      <Text
        color="blackAlpha.700"
        fontSize="xs"
        fontWeight="medium"
        mb={2}
        mx={6}
      >
        {subtitle}
      </Text>

      <Text fontSize="3xl" fontWeight="bold" mx={6}>
        {count}
      </Text>

      {!isLoading && fp.isEmpty(data) ? (
        <EmptyState flex={1} description={t('charts.empty.message')} />
      ) : (
        <Chart
          data={data}
          dataKeyY="registries"
          dataKeyX="day"
          height={200}
          id={id}
          isLoading={isLoading}
          mb={4}
          stroke={stroke}
        />
      )}

      {!isLoading && !fp.isEmpty(data) ? (
        <>
          <Text
            color="blackAlpha.700"
            fontSize="xs"
            fontWeight="medium"
            mb={4}
            mx={6}
          >
            {listingTitle}
          </Text>

          <UserList
            dateKey={DateKeyEnum[dateKey]}
            mb={6}
            mx={6}
            registries={registries}
          />
        </>
      ) : null}
    </Flex>
  );
};

UseChartCard.defaultProps = {
  bg: 'white',
  border: '1px',
  borderColor: 'blackAlpha.300',
  direction: 'column',
  rounded: 'lg',
  w: '100%',
};
