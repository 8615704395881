import { Tr } from '@chakra-ui/react';
import { useGenericContext } from 'components/contexts/useGenericContext';
import { ReferenceForm } from 'components/forms';
import { Td, TdActions } from 'components/lib';
import { CustomTable, FormDrawer } from 'components/modules';
import { FormikProps } from 'formik';
import {
  getEmail,
  getFirstSurname,
  getId,
  getLastSurname,
  getName,
  getResults,
  isResultsEmpty,
} from 'helpers';
import { nanoid } from 'nanoid';
import { useCallback, useRef } from 'react';
import {
  IReferencePayload,
  IReferenceResource,
} from 'services/resources/users/reference/types.d';

import { getCreated, HEADERS } from '../helpers';

export const Page: React.FC = (): JSX.Element => {
  const {
    active,
    defaultPatch,
    defaultRestore,
    isDrawerOpen,
    onPrepareDelete,
    onPrepareDrawer,
    registry,
    state,
    toggleDrawer,
  } = useGenericContext<IReferenceResource>();

  const ref = useRef<FormikProps<IReferencePayload>>(null);

  const prepareOnSubmit = useCallback(() => {
    if (ref?.current) ref?.current?.submitForm();
  }, [ref]);

  return (
    <>
      <CustomTable
        isEmpty={isResultsEmpty(state)}
        headers={HEADERS}
        isLoading={state?.loading}
        mx={8}
      >
        {getResults(state)?.map((row: IReferenceResource) => (
          <Tr key={nanoid()} position="relative">
            <Td>{getId(row)}</Td>
            <Td>{getName(row)}</Td>
            <Td>{getFirstSurname(row)}</Td>
            <Td>{getLastSurname(row)}</Td>
            <Td>{getEmail(row)}</Td>
            <Td>{getCreated(row)}</Td>
            <TdActions
              isActive={active}
              onDelete={() => onPrepareDelete(row)}
              onRestore={() => defaultRestore(row, { is_active: true })}
              onUpdate={() => onPrepareDrawer(row)}
            />
          </Tr>
        ))}
      </CustomTable>

      <FormDrawer
        isOpen={isDrawerOpen}
        isDisabled={!!ref?.current?.isSubmitting || !!ref?.current?.isValid}
        isLoading={!!ref.current?.isSubmitting}
        onClose={toggleDrawer}
        onSubmit={prepareOnSubmit}
      >
        <ReferenceForm
          initialValues={{ ...registry }}
          onSubmit={defaultPatch}
          ref={ref}
        />
      </FormDrawer>
    </>
  );
};
