import { Flex, useMediaQuery, useTheme } from '@chakra-ui/react';
import { Container } from 'components/elements';
import { Header, Sidebar } from 'components/modules';
import { authActions } from 'containers/AuthManager/store';
import { LoginPage } from 'containers/LogInPage';
import fp from 'lodash/fp';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import { useAsync } from 'react-use';
import { UserResources } from 'services/resources/users/user';

import { HEADER_NAVIGATION, SIDEBAR_NAVIGATION } from '../helpers';
import { RouteTree } from './RouteTree';

export const Root: React.FC = (): JSX.Element => {
  const { breakpoints } = useTheme();

  const [isLgScreen] = useMediaQuery(`(min-width: ${breakpoints.lg})`);

  const dispatch = useDispatch();

  const { error, loading, value } = useAsync(async () => {
    const response = await UserResources.me();
    return response.data;
  });

  return value?.is_staff ? (
    <Flex bg="white" direction="column" minH="100vh">
      <Header
        as="header"
        avatar={value?.avatar as string}
        isFetching={!fp.isNil(error) || loading}
        isLgScreen={isLgScreen}
        onLogout={() => dispatch(authActions.logout())}
        routes={HEADER_NAVIGATION}
        username={value?.email}
      />

      <Container as="main" display="flex" isFluid p={0} top={16}>
        <Sidebar isLgScreen={isLgScreen} navigation={SIDEBAR_NAVIGATION} />
        <Flex
          flex={1}
          direction="column"
          minH="calc(100vh - 4rem)"
          ml={{ base: 8, lg: 0 }}
          overflowX="hidden"
        >
          <RouteTree />
        </Flex>
      </Container>
    </Flex>
  ) : (
    <Route exact path="/" component={LoginPage} />
  );
};
