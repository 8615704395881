/* eslint-disable @typescript-eslint/no-explicit-any */
import { colors } from 'styles/colors';

export enum SelectSize {
  sm = '32px',
  md = '40px',
  lg = '48px',
}

export enum SelectFontSize {
  sm = '0.875rem',
  md = '1rem',
  lg = '1.125rem',
}

export const customStyles = (size: keyof typeof SelectSize): any => ({
  control: (base: any) => ({
    ...base,
    background: colors.blue[50],
    fontSize: SelectFontSize[size],
    minHeight: SelectSize[size],
  }),
  menu: (base: any) => ({
    ...base,
    fontSize: SelectFontSize[size],
  }),
  valueContainer: (base: any) => ({
    ...base,
    position: size === 'sm' ? 'static' : 'relative',
  }),
});
