import { Button, Stack, StackDivider } from '@chakra-ui/react';
import { CheckboxField, FileField } from 'components/inputs';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { IKYBMoralUpdateFormInitialValues } from './types.d';

export interface IKYBMoralUpdateFormProps {
  /**
   * Form initial values, can be a complete or partial IUserPayload
   * object which will allow to support create and update requests.
   */
  initialValues: IKYBMoralUpdateFormInitialValues;
  /**
   * On submit action, for this form this will be controlled from the outside
   * of this component thanks to the forward ref property.
   */
  onSubmit: (
    v: IKYBMoralUpdateFormInitialValues,
    h?: FormikHelpers<IKYBMoralUpdateFormInitialValues>,
  ) => void;
}

export const KYBMoralUpdateForm = forwardRef<
  FormikProps<IKYBMoralUpdateFormInitialValues>,
  IKYBMoralUpdateFormProps
>((props, ref) => {
  const { initialValues, onSubmit } = props;
  const { t } = useTranslation(['investors', 'common', 'kyb-moral']);

  return (
    <Formik
      initialValues={{
        ...initialValues,
      }}
      innerRef={ref}
      onSubmit={onSubmit}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <Stack
            divider={<StackDivider borderColor="blackAlpha.100" />}
            spacing={4}
          >
            <FileField
              label={t('form.address-proof', { ns: 'kyb-moral' })}
              name="address_proof"
            />
            <FileField
              label={t('form.constitutive_certificate', { ns: 'kyb-moral' })}
              name="constitutive_certificate"
            />
            <FileField
              label={t('form.electronic_sign_number_proof', {
                ns: 'kyb-moral',
              })}
              name="electronic_sign_number_proof"
            />
            <FileField
              label={t('form.legal_representative_declaration', {
                ns: 'kyb-moral',
              })}
              name="legal_representative_declaration"
            />
            <FileField
              label={t('form.legal_representative_ID', { ns: 'kyb-moral' })}
              name="legal_representative_ID"
            />
            <FileField
              label={t('form.legal_representative_proof', { ns: 'kyb-moral' })}
              name="legal_representative_proof"
            />
            <FileField
              label={t('form.official_id_number_proof', { ns: 'kyb-moral' })}
              name="official_id_number_proof"
            />
            <FileField
              label={t('form.signed_statement', { ns: 'kyb-moral' })}
              name="signed_statement"
            />
            <FileField
              label={t('form.tax_id_proof', { ns: 'kyb-moral' })}
              name="tax_id_proof"
            />

            <CheckboxField
              mb={4}
              label={t('form.input.is-accredited.label')}
              name="is_accredited"
            />

            <CheckboxField
              label={t('inputs.is-active.label', { ns: 'common' })}
              mb={4}
              name="is_active"
            />
          </Stack>


          <Button
            colorScheme="blue"
            isDisabled={isSubmitting || !isValid}
            isFullWidth
            isLoading={isSubmitting}
            mt={12}
            size="sm"
            type="submit"
          >
            {t('button.save', { ns: 'common' })}
          </Button>
        </Form>
      )}
    </Formik>
  );
});

KYBMoralUpdateForm.displayName = 'KYBMoralUpdateForm';
