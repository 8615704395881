import { instance } from 'services';
import { IFindFnReturn, IQueryParams, IResponse } from 'services/types.d';

import { ISitiPayload, ISitiResource } from './types.d';

export const SitiResource = {
  findAll: (params?: IQueryParams): Promise<IFindFnReturn<ISitiResource>> =>
    instance.get('/siti/', { params }),

  create: (params: ISitiPayload): Promise<IResponse<ISitiResource>> =>
    instance.post(`/siti/`, params),

  update: (
    id: number,
    params: ISitiPayload,
  ): Promise<IResponse<ISitiResource>> =>
    instance.patch(`/siti/${id}/`, params),

  delete: (): Promise<IResponse<unknown>> => instance.delete(`/siti/`),
};
