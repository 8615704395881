import fp from 'lodash/fp';

export const getId = fp.get('id');
export const getKybStage = fp.get('kyb_stage');
export const getTradeName = fp.get('trade_name');
export const getActivity = fp.get('activity');
export const getNationality = fp.get('nationality');
export const getIncorporationDate = fp.get('incorporation_date');
export const homePhone = fp.get('home_phone');
export const getStreet = fp.get('street');
export const getExteriorNumber = fp.get('ext_num');
export const getInteriorNumber = fp.get('int_num');
export const getZipCode = fp.get('zip_code');
export const getColony = fp.get('colony');
export const getMunicipality = fp.get('municipality');
export const getCity = fp.get('city');
export const getResidenceCountry = fp.get('residence_country');
export const getIsInvestor = fp.get('is_investor');
export const getIsApplicant = fp.get('is_applicant');

export const getAvatar = fp.get(['user_detail', 'avatar']);
export const getName = fp.get(['user_detail', 'kyc_physical_person', 'name']);
export const getFirstSurname = fp.get([
  'user_detail',
  'kyc_physical_person',
  'first_surname',
]);
export const getLastSurname = fp.get([
  'user_detail',
  'kyc_physical_person',
  'last_surname',
]);

export const getTaxId = fp.get('tax_id');
export const getOfficialIdNumber = fp.get('official_id_number');
export const getOfficialIdNumberProof = fp.get('official_id_number_proof');
export const getLegalRepresentativeFea = fp.get('legal_representative_FEA');
export const getTaxIdCountryEmisor = fp.get('tax_id_country_emisor');

export const getConstitutiveCertificate = fp.get('constitutive_certificate');
export const getTaxIdProof = fp.get('tax_id_proof');
export const getOfficialIdProod = fp.get('official_id_proof');
export const getElectronicSignNumberProof = fp.get(
  'electronic_sign_number_proof',
);
export const getAddressProof = fp.get('address_proof');
export const getLegalRepresentativeProof = fp.get('legal_representative_proof');
export const getLegalRepresentativeId = fp.get('legal_representative_ID');
export const getResourceProvider = fp.get('resource_provider');
export const getLegalSign = fp.get('legal_sign');

export enum KYBStagesEnum {
  COMPANY_INFORMATION = 2,
  COMPANY_INFORMATION_SUCCESS = 3,
  BUSINESS_BANK_INFORMATION = 4,
  BUSINESS_BANK_INFORMATION_SUCESS = 5,
  TAX_COMPANY_INFORMATION = 6,
  TAX_COMPANY_INFORMATION_SUCCESS = 7,
  OFFICIAL_DOCUMENTS = 8,
  OFFICIAL_DOCUMENTS_SUCCESS = 9,
  LEGAL_SIGN = 10,
  LEGAL_SIGN_SUCCESS = 11,
  KYB_MORAL_COMPLETE = 12,
}

export interface IDocumentsProps {
  /**
   * Unique document identificator name.
   */
  identificator?: string;
  /**
   * Document label.
   */
  label?: string;
}

export const documents = [
  {
    identificator: 'constitutive_certificate',
    label:
      'page.detail.company-official-documents.constitutive-certificate.title',
  },
  {
    identificator: 'tax_id_proof',
    label: 'page.detail.company-official-documents.tax-id.title',
  },
  {
    identificator: 'official_id_number_proof',
    label: 'page.detail.company-official-documents.official-id-number.title',
  },
  {
    identificator: 'electronic_sign_number_proof',
    label:
      'page.detail.company-official-documents.electronic-sign-number-proof.title',
  },
  {
    identificator: 'address_proof',
    label: 'page.detail.company-official-documents.address-proof.title',
  },
  {
    identificator: 'legal_representative_proof',
    label:
      'page.detail.company-official-documents.legal-representative-proof.title',
  },
  {
    identificator: 'legal_representative_ID',
    label:
      'page.detail.company-official-documents.legal-representative-ID.title',
  },
  {
    identificator: 'resource_provider',
    label: 'page.detail.company-official-documents.resource-provider.title',
  },
];

export const documentsIdentificator = {
  constitutive_certificate: getConstitutiveCertificate,
  tax_id_proof: getTaxIdProof,
  official_id_number_proof: getOfficialIdNumberProof,
  electronic_sign_number_proof: getElectronicSignNumberProof,
  address_proof: getAddressProof,
  legal_representative_proof: getLegalRepresentativeProof,
  legal_representative_ID: getLegalRepresentativeId,
  resource_provider: getResourceProvider,
};
