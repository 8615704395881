import axios from 'axios';
import { instance } from 'services';
import { IResponse } from 'services/types.d';

import { ICountryResource } from './types.d';

export const MiscResources = {
  getCountries: (): Promise<IResponse<ICountryResource>> =>
    axios.get('https://restcountries.com/v2/all'),

  mailing: (params: unknown): Promise<IResponse<unknown>> =>
    instance.post('/mailing/', params),
};
