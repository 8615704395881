import { Flex, Icon, Tr } from '@chakra-ui/react';
import { useGenericContext } from 'components/contexts/useGenericContext';
import { H1, Modal } from 'components/elements';
import { CompanyReportForm } from 'components/forms/CompanyReportForm';
import { Td, TdActions, TdBin } from 'components/lib';
import { CustomTable, FormDrawer } from 'components/modules';
import {
  addErrorToast,
  addSuccessToast,
} from 'containers/ToastManager/store/slice';
import { FormikHelpers, FormikProps } from 'formik';
import {
  getCompanyId,
  getId,
  getIsActive,
  getQuarter,
  getReport,
  getResults,
  getYear,
  isResultsEmpty,
} from 'helpers';
import fp from 'lodash/fp';
import { nanoid } from 'nanoid';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { MdFileDownload } from 'react-icons/md';
import { RiFileEditFill } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { useToggle } from 'react-use';
import {
  ICompanyReportPayload,
  ICompanyReportResource,
} from 'services/resources/company-report/types.d';
import { CompanyReportResources } from 'services/resources/deal-flow/company-report';

import { getCreated, HEADERS } from '../helpers';

export const Page: React.FC = (): JSX.Element => {
  const {
    active,
    defaultRestore,
    doFetch,
    isDrawerOpen,
    onPrepareDelete,
    onPrepareDrawer,
    params,
    registry,
    setRegistry,
    state,
    toggleDrawer,
  } = useGenericContext<ICompanyReportResource>();

  const { t } = useTranslation('companies');

  const ref = useRef<FormikProps<ICompanyReportPayload>>(null);

  const dispatch = useDispatch();

  const [isOpen, toggle] = useToggle(false);

  const handleOnDownload = useCallback((row: ICompanyReportResource) => {
    window.open(getReport(row));
  }, []);

  const prepareFormValues = useCallback(
    () => ({
      ...registry,
      company: fp.get(['company', 'id'])(registry),
      quarter: fp.get(['quarter', 'id'])(registry),
      is_active: true,
    }),
    [registry],
  );

  const prepareOnSubmit = useCallback(() => {
    if (ref?.current) ref?.current?.submitForm();
  }, [ref]);

  const handleOnSubmit = useCallback(
    async (
      { report: s, ...v }: ICompanyReportPayload,
      helpers?: FormikHelpers<ICompanyReportPayload>,
    ) => {
      try {
        const p = new FormData();
        if (!fp.isString(s)) p.append('report', s as File, (s as File).name);

        fp.compose(
          fp.each((k: string) => p.append(k, v[k])),
          fp.keys,
          fp.omitBy(fp.isNil),
        )(v);

        if (fp.compose(fp.isNil, getId)(v)) {
          await CompanyReportResources.create(p as ICompanyReportPayload);
        } else {
          await CompanyReportResources.update(
            getId(v),
            p as ICompanyReportPayload,
          );
        }

        await doFetch(params);

        dispatch(addSuccessToast('toast.success.files'));
        toggleDrawer();
      } catch (err) {
        dispatch(addErrorToast('toast.errors.files.network'));
      } finally {
        helpers?.setSubmitting(false);
      }
    },
    [],
  );

  const prepareCreateReport = useCallback((company: ICompanyReportResource) => {
    setRegistry(company);
    toggle();
  }, []);

  const createReport = useCallback(
    async (
      { report: r, ...v }: ICompanyReportPayload,
      h?: FormikHelpers<ICompanyReportPayload>,
    ) => {
      try {
        const p = new FormData();

        if (!fp.isString(r)) p.append('report', r as File, (r as File).name);

        fp.compose(
          fp.each((k: string) => p.append(k, v[k])),
          fp.keys,
        )(v);

        await CompanyReportResources.create(p as ICompanyReportPayload);

        dispatch(addSuccessToast('toast.success.files'));
        toggle();
      } catch (e) {
        dispatch(addErrorToast('toast.errors.files.network'));
      } finally {
        h?.setSubmitting(false);
      }
    },
    [],
  );

  return (
    <>
      <CustomTable
        isEmpty={isResultsEmpty(state)}
        headers={HEADERS}
        isLoading={state?.loading}
        mx={8}
      >
        {getResults(state)?.map((row: ICompanyReportResource) => (
          <Tr key={nanoid()} position="relative">
            <Td>{getId(row)}</Td>
            <Td>{getCreated(row)}</Td>
            <TdBin isCheck={getIsActive(row)} />
            <Td>{getCreated(row)}</Td>
            <Td>{getYear(row)}</Td>
            <Td>{getCompanyId(row)}</Td>
            <Td>{getQuarter(row)}</Td>
            <TdActions
              customButtonBg="orange"
              customButtonIcon={<Icon as={MdFileDownload} color="white" />}
              isActive={active}
              onCustomCallback={() => handleOnDownload(row)}
              onDelete={() => onPrepareDelete(row)}
              onRestore={() => defaultRestore(row, { is_active: true })}
              onUpdate={() => onPrepareDrawer(row)}
              // onCreate={() => prepareCreateReport(row)}
            />
          </Tr>
        ))}
      </CustomTable>

      <FormDrawer
        isOpen={isDrawerOpen}
        isDisabled={!!ref?.current?.isSubmitting || !!ref?.current?.isValid}
        isLoading={!!ref.current?.isSubmitting}
        onClose={toggleDrawer}
        onSubmit={prepareOnSubmit}
        adminPage
      >
        <CompanyReportForm
          initialValues={prepareFormValues()}
          onSubmit={handleOnSubmit}
          adminPage
        />
      </FormDrawer>

      <Modal isOpen={isOpen} onClose={toggle}>
        <Flex
          direction="column"
          h="100%"
          justify="center"
          maxW="512px"
          mx="auto"
        >
          <H1 mb={12}>{t('reports.modal.title')}</H1>
          <CompanyReportForm
            initialValues={prepareFormValues()}
            onSubmit={createReport}
            adminPage
          />
        </Flex>
      </Modal>
    </>
  );
};
