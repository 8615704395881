import { Divider, Flex, FlexProps, Icon, Stack, Text } from '@chakra-ui/react';
import { Chart, H1 } from 'components/elements';
import fp from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { IconType } from 'react-icons/lib';
import { IChart } from 'types.d';

import { EmptyState } from './components';

export interface ISimpleChartCardProps extends FlexProps {
  /**
   * Total of registries that the chart will display.
   */
  count: number;
  /**
   * Data that the chart will display.
   */
  data: IChart[];
  /**
   * Icon that the component will display.
   */
  icon?: IconType;
  /**
   * If `true`, then the container will display a spinner component.
   */
  isLoading: boolean;
  /**
   * Card subtitle.
   */
  subtitle: string;
  /**
   * Chart color.
   */
  stroke: string;
  /**
   * Card title.
   */
  title: string;
}

export const SimpleChartCard: React.FC<ISimpleChartCardProps> = (
  props,
): JSX.Element => {
  const { count, data, icon, id, isLoading, subtitle, stroke, title, ...rest } =
    props;

  const { t } = useTranslation('home');

  return (
    <Flex {...rest}>
      <Stack align="center" isInline mx={6} my={2} spacing={4}>
        <Icon as={icon} boxSize={6} />
        <H1 fontSize="sm">{title}</H1>
      </Stack>

      <Divider colorScheme="blackAlpha.100" mb={6} />

      <Text
        color="blackAlpha.700"
        fontSize="xs"
        fontWeight="medium"
        mb={2}
        mx={6}
      >
        {subtitle}
      </Text>

      <Text fontSize="3xl" fontWeight="bold" mx={6}>
        {count}
      </Text>

      {!isLoading && fp.isEmpty(data) ? (
        <EmptyState flex={1} description={t('charts.empty.message')} />
      ) : (
        <Chart
          data={data}
          dataKeyY="registries"
          dataKeyX="day"
          height={110}
          id={id}
          isLoading={isLoading}
          stroke={stroke}
        />
      )}
    </Flex>
  );
};

SimpleChartCard.defaultProps = {
  bg: 'white',
  border: '1px',
  borderColor: 'blackAlpha.300',
  direction: 'column',
  h: '100%',
  rounded: 'lg',
  w: '100%',
};
