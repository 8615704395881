export const colors = {
  blue: {
    50: '#f3f8ff',
    100: '#ced9fd',
    200: '#b0c2fb',
    300: '#8ca6fa',
    400: '#5d82f8',
    500: '#073ff5',
    600: '#0638dc',
    700: '#0531c0',
    800: '#04289e',
    900: '#031c6f',
  },
};
