import { Button } from '@chakra-ui/react';
import { QuerySuggestions } from 'components/elements';
import { FileField, InputField, SelectField } from 'components/inputs';
import { Form, Formik, FormikHelpers } from 'formik';
import { genericTransform } from 'helpers/transform-helpers';
import { useTranslation } from 'react-i18next';
import { QuarterResources } from 'services/resources/catalogs/quarter';
import { CompanyResources } from 'services/resources/deal-flow/company';
import { ICompanyReportPayload } from 'services/resources/deal-flow/company-report/types.d';
import { ISelectOption } from 'types.d';

import { CompanyReportFormValidationSchema } from './helpers';

export interface ICompanyReportFormProps {
  /**
   * Form initial values.
   */
  initialValues?: ICompanyReportPayload;
  /**
   * Form initial values.
   */
  adminPage?: boolean;
  /**
   * On submit handler.
   */
  onSubmit: (
    v: ICompanyReportPayload,
    helpers?: FormikHelpers<ICompanyReportPayload>,
  ) => Promise<void>;
}

export const CompanyReportForm: React.FC<ICompanyReportFormProps> = (
  props,
): JSX.Element => {
  const {
    initialValues = { report: undefined },
    adminPage = false,
    onSubmit,
  } = props;

  console.log(initialValues);

  const { t } = useTranslation('common');

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={CompanyReportFormValidationSchema}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          {adminPage ? (
            <QuerySuggestions
              query={CompanyResources.findAll}
              transform={genericTransform}
            >
              {({ loading, value }) => (
                <SelectField
                  isLoading={loading}
                  label={t('forms.input.company.label')}
                  mb={4}
                  name="company"
                  options={value as ISelectOption[]}
                  placeholder={t('forms.input.query.placeholder')}
                  size="md"
                  w="100%"
                />
              )}
            </QuerySuggestions>
          ) : null}
          <FileField
            label={t('inputs.report.label')}
            mb={4}
            name="report"
            helper="inputs.file.helpers-pdf"
          />

          <QuerySuggestions
            query={QuarterResources.findAll}
            transform={genericTransform}
          >
            {({ loading, value }) => (
              <SelectField
                isLoading={loading}
                label={t('inputs.quarter.label')}
                mb={4}
                name="quarter"
                options={value as ISelectOption[]}
                placeholder={t('inputs.select.default-placeholder')}
                w={{ base: '100%', lg: '50%' }}
              />
            )}
          </QuerySuggestions>

          <InputField
            label={t('inputs.year.label')}
            name="year"
            placeholder={t('inputs.year.placeholder')}
            w={{ base: '100%', lg: '33%' }}
          />

          <Button
            colorScheme="blue"
            isDisabled={isSubmitting || !isValid}
            isFullWidth
            isLoading={isSubmitting}
            mt={12}
            size="sm"
            type="submit"
          >
            {t('button.save')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
