import fp from 'lodash/fp';
import { ICountryResource } from 'services/resources/misc/types.d';

export interface ICountry {
  /**
   * Country icon URL.
   */
  icon: string;
  /**
   * Country name.
   */
  label: string;
  /**
   * Country two characters code.
   */
  value: string;
}

export const flatCountries = (countries: ICountryResource): ICountry[] =>
  fp.map(({ alpha2Code, name }) => ({
    icon: `https://www.countryflags.io/${alpha2Code}/flat/24.png`,
    label: name,
    value: alpha2Code,
  }))(countries);

export const flatCallingCodes = (countries: ICountryResource): ICountry[] =>
  fp.map(({ alpha2Code, name, callingCodes }) => ({
    icon: `https://www.countryflags.io/${alpha2Code}/flat/24.png`,
    label: name,
    value: `+${fp.head(callingCodes) as string}`,
  }))(countries);
