import { FaTag } from 'react-icons/fa';
import { GiReceiveMoney } from 'react-icons/gi';
import { GoGraph } from 'react-icons/go';
import { GrCatalog } from 'react-icons/gr';
import { HiUserGroup } from 'react-icons/hi';
import { IoIosJournal } from 'react-icons/io';
import { MdBusiness, MdGroup } from 'react-icons/md';
import { INavigation } from 'types.d';

export const SIDEBAR_NAVIGATION: INavigation[] = [
  {
    icon: MdBusiness,
    label: 'sidebar.menu.portfolio',
    routes: [
      {
        label: 'sidebar.item.companies',
        path: '/companies',
      },
      {
        label: 'sidebar.item.deals',
        path: '/deals',
      },
      {
        label: 'sidebar.item.valuations',
        path: '/valuations',
      },
      {
        label: 'sidebar.item.deals-status',
        path: '/deal-status',
      },
      {
        label: 'sidebar.item.company-report',
        path: '/company-reports',
      },
    ],
  },
  {
    icon: MdGroup,
    label: 'sidebar.menu.users',
    routes: [
      {
        label: 'sidebar.item.investors',
        path: '/users',
      },
      {
        label: 'sidebar.item.compliance',
        path: '/kyc-physical-person',
      },
      {
        label: 'KYB (Moral)',
        path: '/kyb-moral-person',
      },
      {
        label: 'sidebar.item.beneficiaries',
        path: '/beneficiaries',
      },
      {
        label: 'sidebar.item.references',
        path: '/references',
      },
      {
        label: 'sidebar.item.kyc-history',
        path: '/kyc-history',
      },
    ],
  },
  {
    icon: GiReceiveMoney,
    label: 'sidebar.menu.financial',
    routes: [
      {
        label: 'sidebar.item.investments',
        path: '/investments',
      },
      {
        label: 'sidebar.item.bank-statement',
        path: '/bank-statement',
      },
    ],
  },
  {
    icon: GoGraph,
    label: 'sidebar.item.siti',
    path: '/siti',
  },
  {
    icon: HiUserGroup,
    label: 'sidebar.item.groups',
    routes: [
      {
        label: 'sidebar.item.group-users',
        path: '/group-users',
      },
      {
        label: 'sidebar.item.group-management',
        path: '/group-admin',
      },
    ],
  },
  {
    icon: MdBusiness,
    label: 'sidebar.menu.investment-preferences',
    routes: [
      {
        label: 'sidebar.item.industries',
        path: '/industries',
      },
      {
        label: 'sidebar.item.differentiations',
        path: '/differentiations',
      },
      {
        label: 'sidebar.item.company-rounds',
        path: '/company-rounds',
      },
      {
        label: 'sidebar.item.company-stages',
        path: '/company-stages',
      },
    ],
  },
  {
    icon: IoIosJournal,
    label: 'sidebar.menu.journal',
    path: '/journal',
  },
  {
    icon: FaTag,
    label: 'sidebar.menu.marketing',
    routes: [
      {
        label: 'sidebar.item.spotlight',
        path: '/spotlight',
      },
    ],
  },
  {
    icon: GrCatalog,
    label: 'sidebar.menu.catalogs',
    routes: [
      {
        label: 'sidebar.item.company-types',
        path: '/catalogs/company-type',
      },
      {
        label: 'sidebar.item.valuation-types',
        path: '/catalogs/valuation-type',
      },
      {
        label: 'sidebar.item.exchanges',
        path: '/catalogs/exchange',
      },
      {
        label: 'sidebar.item.investment-status',
        path: '/catalogs/investment-status',
      },
      {
        label: 'sidebar.item.security-types',
        path: '/catalogs/security-type',
      },
      {
        label: 'sidebar.item.resources-origin',
        path: '/catalogs/financial-resource-origin',
      },
      {
        label: 'sideber.item.journal-activity',
        path: '/catalogs/journal-activity',
      },
      {
        label: 'sidebar.item.genders',
        path: '/catalogs/gender',
      },
      {
        label: 'sidebar.item.investment-resources',
        path: '/catalogs/investment-resource',
      },
      {
        label: 'sidebar.item.compliance-stages',
        path: '/catalogs/kyc-stage',
      },
      {
        label: 'sidebar.item.occupations',
        path: '/catalogs/occupation',
      },
      {
        label: 'sidebar.item.occupation-years',
        path: '/catalogs/occupation-year',
      },
      {
        label: 'sidebar.item.vulnerable-activities',
        path: '/catalogs/vulnerable-activity',
      },
      {
        label: 'sidebar.item.spotlight-types',
        path: '/catalogs/spotlight-type',
      },
    ],
  },
];

export const HEADER_NAVIGATION: INavigation[] = [];
