import { formatAmount, formatDate, formatPercentage } from 'helpers';
import fp from 'lodash/fp';

export const getName = fp.get(['user', 'name']);

export const getClabe = fp.get(['debit', 'clabe']);

export const getCardNumber = fp.get(['credit', 'cardNumber']);

export const getDetails = fp.get('details');

export const getCreditLimit = fp.compose(
  formatAmount,
  fp.get(['credit', 'creditLimit']),
);

export const getStatementBalance = fp.compose(
  formatAmount,
  fp.get(['credit', 'statementBalance']),
);

export const getAvailableBalance = fp.compose(
  formatAmount,
  fp.get(['credit', 'availableBalance']),
);

export const getNonInterestPayment = fp.compose(
  formatAmount,
  fp.get(['credit', 'nonInterestPayment']),
);

export const getMinimumPayment = fp.compose(
  formatAmount,
  fp.get(['credit', 'minimumPayment']),
);

export const getDueDate = fp.compose(formatDate, fp.get(['credit', 'dueDate']));

export const getClosingDate = fp.compose(
  formatDate,
  fp.get(['credit', 'closingDate']),
);

export const getAnnualPercentageRate = fp.compose(
  formatPercentage,
  fp.curry((value?: number) => (value || 0) / 100),
  fp.get(['credit', 'annualPercentageRate']),
);

export const getTransactions = fp.get('transactions');

export const getTransactionDescription = fp.get('description');

export const getTransactionAmount = fp.compose(
  fp.curry(({ amount, currency }) => `${formatAmount(amount)} ${currency}`),
  fp.pick(['amount', 'currency']),
);

export const getTransactionDate = fp.compose(formatDate, fp.get('date'));

export const hasTransactions = fp.compose(
  fp.curry((value: boolean) => !value),
  fp.isEmpty,
);
