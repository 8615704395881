import { Box, Image, Stack, StackProps, Text } from '@chakra-ui/react';
import Logo from 'images/logo-icon.jpeg';
import { useTranslation } from 'react-i18next';

export const Heading: React.FC<StackProps> = (props): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <Stack {...props}>
      <Image
        alt="logo-icon"
        boxSize={10}
        fit="cover"
        src={Logo}
        rounded="full"
      />

      <Box>
        <Text fontSize="sm" fontWeight="bold">
          {t('sidebar.title')}
        </Text>
        <Text color="blackAlpha.500" fontSize="xs">
          {t('sidebar.subtitle')}
        </Text>
      </Box>
    </Stack>
  );
};

Heading.defaultProps = {
  align: 'center',
  h: '90px',
  isInline: true,
  mx: 8,
  w: '100%',
};
