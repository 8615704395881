import { Avatar, Stack } from '@chakra-ui/react';
import {
  CheckboxField,
  DateField,
  EmailField,
  FileField,
} from 'components/inputs';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IUserPayload } from 'services/resources/users/user/types.d';

export interface IUserFormProps {
  /**
   * Form initial values, can be a complete or partial IUserPayload
   * object which will allow to support create and update requests.
   */
  initialValues: IUserPayload;
  /**
   * On submit action, for this form this will be controlled from the outside
   * of this component thanks to the forward ref property.
   */
  onSubmit: (v: IUserPayload, h?: FormikHelpers<IUserPayload>) => void;
}

export const UserForm = forwardRef<FormikProps<IUserPayload>, IUserFormProps>(
  (props, ref) => {
    const { initialValues, onSubmit } = props;
    const { t } = useTranslation(['investors', 'common']);

    return (
      <Formik
        initialValues={{ ...initialValues }}
        innerRef={ref}
        onSubmit={onSubmit}
      >
        <Form>
          <Stack align="center" isInline mb={8} spacing={4}>
            <Avatar
              bg="blue.100"
              name={initialValues?.email}
              size="lg"
              src={initialValues?.avatar as string}
            />
            <EmailField name="email" />
          </Stack>

          <FileField
            helper="inputs.file.helpers-pdf"
            label={t('form.input.marco-contract.label')}
            mb={4}
            name="marco_contract"
          />

          <DateField
            label={t('form.input.accreditation-date.label')}
            mb={4}
            name="accreditation_date"
          />

          <Stack isInline mb={4}>
            <CheckboxField
              label={t('form.input.is-accredited.label')}
              name="is_accredited"
            />
            <CheckboxField
              label={t('form.input.account.label')}
              name="is_confirmated_account"
            />
          </Stack>

          <Stack isInline mb={4}>
            <CheckboxField
              label={t('form.input.terms-and-conditions.label')}
              name="terms"
            />
            <CheckboxField
              label={t('form.input.privacy-notice.label')}
              name="privacy"
            />
          </Stack>

          <CheckboxField
            label={t('form.input.experimental-investor.label')}
            mb={4}
            name="is_senior_investor"
          />

          <CheckboxField
            label={t('inputs.is-active.label', { ns: 'common' })}
            mb={4}
            name="is_active"
          />
        </Form>
      </Formik>
    );
  },
);

UserForm.displayName = 'UserForm';
