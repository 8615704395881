import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { CreatePage, ListingPage, UpdatePage } from './pages';

export const DealPages: React.FC = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route component={ListingPage} exact path={path} />
      <Route component={CreatePage} path={`${path}/create`} />
      <Route component={UpdatePage} path={`${path}/:slug`} />
    </Switch>
  );
};
