/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  configureStore,
  EnhancedStore,
  getDefaultMiddleware,
  StoreEnhancer,
} from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { createInjectorsEnhancer, forceReducerReload } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';
import { setupInterceptors } from 'services/utils/interceptors';

import { createReducer } from './reducers';

export const configureAppStore = (
  initialState = {},
  history: History,
): EnhancedStore => {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ] as StoreEnhancer<any, any>[];

  const store = configureStore({
    reducer: createReducer(),
    preloadedState: initialState,
    middleware: [
      ...getDefaultMiddleware({ serializableCheck: false }),
      ...middlewares,
    ],
    devTools: process.env.NODE_ENV !== 'production',
    enhancers,
  });

  if (module?.hot) {
    module?.hot?.accept('./reducers', () => {
      forceReducerReload(store);
    });
  }

  setupInterceptors(store);

  return store;
};
