import { Box, BoxProps, Table, Tbody, Thead, Tr } from '@chakra-ui/react';
import { Td, TdBin, TdTag, Th } from 'components/lib';
import { formatDate } from 'helpers';
import fp from 'lodash/fp';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { IReadOnlyResource } from 'services/resources/catalogs/types.d';

import { HEADERS, HEADERS_WITH_CODE } from './helpers';

export interface IReadOnlyProps extends BoxProps {
  /**
   * Resources that will populate the table, they only should be type of
   * `IReadOnlyResource`.
   */
  data?: IReadOnlyResource[];
  /**
   * If `true`, then the table will render an extra header named `CODE` which
   * will display a tag with the programatically code of the resource.
   */
  hasCode?: boolean;
}

export const ReadOnly: React.FC<IReadOnlyProps> = (
  props,
): JSX.Element | null => {
  const { data = [], hasCode = false, ...rest } = props;

  const { t } = useTranslation('tables');

  if (fp.isEmpty(data)) return null;

  return (
    <Box {...rest}>
      <Table minW="1024px" w="100%">
        <Thead>
          <Tr>
            {(hasCode ? HEADERS_WITH_CODE : HEADERS)?.map((header: string) => (
              <Th key={nanoid()}>{t(header)}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data?.map((item: IReadOnlyResource) => (
            <Tr key={nanoid()} _hover={{ bg: 'blackAlpha.50' }}>
              <Td fontWeight="bold">{item?.id}</Td>
              <Td>{item?.name}</Td>
              {hasCode ? <TdTag>{item?.code}</TdTag> : null}
              <TdBin isCheck={item?.is_active} />
              <Td>{formatDate(item?.created)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

ReadOnly.defaultProps = {
  maxH: '512px',
  overflowX: 'scroll',
};
