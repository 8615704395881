import {
  Box,
  BoxProps,
  Flex,
  Image,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import NotFoundCover from 'images/not-found.jpeg';
import { useTranslation } from 'react-i18next';
import { IKYCPhysicalResource } from 'services/resources/users/kyc-physical/types.d';

import {
  getKycStage,
  getOfficialIdBack,
  getOfficialIdFront,
  getOfficialIdNumber,
  KYCStagesEnum,
} from '../helpers';
import { LinkButton } from './LinkButton';

export interface IOfficialInformationProps extends BoxProps {
  /**
   * KYC physical user resource data.
   */
  kyc?: IKYCPhysicalResource;
}

export const OfficialInformation: React.FC<IOfficialInformationProps> = (
  props,
): JSX.Element => {
  const { kyc, ...rest } = props;
  const { t } = useTranslation('kyc-physical');

  if (!(getKycStage(kyc) > KYCStagesEnum.OFFICIAL_IDENTIFICATION)) {
    return (
      <Box {...rest}>
        <Box mb={2}>
          <Box mb={2}>
            <Text fontSize="sm" fontWeight="bold">
              {t('page.detail.official-information.identification.number')}
              <Text as="span" color="blue">
                {getOfficialIdNumber(kyc)}
              </Text>
            </Text>
          </Box>
          <Stack isInline overflow="scroll" spacing={{ base: 4, md: 8 }}>
            <Image
              alt="life-proof"
              fallback={<Skeleton h={64} rounded="md" w={64} />}
              fit="cover"
              h={64}
              src={NotFoundCover}
              rounded="md"
              w={64}
            />
            <Image
              alt="life-proof"
              fallback={<Skeleton h={64} rounded="md" w={64} />}
              fit="cover"
              h={64}
              src={NotFoundCover}
              rounded="md"
              w={64}
            />
          </Stack>
        </Box>
      </Box>
    );
  }

  return (
    <Box {...rest}>
      <Box mb={2}>
        <Text fontSize="sm" fontWeight="bold">
          {t('page.detail.official-information.identification.number')}
          <Text as="span" color="blue">
            {getOfficialIdNumber(kyc)}
          </Text>
        </Text>
      </Box>
      <Stack isInline mb={4} overflow="scroll" spacing={{ base: 4, md: 8 }}>
        <Image
          alt="life-proof"
          fallback={<Skeleton h={64} rounded="md" w={64} />}
          fit="cover"
          h={64}
          src={getOfficialIdFront(kyc) || NotFoundCover}
          rounded="md"
          w={64}
        />

        <Image
          alt="life-proof"
          fallback={<Skeleton h={64} rounded="md" w={64} />}
          fit="cover"
          h={64}
          src={getOfficialIdBack(kyc) || NotFoundCover}
          rounded="md"
          w={64}
        />
      </Stack>

      <Flex direction="row">
        <Flex w={64}>
          <LinkButton
            mx="auto"
            path={getOfficialIdFront(kyc)}
            title={t('page.detail.official-information.download')}
          />
        </Flex>
        <Flex mx={{ base: 8, md: 8 }} w={64}>
          <LinkButton
            mx="auto"
            path={getOfficialIdBack(kyc)}
            title={t('page.detail.official-information.download')}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
