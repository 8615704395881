import { isAlphaSpace } from 'utils';
import * as Yup from 'yup';

export const ReferenceFormValidationSchema = Yup.object().shape({
  email: Yup.string().email('errors.email').required('errors.required'),
  first_surname: Yup.string()
    .test('alphaspace', 'errors.name', isAlphaSpace)
    .required('errors.required'),
  is_active: Yup.bool().oneOf([false, true]).required('errors.required'),
  last_surname: Yup.string().test('alphaspace', 'errors.name', isAlphaSpace),
  name: Yup.string()
    .test('alphaspace', 'errors.name', isAlphaSpace)
    .required('errors.required'),
});
