/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: remove all the `any` types in this file.
import { useToast } from '@chakra-ui/react';
import fp from 'lodash/fp';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'redux-injectors';
import { createStructuredSelector } from 'reselect';

import { makeSelectToastManager } from './store/selectors';
import { reducer, removeToast } from './store/slice';

const stateSelector = createStructuredSelector({
  toasts: makeSelectToastManager(),
});

export function ToastManager(): null {
  useInjectReducer({ key: 'toasts', reducer });

  const { toasts } = useSelector(stateSelector);

  const { t } = useTranslation('toasts');

  const dispatch = useDispatch();

  const toast = useToast();

  const cleanToastPool = useCallback(() => {
    dispatch(removeToast());
  }, [dispatch]);

  useEffect(() => {
    if (fp.isEmpty(toasts)) return;
    fp.each(({ description, status }) => {
      toast({
        description: t(description),
        duration: 5000,
        isClosable: true,
        position: 'top-right',
        status,
      });

      cleanToastPool();
    })(toasts as any[]);
  }, [toasts]);

  return null;
}
