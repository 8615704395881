import { FormControl, FormControlProps } from '@chakra-ui/react';
import { FormErrorMessage, FormHelperText, FormLabel } from 'components/lib';
import { useField } from 'formik';
import fp from 'lodash/fp';
import Select, { OptionsType } from 'react-select';
import { colors } from 'styles/colors';
import { ISelectOption } from 'types.d';

import { customStyles, SelectSize } from './helpers';

export interface ISelectFieldProps extends FormControlProps {
  /**
   * Input background color.
   */
  bg?: string;
  /**
   * Input helper text.
   */
  helper?: string;
  /**
   * If `true`, the input will display a loading helper.
   */
  isLoading?: boolean;
  /**
   * If `true`, the select field will be multi-select.
   */
  isMulti?: boolean;
  /**
   * Input label.
   */
  label?: string;
  /**
   * Input name.
   */
  name: string;
  /**
   * Select options.
   */
  options: OptionsType<ISelectOption>;
  /**
   * Input size.
   */
  size?: keyof typeof SelectSize;
}

export const SelectField: React.FC<ISelectFieldProps> = (
  props,
): JSX.Element => {
  const {
    bg = colors.blue[50],
    helper,
    label,
    name,
    isLoading = false,
    isMulti = false,
    options,
    placeholder,
    size = 'sm',
    ...rest
  } = props;

  const [field, meta, helpers] = useField(name);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onChange: DONT_USE, value } = field;

  const handleOnChange = (
    option: ISelectOption | OptionsType<ISelectOption> | null,
  ) => {
    if (!meta.touched) helpers.setTouched(true);
    if (fp.isArray(option)) {
      const aux = option.map((item) => item.value);
      helpers.setValue(aux);
    } else {
      fp.compose(helpers.setValue, fp.get('value'))(option);
    }
  };

  const getCurrentValue = () => {
    if (!fp.isEmpty(options) && !fp.isNil(value)) {
      return isMulti
        ? options.filter((opt) => value.indexOf(opt.value) >= 0)
        : options.find((opt) => opt.value === fp.toString(value));
    }
    return isMulti ? [] : undefined;
  };

  const isInvalid = !!meta.error && !!meta.touched;

  return (
    <FormControl isInvalid={isInvalid} name={name} {...rest}>
      <FormLabel fontSize={size} htmlFor={name}>
        {label}
      </FormLabel>
      <Select
        isLoading={isLoading}
        isMulti={isMulti}
        onChange={handleOnChange}
        options={options}
        placeholder={placeholder}
        styles={customStyles(size, bg)}
        value={getCurrentValue()}
      />
      <FormHelperText>{helper}</FormHelperText>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
