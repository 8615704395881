import { logout, refresh } from 'containers/AuthManager/store';
import _ from 'lodash';
import { instance } from 'services';
import { delay, SessionCookies } from 'utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setupInterceptors = ({ dispatch }: { dispatch: any }): void => {
  const isTokenExpiredError = ({ status }) => status === 401;

  instance.interceptors.response.use(_.identity, ({ response, config }) => {
    const session = SessionCookies.load();
    if (isTokenExpiredError(response) && !_.isNil(session)) {
      try {
        dispatch(refresh());
        const path = ['headers', 'Authorization'];
        _.set(config, path, `Bearer ${_.get(session, 'accessToken')}`);
        return Promise.resolve(config)
          .then(delay(2000))
          .then((originalRequest: typeof config) =>
            instance.request(originalRequest),
          );
      } catch (err) {
        dispatch(logout());
      }
    }

    return Promise.reject(response);
  });
};
