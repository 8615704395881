import { formatDate, getEmail } from 'helpers';
import fp from 'lodash/fp';

export const getDescription = fp.get('description');

export const getAvatar = fp.get('avatar');

export const getCompanyLogo = fp.get('logo');

export const getName = fp.get('name');

export const getUserEmail = fp.compose(getEmail, fp.get('user'));

export const getIsPrivate = fp.get('is_private');

export const getRequestPrivate = fp.get('request_private');

export const getPublicCode = fp.get('public_code');

export const getCreated = fp.compose(formatDate, fp.get('created'));
