import {
  Checkbox,
  CheckboxGroup,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  StackProps,
  Text,
} from '@chakra-ui/react';
import { useGenericContext } from 'components/contexts/useGenericContext';
import { useTranslation } from 'react-i18next';

export interface IFiltersProps extends StackProps {
  /**
   * If `true`, it will render the `is_active` filter.
   */
  allowIsActive: boolean;
  /**
   * If `true`, it will render the `ascending` and `descending` ordering based
   * in the MongoDB results.
   */
  allowMongoIDOrdering?: boolean;
  /**
   * If `true`, every filter will be removed.
   */
  allowRemoveFilters: boolean;
  /**
   * If `true`, it will render the `is_accredited`, `is_staff` and
   * `is_superuser` filters.
   */
  allowUserFilters: boolean;
  /**
   * If `true`, it will render the `is_owner`, `is_admin` and filters.
   */
  allowUserGroupFilters: boolean;
}

export const Filters: React.FC<IFiltersProps> = (props): JSX.Element | null => {
  const {
    allowIsActive,
    allowMongoIDOrdering,
    allowRemoveFilters,
    allowUserFilters,
    allowUserGroupFilters,
    ...rest
  } = props;

  const {
    active,
    ordering,
    orderingMongoID,
    onSetActiveFilter,
    onSetOrdering,
    onSetUserFilters,
    onSetUserGroupFilters,
  } = useGenericContext();

  const { t } = useTranslation('common');

  if (allowRemoveFilters) {
    return null;
  }

  return (
    <Stack isInline mb={6} mx={8} spacing={8} {...rest}>
      {allowUserFilters ? (
        <Stack>
          <Text fontSize="sm" fontWeight="bold" mb={0}>
            {t('filter.user.title')}
          </Text>
          <CheckboxGroup onChange={onSetUserFilters} size="sm">
            <Flex direction={{ base: 'column', md: 'row' }} justify="center">
              <Checkbox mr={4} rounded="full" value="is_superuser">
                {t('filter.user.is-superuser')}
              </Checkbox>
              <Checkbox mr={4} value="is_staff">
                {t('filter.user.is-staff')}
              </Checkbox>
              <Checkbox value="is_accredited">
                {t('filter.user.is-accredited')}
              </Checkbox>
            </Flex>
          </CheckboxGroup>
        </Stack>
      ) : null}

      {allowUserGroupFilters ? (
        <Stack>
          <Text fontSize="sm" fontWeight="bold" mb={0}>
            {t('filter.userGroup.title')}
          </Text>
          <CheckboxGroup onChange={onSetUserGroupFilters} size="sm">
            <Flex direction={{ base: 'column', md: 'row' }} justify="center">
              <Checkbox mr={4} rounded="full" value="is_owner">
                {t('filter.user.is-owner')}
              </Checkbox>
              <Checkbox mr={4} value="is_admin">
                {t('filter.user.is-admin')}
              </Checkbox>
            </Flex>
          </CheckboxGroup>
        </Stack>
      ) : null}

      {allowIsActive ? (
        <RadioGroup onChange={onSetActiveFilter} size="sm" value={active}>
          <Text fontSize="sm" fontWeight="bold" mb={2}>
            {t('filter.active.title')}
          </Text>
          <Radio mr={4} value={1}>
            {t('filter.active.value.on')}
          </Radio>
          <Radio value={0}>{t('filter.active.value.off')}</Radio>
        </RadioGroup>
      ) : null}

      {allowMongoIDOrdering ? (
        <RadioGroup onChange={onSetOrdering} size="sm" value={orderingMongoID}>
          <Text fontSize="sm" fontWeight="bold" mb={2}>
            {t('filter.ordering.title')}
          </Text>
          <Radio mr={4} value="_id">
            {t('filter.ordering.value.ascendant')}
          </Radio>
          <Radio value="-_id">{t('filter.ordering.value.descendant')}</Radio>
        </RadioGroup>
      ) : (
        <RadioGroup onChange={onSetOrdering} size="sm" value={ordering}>
          <Text fontSize="sm" fontWeight="bold" mb={2}>
            {t('filter.ordering.title')}
          </Text>
          <Radio mr={4} value="id">
            {t('filter.ordering.value.ascendant')}
          </Radio>
          <Radio value="-id">{t('filter.ordering.value.descendant')}</Radio>
        </RadioGroup>
      )}
    </Stack>
  );
};
