import { instance } from 'services';
import {
  IFindFnReturn,
  IGenericPayload,
  IGenericResource,
  IQueryParams,
  IResponse,
} from 'services/types.d';

export const CompanyRoundResources = {
  findAll: (p?: IQueryParams): Promise<IFindFnReturn<IGenericResource>> =>
    instance.get('/company-round/', { params: p }),

  create: (params: IGenericPayload): Promise<IResponse<IGenericResource>> =>
    instance.post(`/company-round/`, params),

  update: (
    id: number,
    params: IGenericPayload,
  ): Promise<IResponse<IGenericResource>> =>
    instance.patch(`/company-round/${id}/`, params),

  delete: (id: number): Promise<IResponse<unknown>> =>
    instance.delete(`/company-round/${id}/`),
};
