/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoginPayload } from 'services/resources/auth/types.d';
import { FetchState } from 'types.d';

export interface IAuthSliceState {
  /**
   * Auth state.
   * @FetchState
   */
  auth: FetchState<any>;
  /**
   * Session token.
   */
  session: string | null;
}

export const initialState = {
  auth: { data: null, error: false, loading: true },
  session: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    auth(state, _action: PayloadAction<ILoginPayload>) {
      state.auth.loading = true;
    },
    authSuccess(state) {
      state.auth.loading = false;
    },
    authFailure(state) {
      state.auth.error = true;
      state.auth.loading = false;
    },

    logout() {},

    refresh(state) {
      state.auth.loading = true;
    },
    refreshSuccess(state) {
      state.auth.loading = false;
    },
    refreshFailure(state) {
      state.auth.error = true;
      state.auth.loading = false;
    },

    setSession(state, action) {
      state.session = action.payload;
    },
  },
});

export const {
  auth,
  authSuccess,
  authFailure,
  logout,
  refresh,
  refreshFailure,
  refreshSuccess,
  setSession,
} = authSlice.actions;

export const { actions: authActions } = authSlice;

export const { reducer: authReducer } = authSlice;
