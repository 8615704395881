import {
  Box,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { Divider, PageHeadline } from 'components/elements';
import {
  addErrorToast,
  addSuccessToast,
} from 'containers/ToastManager/store/slice';
import { FormikProps } from 'formik';
import fp from 'lodash/fp';
import { nanoid } from 'nanoid';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAsyncFn, useMount } from 'react-use';
import { CompanyResources } from 'services/resources/deal-flow/company';
import { DealResources } from 'services/resources/deal-flow/deal';
import { IDealPayload } from 'services/resources/deal-flow/deal/types.d';

import { NODES } from './helpers';

export const UpdatePage: React.FC = (): JSX.Element => {
  const { slug } = useParams<{ slug?: string }>();

  const { t } = useTranslation(['common', 'deals']);

  const [{ value }, onFetch] = useAsyncFn(async () => {
    const { data: d } = await DealResources.findByID(slug as string);
    const { data: c } = await CompanyResources.findByID(d?.company as number);
    return { company: c, deal: d };
  }, [slug]);

  const dispatch = useDispatch();

  const ref = useRef<FormikProps<IDealPayload>>(null);

  const handleOnSubmit = useCallback(
    async (v: IDealPayload) => {
      try {
        const { cover: c, ...payload } = v;

        const p = new FormData();

        if (!fp.isNil(c) && !fp.isString(c))
          p.append('cover', c as File, (c as File).name);

        fp.compose(
          fp.each((k: string) => p.append(k, payload[k])),
          fp.keys,
          fp.omitBy(fp.isNil),
        )(payload);

        await DealResources.update(slug as string, p as IDealPayload);

        onFetch();

        dispatch(addSuccessToast('toast.success.network'));
      } catch (e) {
        dispatch(addErrorToast('toast.errors.network'));
      } finally {
        ref?.current?.setSubmitting(false);
      }
    },
    [ref?.current],
  );

  const prepareOnSubmit = useCallback(() => {
    if (ref?.current) {
      ref?.current?.setSubmitting(true);
      ref?.current?.submitForm();
    }
  }, [ref?.current]);

  useMount(() => onFetch());

  return (
    <>
      <PageHeadline
        quote={t('deals:page.update.quote', { value: value?.company?.name })}
        title={t('deals:page.update.title', { value: value?.company?.name })}
      />

      <Divider mb={6} />

      <Tabs isLazy mx={8} size="md" variant="enclosed">
        <TabList>
          {NODES.map(({ key }) => (
            <Tab key={nanoid()}>{t(`deals:${key}`)}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {NODES.map(({ fn, props: customProps, UI }) => (
            <TabPanel key={nanoid()} {...customProps}>
              <UI
                initialValues={fn(value)}
                onSubmit={handleOnSubmit}
                ref={ref}
              />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>

      <Box mx={12} my={6}>
        <Button
          colorScheme="blue"
          isDisabled={ref?.current?.isSubmitting}
          isLoading={ref?.current?.isSubmitting}
          onClick={prepareOnSubmit}
          px={8}
          size="sm"
          type="button"
        >
          {t('common:button.save')}
        </Button>
      </Box>
    </>
  );
};
