import {
  FormControl,
  FormControlProps,
  Input,
  InputGroup,
} from '@chakra-ui/react';
import { FormErrorMessage, FormHelperText, FormLabel } from 'components/lib';
import { useField } from 'formik';
import { CSSProperties } from 'react';
import NumberFormat from 'react-number-format';
import { theme } from 'styles';

import { InputSize } from './helpers';

export interface IDateFieldProps extends FormControlProps {
  /**
   * Input helper text.
   */
  helper?: string;
  /**
   * Custom input props.
   * @type CSSProperties
   */
  inputProps?: CSSProperties;
  /**
   * Input label.
   */
  label?: string;
  /**
   * Input name.
   */
  name: string;
}

export const DateField: React.FC<IDateFieldProps> = (props): JSX.Element => {
  const {
    helper,
    inputProps = { background: theme.colors.blue[50] },
    label,
    name,
    size = 'sm',
    ...rest
  } = props;

  const [field, meta, helpers] = useField(name);

  const isInvalid = !!meta.error && !!meta.touched;

  return (
    <FormControl isInvalid={isInvalid} name={name} {...rest}>
      <FormLabel fontSize={size} htmlFor={name}>
        {label}
      </FormLabel>
      <InputGroup>
        <NumberFormat
          allowNegative={false}
          customInput={Input}
          format="####-##-##"
          onValueChange={({ formattedValue }) => {
            if (!meta.touched) helpers.setTouched(true);
            helpers.setValue(formattedValue);
          }}
          placeholder="YYYY-MM-DD"
          style={{ ...inputProps, height: InputSize[size] }}
          value={field.value}
        />
      </InputGroup>
      <FormHelperText>{helper}</FormHelperText>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
