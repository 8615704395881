import {
  Box,
  BoxProps,
  Icon,
  Table,
  Tbody,
  Text,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Td, TdActions, Th } from 'components/lib';
import { TransactionsModal } from 'components/modules';
import NotFoundCover from 'images/blank-information.png';
import fp from 'lodash/fp';
import { nanoid } from 'nanoid';
import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FaEye } from 'react-icons/fa';
import { useAsync, useToggle } from 'react-use';
import { FinerioResources } from 'services/resources/finerio';
import { IFinerioAccountResource } from 'services/resources/finerio/types.d';
import { IKYCPhysicalResource } from 'services/resources/users/kyc-physical/types.d';

import {
  getBalance,
  getCurrency,
  getFinerioBank,
  getFinerioCustomerId,
  getId,
  getName,
  getType,
} from '../helpers';
import { EmptyBanner } from './EmptyBanner';

export interface IFinerioInformationProps extends BoxProps {
  /**
   * KYC physical user resource data.
   */
  kyc?: IKYCPhysicalResource;
}

export const FinerioInformation: React.FC<IFinerioInformationProps> = (
  props,
): JSX.Element => {
  const { kyc, ...rest } = props;

  const [registry, setRegistry] = useState<IFinerioAccountResource | undefined>(
    undefined,
  );

  const [isOpen, toggle] = useToggle(false);

  const { t } = useTranslation('kyc-physical');

  const { value } = useAsync(async () => {
    const { data: c } = await FinerioResources.findCredentials({
      customer_id: getFinerioCustomerId(kyc),
    });
    const { data: a } = await FinerioResources.findAccountsByCredential({
      credential_id: fp.get('data.0.id')(c),
    });

    return { accounts: a?.data, credentials: c?.data };
  }, []);

  const handleOpenModal = useCallback((i: IFinerioAccountResource) => {
    setRegistry(i);
    toggle();
  }, []);

  const handleOnClose = useCallback(() => {
    setRegistry(undefined);
    toggle();
  }, []);

  if (fp.isEmpty(value?.credentials) || fp.isEmpty(value?.accounts)) {
    return (
      <EmptyBanner
        cover={NotFoundCover}
        mb={6}
        title={t('page.detail.finerio.empty.title')}
      />
    );
  }

  return (
    <Box {...rest}>
      <Trans
        i18nKey="page.detail.header.finerio-customer"
        ns="kyc-physical"
        values={{ value: getFinerioCustomerId(kyc) }}
      >
        <Text as="span" fontSize="sm" fontWeight="bold" />
        <Text as="span" color="blue" fontSize="sm" fontWeight="bold" />
      </Trans>

      <Table colorScheme="blackAlpha" mt={4} variant="striped">
        <Thead>
          <Tr>
            <Th>{t('page.detail.table.title.id')}</Th>
            <Th>{t('page.detail.table.title.name')}</Th>
            <Th>{t('page.detail.table.title.balance')}</Th>
            <Th>{t('page.detail.table.title.type')}</Th>
            <Th>{t('page.detail.table.title.bank')}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {value?.accounts?.map((item: IFinerioAccountResource) => (
            <Tr key={nanoid()}>
              <Td>{getId(item)}</Td>
              <Td>{getName(item)}</Td>
              <Td>{`${getBalance(item)} ${getCurrency(item)}`}</Td>
              <Td>{getType(item)}</Td>
              <Td>{getFinerioBank(item?.bankId)}</Td>
              <TdActions
                customButtonBg="orange"
                customButtonIcon={<Icon as={FaEye} color="white" />}
                isActive
                onCustomCallback={() => handleOpenModal(item)}
                onlyCustomAction
              />
            </Tr>
          ))}
        </Tbody>
      </Table>

      {!fp.isNil(registry) ? (
        <TransactionsModal
          isOpen={isOpen}
          onClose={handleOnClose}
          registry={registry}
        />
      ) : null}
    </Box>
  );
};

FinerioInformation.defaultProps = {
  overflow: 'scroll',
};
