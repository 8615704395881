import { Icon, Tr } from '@chakra-ui/react';
import { useGenericContext } from 'components/contexts/useGenericContext';
import { GroupForm } from 'components/forms';
import { Td, TdActions, TdBin, TdLogo } from 'components/lib';
import { CustomTable, FormDrawer } from 'components/modules';
import { FormikProps } from 'formik';
import {
  getCompanyLogo,
  getId,
  getName,
  getResults,
  isResultsEmpty,
} from 'helpers';
import fp from 'lodash/fp';
import { nanoid } from 'nanoid';
import React, { useCallback, useRef } from 'react';
import { RiFileEditFill } from 'react-icons/ri';
import {
  IGroupPayload,
  IGroupResource,
} from 'services/resources/group/admin-group/types.d';

import {
  getCreated,
  getDescription,
  getIsPrivate,
  getRequestPrivate,
  getPublicCode,
  HEADERS,
} from '../helpers';

export const Page: React.FC = (): JSX.Element => {
  const {
    state,
    active,
    onPrepareDelete,
    defaultRestore,
    onPrepareDrawer,
    registry,
    isDrawerOpen,
    defaultPatch,
    toggleDrawer,
  } = useGenericContext<IGroupResource>();

  const ref = useRef<FormikProps<IGroupPayload>>(null);

  const prepareOnSubmit = useCallback(() => {
    if (ref?.current) ref?.current?.submitForm();
  }, [ref]);

  const prepareFormValues = useCallback(
    () => ({
      ...registry,
      name: fp.get('name')(registry),
      description: fp.get('description')(registry),
      is_private: fp.get('is_private')(registry),
      request_private: fp.get('request_private')(registry),
    }),
    [registry],
  );

  return (
    <>
      <CustomTable
        isEmpty={isResultsEmpty(state)}
        headers={HEADERS}
        isLoading={state?.loading}
        mx={8}
      >
        {getResults(state)?.map((row: IGroupResource) => (
          <Tr key={nanoid()} position="relative">
            <Td>{getId(row)}</Td>
            <TdLogo src={getCompanyLogo(row)}>{getName(row)}</TdLogo>
            <Td>{getDescription(row)}</Td>
            <TdBin isCheck={getIsPrivate(row)} />
            <TdBin isCheck={getRequestPrivate(row)} />
            <Td>{getCreated(row)}</Td>
            <Td>{getPublicCode(row)}</Td>
            <TdActions
              customButtonBg="orange"
              isActive={active}
              customButtonIcon={<Icon as={RiFileEditFill} color="white" />}
              onDelete={() => onPrepareDelete(row)}
              onRestore={() => defaultRestore(row, { is_active: true })}
              onUpdate={() => onPrepareDrawer(row)}
            />
          </Tr>
        ))}
      </CustomTable>

      <FormDrawer
        isOpen={isDrawerOpen}
        isDisabled={!!ref?.current?.isSubmitting || !!ref?.current?.isValid}
        isLoading={!!ref.current?.isSubmitting}
        onClose={toggleDrawer}
        onSubmit={prepareOnSubmit}
      >
        <GroupForm
          initialValues={prepareFormValues()}
          onSubmit={defaultPatch}
          ref={ref}
        />
      </FormDrawer>
    </>
  );
};
