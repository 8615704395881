export const HEADERS = [
  'HEADER.ID',
  'HEADER.TRADE_NAME',
  'HEADER.APPLICATION_TYPE',
  'HEADER.LEGAL_REPRESENTATIVE',
  'HEADER.LEGAL_REPRESENTATIVE_EMAIL',
  'HEADER.KYB_COMPLETE',
  'HEADER.IS_ACCREDITED',
  'HEADER.CREATED',
];
