import { CRUDProvider } from 'components/providers';
import { useTranslation } from 'react-i18next';
import { CompanyResources } from 'services/resources/deal-flow/company';

import { Page } from './components/Page';

export const CompanyPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation('companies');

  return (
    <CRUDProvider
      allowPost
      quote={t('page.quote')}
      resource={CompanyResources}
      searchBy="name"
      title={t('page.title')}
    >
      <Page />
    </CRUDProvider>
  );
};
