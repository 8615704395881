import { Icon, Link, Stack, StackProps, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link as ReachLink } from 'react-router-dom';
import { INavigation } from 'types.d';

export type INavigationItemProps = StackProps & INavigation;

export const NavigationItem: React.FC<INavigationItemProps> = (
  props,
): JSX.Element => {
  const { icon, label, path, ...rest } = props;

  const { t } = useTranslation('common');

  return (
    <Link as={ReachLink} to={path as string}>
      <Stack {...rest}>
        <Icon as={icon} boxSize={4} />
        <Text fontSize="sm">{t(label)}</Text>
      </Stack>
    </Link>
  );
};

NavigationItem.defaultProps = {
  align: 'center',
  cursor: 'pointer',
  h: 12,
  isInline: true,
  px: 8,
  spacing: 4,
  sx: {
    ':hover': {
      bg: 'blackAlpha.100',
      transition: 'background 0.5s ease',
    },
  },
};
