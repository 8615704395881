export enum Nodes {
  GENERAL,
  FINANCIAL,
  SECURITY_TYPE,
  INVESTMENT_MEMO,
}

export const STAGE_TITLE = {
  GENERAL: 'deal.general.title',
  FINANCIAL: 'deal.financial.title',
  SECURITY_TYPE: 'deal.security-type.title',
  INVESTMENT_MEMO: 'deal.investment-memo.title',
};
