import { SimpleGrid, Stack } from '@chakra-ui/react';
import { UseChartCard } from 'components/modules';
import { SimpleChartCard } from 'components/modules/SimpleChartCard';
import { getPastDate, SIMPLE_DATE_FORMAT } from 'helpers';
import {
  groupMonthlyRegistries,
  investmentWeeklyRegistries,
  userWeeklyAccreditations,
  userWeeklyRegistries,
} from 'helpers/format-helpers';
import { useTranslation } from 'react-i18next';
import { MdPeople, MdTrendingUp } from 'react-icons/md';
import { useAsync } from 'react-use';
import { InvestmentResources } from 'services/resources/financial/investment';
import { GroupResources } from 'services/resources/group/admin-group/admin-group';
import { UserResources } from 'services/resources/users/user/user';

export const HomePage: React.FC = (): JSX.Element => {
  const { value, loading } = useAsync(async () => {
    const { data: registered } = await UserResources.findAll({
      created__gt: getPastDate(new Date(), 7),
      page_size: 100,
    });

    const { data: accredited } = await UserResources.findAll({
      accreditation_date__gt: getPastDate(new Date(), 7, SIMPLE_DATE_FORMAT),
      page_size: 100,
    });
    const { data: investment } = await InvestmentResources.findAll({
      created__gt: getPastDate(new Date(), 7),
      page_size: 100,
    });

    const { data: group } = await GroupResources.findAll({
      created__gt: getPastDate(new Date(), 30),
      page_size: 100,
    });

    return { registered, accredited, investment, group };
  });

  const { t } = useTranslation('home');

  return (
    <SimpleGrid columns={[1, 1, 2, 3]} m={8} spacing={4}>
      <UseChartCard
        count={value?.registered?.count as number}
        data={userWeeklyRegistries(value?.registered?.results)}
        dateKey="created"
        id="registries-chart"
        isLoading={loading}
        listingTitle={t('charts.users.listing.title')}
        registries={value?.registered?.results}
        subtitle={t('charts.users.total.registries')}
        stroke="#2B6CB0"
        title={t('charts.users.title')}
      />
      <UseChartCard
        count={value?.accredited?.count as number}
        data={userWeeklyAccreditations(value?.accredited?.results)}
        dateKey="accreditation_date"
        id="accreditations-chart"
        isLoading={loading}
        listingTitle={t('charts.accreditations.listing.title')}
        registries={value?.accredited?.results}
        subtitle={t('charts.accreditations.total.registries')}
        stroke="#4A5568"
        title={t('charts.accreditations.title')}
      />
      <Stack direction="column" justify="space-between" spacing={4}>
        <SimpleChartCard
          count={value?.investment.count as number}
          data={investmentWeeklyRegistries(value?.investment?.results)}
          icon={MdTrendingUp}
          id="investments-chart"
          isLoading={loading}
          subtitle={t('charts.investments.total.registries')}
          stroke="#B7791F"
          title={t('charts.investments.title')}
        />
        <SimpleChartCard
          count={value?.group.count as number}
          data={groupMonthlyRegistries(value?.group?.results)}
          icon={MdPeople}
          id="groups-chart"
          isLoading={loading}
          subtitle={t('charts.groups.total.registries')}
          stroke="#C53030"
          title={t('charts.groups.title')}
        />
      </Stack>
    </SimpleGrid>
  );
};
