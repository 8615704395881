import { useEffect } from 'react';
import { DivElementRef } from 'types.d';

export const useOutsideHandler = (
  ref: DivElementRef,
  isLgScreen: boolean,
  isCollapsed: boolean,
  collapse: (v: boolean) => void,
): void => {
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        !ref.current.contains(event.target as Node) &&
        ref.current &&
        !isLgScreen &&
        !isCollapsed
      ) {
        collapse(!isCollapsed);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [ref, isCollapsed, isLgScreen]);
};
