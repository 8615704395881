import { Flex, FlexProps, Image, Spinner, Text } from '@chakra-ui/react';
import Art from 'images/empty.svg';
import { useTranslation } from 'react-i18next';

export const EmptyContainer: React.FC<FlexProps> = (props): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <Flex {...props}>
      <Image
        alt="empty-logo"
        fallback={<Spinner mb={4} />}
        h="256px"
        mb={4}
        src={Art}
        w="256px"
      />
      <Text as="strong" fontSize="xl">
        {t('table.empty')}
      </Text>
    </Flex>
  );
};

EmptyContainer.defaultProps = {
  align: 'center',
  direction: 'column',
  bg: 'rgba(0,0,0,0.01)',
  h: '360px',
  justify: 'center',
};
