import { Button, CloseButton, Stack, StackProps, Text } from '@chakra-ui/react';
import fp from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import { getFileSize, InputSize } from '../helpers';

export interface ICustomInput extends StackProps {
  /**
   * Input border color.
   */
  border: string;
  /**
   * Selected file name.
   */
  file?: File | string;
  /**
   * If `true`, the input has found an error.
   */
  isInvalid: boolean;
  /**
   * On clear input handler.
   */
  onClear: () => void;
  /**
   * On select input handler.
   */
  onSelect: () => void;
  /**
   * Components size.
   */
  size: string;
}

export const CustomInput: React.FC<ICustomInput> = (props): JSX.Element => {
  const { border, file, isInvalid, onClear, onSelect, size, ...rest } = props;

  const { t } = useTranslation('common');

  return (
    <Stack
      borderColor={isInvalid ? 'red.500' : border}
      maxH={InputSize[size as keyof typeof InputSize]}
      {...rest}
    >
      {fp.isNil(file) ? (
        <Text fontSize="xs" fontWeight="medium">
          {t('inputs.file.label')}
        </Text>
      ) : (
        <Text fontSize="xs">
          {fp.isString(file) ? (
            <Button
              colorScheme="blue"
              onClick={() => window.open(file as string)}
              px={4}
              size="xs"
            >
              {t('inputs.file.preview')}
            </Button>
          ) : (
            <Text as="span" mr={4}>
              {fp.get('name')(file)}
            </Text>
          )}
          <Text as="span" fontWeight="bold">
            {getFileSize(file)}
          </Text>
        </Text>
      )}
      {fp.isNil(file) ? (
        <Button colorScheme="blue" onClick={onSelect} px={4} size="xs">
          {t('inputs.file.button.search')}
        </Button>
      ) : (
        <CloseButton onClick={onClear} size="sm" />
      )}
    </Stack>
  );
};

CustomInput.defaultProps = {
  align: 'center',
  bg: 'blue.50',
  borderRadius: '4px',
  borderWidth: '1px',
  isInline: true,
  justify: 'space-between',
  pl: 4,
  pr: 2,
  py: 2,
};
