/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: remove all the `any` types in this file.

import { createSelector } from 'reselect';

import { initialState, IToastSliceState } from './slice';

/**
 * Direct selector to the toast state domain,
 */
const selectToastManagerDomain = (state: IToastSliceState): any =>
  state?.toasts || initialState;

/**
 * Default selector used by Toast.
 */
export const makeSelectToastManager = (): any =>
  createSelector(selectToastManagerDomain, (substate) => substate);
