import { Box, BoxProps, Button } from '@chakra-ui/react';
import {
  addErrorToast,
  addSuccessToast,
} from 'containers/ToastManager/store/slice';
import fp from 'lodash/fp';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IBeneficiaryResource } from 'services/resources/users/beneficiary/types.d';
import { KYCPhysicalResources } from 'services/resources/users/kyc-physical';
import { IKYCPhysicalResource } from 'services/resources/users/kyc-physical/types.d';

export interface IDangerZoneProps extends BoxProps {
  /**
   * User Beneficiaries
   */
  beneficiaries?: number[] | IBeneficiaryResource[];
  /**
   * KYC physical user resource data.
   */
  kyc?: IKYCPhysicalResource;
}

export const DangerZone: React.FC<IDangerZoneProps> = (props): JSX.Element => {
  const { beneficiaries, kyc, ...rest } = props;

  const { t } = useTranslation(['kyc-physical', 'toasts']);

  const [state, setState] = useState<IKYCPhysicalResource>(
    kyc as IKYCPhysicalResource,
  );

  const dispatch = useDispatch();

  const handleOnSubmit = async () => {
    try {
      if (fp.isEmpty(beneficiaries)) {
        throw new Error(
          t('toast.errors.deceased.beneficiaries', { ns: 'toasts' }),
        );
      }
      const { data } = await KYCPhysicalResources.update(state?.id as number, {
        is_deceased: !state?.is_deceased,
      });
      setState(data);
      dispatch(addSuccessToast('toast.success.marked-as-deceased'));
    } catch (e) {
      if (fp.isEmpty(beneficiaries)) {
        dispatch(addErrorToast(e));
        return;
      }
      dispatch(addErrorToast('toast.errors.files.network'));
    }
  };

  return (
    <Box {...rest}>
      <Button
        colorScheme={!state?.is_deceased ? 'red' : 'green'}
        m={2}
        onClick={handleOnSubmit}
      >
        {!state?.is_deceased
          ? t('button.deceased.mark')
          : t('button.deceased.reset')}
      </Button>
    </Box>
  );
};

DangerZone.defaultProps = {
  overflow: 'scroll',
};
