import { formatDate } from 'helpers';
import fp from 'lodash/fp';

export const getIsSuperuser = fp.get('is_superuser');

export const getIsStaff = fp.get('is_staff');

export const getIsAccredited = fp.get('is_accredited');

export const getTerms = fp.get('terms');

export const getPhone = fp.get('phone');

export const getPrivacy = fp.get('privacy');

export const getAccreditationDate = fp.get('accreditation_date');

export const getLastLogin = fp.compose(formatDate, fp.get('deposit_date'));

export const getCreated = fp.compose(formatDate, fp.get('created'));
