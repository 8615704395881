import { QuerySuggestions } from 'components/elements';
import {
  CheckboxField,
  CountryField,
  FileField,
  InputField,
  SelectField,
  TextField,
} from 'components/inputs';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { genericTransform } from 'helpers/transform-helpers';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyTypeResources } from 'services/resources/catalogs/company-type';
import { ICompanyPayload } from 'services/resources/deal-flow/company/types.d';
import { CompanyRoundResources } from 'services/resources/preferences/company-round';
import { CompanyStageResources } from 'services/resources/preferences/company-stage';
import { DifferentiationResources } from 'services/resources/preferences/differentiation';
import { IndustryResources } from 'services/resources/preferences/industry';
import { ISelectOption } from 'types.d';

import { CompanyFormValidationSchema } from './helpers';

export interface ICompanyFormProps {
  /**
   * Form initial values, can be a complete or partial ICompanyPayload
   * object which will allow to support create and update requests.
   */
  initialValues: ICompanyPayload;
  /**
   * On submit action, for this form this will be controlled from the outside
   * of this component thanks to the forward ref property.
   */
  onSubmit: (v: ICompanyPayload, h?: FormikHelpers<ICompanyPayload>) => void;
}

export const CompanyForm = forwardRef<
  FormikProps<ICompanyPayload>,
  ICompanyFormProps
>((props, ref) => {
  const { initialValues, onSubmit } = props;
  const { t } = useTranslation(['companies', 'common']);

  return (
    <Formik
      initialValues={{ ...initialValues }}
      innerRef={ref}
      onSubmit={onSubmit}
      validationSchema={CompanyFormValidationSchema}
    >
      <Form>
        <InputField
          label={t('inputs.name.label', { ns: 'common' })}
          mb={4}
          name="name"
        />

        <InputField
          label={t('form.input.business-name.label')}
          mb={4}
          name="business_name"
        />

        <TextField
          label={t('form.input.description.label')}
          mb={4}
          name="description"
        />

        <FileField
          allowPreview
          label={t('form.input.logo.label')}
          mb={4}
          name="logo"
        />

        <InputField label={t('form.input.agent.label')} mb={4} name="agent" />

        <InputField
          label={t('form.input.agent-email.label')}
          mb={4}
          name="agent_email"
        />

        <CountryField
          label={t('form.input.country.label')}
          mb={4}
          name="country"
        />

        <QuerySuggestions
          query={IndustryResources.findAll}
          transform={genericTransform}
        >
          {({ loading, value }) => (
            <SelectField
              isLoading={loading}
              isMulti
              label={t('form.input.industries.label')}
              mb={4}
              name="industries"
              options={value as ISelectOption[]}
              placeholder={t('form.select-multi.default-placeholder')}
            />
          )}
        </QuerySuggestions>

        <QuerySuggestions
          query={DifferentiationResources.findAll}
          transform={genericTransform}
        >
          {({ loading, value }) => (
            <SelectField
              isLoading={loading}
              isMulti
              label={t('form.input.differentiations.label')}
              mb={4}
              name="differentiations"
              options={value as ISelectOption[]}
              placeholder={t('form.select-multi.default-placeholder')}
            />
          )}
        </QuerySuggestions>

        <QuerySuggestions
          query={CompanyStageResources.findAll}
          transform={genericTransform}
        >
          {({ loading, value }) => (
            <SelectField
              isLoading={loading}
              label={t('form.input.stage.label')}
              mb={4}
              name="stage"
              options={value as ISelectOption[]}
              placeholder={t('form.select.default-placeholder', {
                ns: 'common',
              })}
            />
          )}
        </QuerySuggestions>

        <QuerySuggestions
          query={CompanyRoundResources.findAll}
          transform={genericTransform}
        >
          {({ loading, value }) => (
            <SelectField
              isLoading={loading}
              label={t('form.input.round.label')}
              mb={4}
              name="round"
              options={value as ISelectOption[]}
              placeholder={t('form.select.default-placeholder', {
                ns: 'common',
              })}
            />
          )}
        </QuerySuggestions>

        <QuerySuggestions
          query={CompanyTypeResources.findAll}
          transform={genericTransform}
        >
          {({ loading, value }) => (
            <SelectField
              isLoading={loading}
              label={t('form.input.company-type.label')}
              mb={4}
              name="company_type"
              options={value as ISelectOption[]}
              placeholder={t('form.select.default-placeholder', {
                ns: 'common',
              })}
            />
          )}
        </QuerySuggestions>

        <FileField
          helper="inputs.file.helpers-extended"
          label={t('form.input.decklink.label')}
          mb={4}
          name="decklink"
        />

        <InputField
          label={t('form.input.web-page.label')}
          mb={4}
          name="webpagelink"
        />

        <InputField
          label={t('form.input.facebook.label')}
          mb={4}
          name="facebooklink"
        />

        <InputField
          label={t('form.input.twitter.label')}
          mb={4}
          name="twitterlink"
        />

        <InputField
          label={t('form.input.linkedin.label')}
          mb={4}
          name="linkedinlink"
        />

        <InputField
          label={t('form.input.instagram.label')}
          mb={4}
          name="instagramlink"
        />

        <InputField
          label={t('form.input.youtube.label')}
          mb={4}
          name="youtubelink"
        />

        <CheckboxField
          label={t('inputs.is-active.label', { ns: 'common' })}
          name="is_active"
        />
      </Form>
    </Formik>
  );
});

CompanyForm.displayName = 'CompanyForm';
