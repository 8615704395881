import { instance } from 'services';
import { IFindFnReturn, IQueryParams, IResponse } from 'services/types.d';

import { IJournalPayload, IJournalResource } from './types.d';

export const JournalResources = {
  findAll: (p?: IQueryParams): Promise<IFindFnReturn<IJournalResource>> =>
    instance.get('/journal/', { params: p }),

  create: (params: IJournalPayload): Promise<IResponse<IJournalResource>> =>
    instance.post(`/journal/`, params),

  update: (
    id: number,
    params: IJournalPayload,
  ): Promise<IResponse<IJournalResource>> =>
    instance.patch(`/journal/${id}/`, params),

  delete: (id: number): Promise<IResponse<unknown>> =>
    instance.delete(`/journal/${id}/`),
};
