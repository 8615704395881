import {
  FormControl,
  FormControlProps,
  FormLabelProps,
  Input,
  InputProps,
} from '@chakra-ui/react';
import { FormErrorMessage, FormHelperText, FormLabel } from 'components/lib';
import { useField } from 'formik';
import { forwardRef } from 'react';

export interface IInputFieldProps extends FormControlProps {
  /**
   * Input helper text.
   */
  helper?: string;
  /**
   * Custom input props.
   */
  inputProps?: InputProps;
  /**
   * Input label.
   */
  label?: string;
  /**
   * Label color.
   */
  labelProps?: FormLabelProps;
  /**
   * Input name.
   */
  name: string;
  /**
   * Input placeholder.
   */
  placeholder?: string;
  /**
   * Input type.
   */
  type?: string;
}

export const InputField = forwardRef<HTMLInputElement, IInputFieldProps>(
  (props, ref): JSX.Element => {
    const {
      helper,
      inputProps = { bg: 'blue.50' },
      label,
      labelProps = {},
      name,
      placeholder,
      size = 'sm',
      type = 'text',
      ...rest
    } = props;

    const [field, meta] = useField(name);

    const isInvalid = !!meta.error && !!meta.touched;

    return (
      <FormControl isInvalid={isInvalid} name={name} {...rest}>
        <FormLabel fontSize={size} htmlFor={name} {...labelProps}>
          {label}
        </FormLabel>
        <Input
          placeholder={placeholder}
          ref={ref}
          size={size}
          type={type}
          {...inputProps}
          {...field}
        />
        <FormHelperText>{helper}</FormHelperText>
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      </FormControl>
    );
  },
);

InputField.displayName = 'InputField';
