import { instance } from 'services';
import { IFindFnReturn, IQueryParams, IResponse } from 'services/types.d';

import { IReferencePayload, IReferenceResource } from './types.d';

export const ReferenceResources = {
  findAll: (p?: IQueryParams): Promise<IFindFnReturn<IReferenceResource>> =>
    instance.get('/reference/', { params: p }),

  create: (params: IReferencePayload): Promise<IResponse<IReferenceResource>> =>
    instance.post(`/reference/`, params),

  update: (
    id: number,
    params: IReferencePayload,
  ): Promise<IResponse<IReferenceResource>> =>
    instance.patch(`/reference/${id}/`, params),

  delete: (id: number): Promise<IResponse<unknown>> =>
    instance.delete(`/reference/${id}/`),
};
