import { CRUDProvider } from 'components/providers';
import { useTranslation } from 'react-i18next';
import { CompanyRoundResources } from 'services/resources/preferences/company-round';

import { Page } from './components/Page';

export const CompanyRoundPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation('company-round');

  return (
    <CRUDProvider
      allowPost
      quote={t('page.quote')}
      resource={CompanyRoundResources}
      title={t('page.title')}
    >
      <Page />
    </CRUDProvider>
  );
};
