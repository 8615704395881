import { SimpleGrid, SimpleGridProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IFinerioAccountDetailResource } from 'services/resources/finerio/types.d';

import { getCardNumber, getClabe, getName } from '../helpers';
import { Record } from './Record';

export interface IGeneralInformationProps extends SimpleGridProps {
  /**
   * Account details, such as card number, credit limit, due dates, etc.
   * @type {IFinerioAccountDetailResource}
   */
  details?: IFinerioAccountDetailResource;
}

export const GeneralInformation: React.FC<IGeneralInformationProps> = (
  props,
): JSX.Element => {
  const { details, ...rest } = props;

  const { t } = useTranslation('finerio');

  return (
    <SimpleGrid {...rest}>
      <Record description={getName(details)} title={t('modal.credit.name')} />
      <Record description={getClabe(details)} title={t('modal.credit.clabe')} />
      <Record
        description={getCardNumber(details)}
        title={t('modal.credit.card-number')}
      />
    </SimpleGrid>
  );
};

GeneralInformation.defaultProps = {
  columns: [1, 1, 2, 3],
  spacing: 4,
  w: '100%',
};
