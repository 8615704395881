/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControl, FormControlProps } from '@chakra-ui/react';
import { SingleValue } from 'components/elements';
import { FormErrorMessage, FormHelperText, FormLabel } from 'components/lib';
import { useField } from 'formik';
import fp from 'lodash/fp';
import { useEffect } from 'react';
import Select from 'react-select';
import { useAsync, useSetState } from 'react-use';
import { MiscResources } from 'services/resources/misc';
import { ICountryResource } from 'services/resources/misc/types.d';

import { customStyles, flatCountries, SelectSize } from './helpers';

export interface ICountryFieldProps extends FormControlProps {
  /**
   * Input helper text.
   */
  helper?: string;
  /**
   * Input label.
   */
  label?: string;
  /**
   * Input name.
   */
  name: string;
  /**
   * Input placeholder.
   */
  placeholder?: string;
  /**
   * Input size.
   */
  size?: keyof typeof SelectSize;
  /**
   * Transform the response of the country API.
   */
  transformResponse?: (countries: ICountryResource) => any;
}

export const CountryField: React.FC<ICountryFieldProps> = (
  props,
): JSX.Element => {
  const {
    helper,
    isDisabled,
    label,
    name,
    placeholder,
    size = 'sm',
    transformResponse = flatCountries,
    ...rest
  } = props;

  const { value: options, loading } = useAsync(async () => {
    const response = await MiscResources.getCountries();
    const result = response.data;
    return transformResponse(result);
  }, []);

  const [field, meta, helpers] = useField(name);

  const [option, setOption] = useSetState<any>();

  const handleOnChange = (opt) => {
    if (!meta?.touched) helpers?.setTouched(true);
    fp.compose(helpers.setValue, fp.get('value'))(opt);
    setOption(opt);
  };

  useEffect(() => {
    if (!fp.isEmpty(options)) {
      fp.compose(
        setOption,
        fp.find(fp.matches({ value: field?.value })),
      )(options);
    }
  }, [options]);

  const isInvalid = !!meta.error;

  return (
    <FormControl isInvalid={isInvalid} name={name} {...rest}>
      <FormLabel fontSize={size} htmlFor={name}>
        {label}
      </FormLabel>
      <Select
        components={{ SingleValue }}
        isDisabled={isDisabled}
        isLoading={loading}
        name={name}
        onChange={handleOnChange}
        options={options}
        placeholder={placeholder}
        styles={customStyles(size)}
        value={option}
      />
      <FormHelperText>{helper}</FormHelperText>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
