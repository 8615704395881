import { instance } from 'services';
import { IFindFnReturn, IQueryParams, IResponse } from 'services/types.d';

import { IGroupPayload, IGroupResource } from './types.d';

export const GroupResources = {
  findAll: (p?: IQueryParams): Promise<IFindFnReturn<IGroupResource>> =>
    instance.get('/group/', { params: p }),

  findAllNames: (p?: IQueryParams): Promise<IFindFnReturn<IGroupResource>> =>
    instance.get('/group/names', { params: p }),

  create: (params: IGroupPayload): Promise<IResponse<IGroupResource>> =>
    instance.post(`/group/`, params),

  update: (
    id: number,
    params: IGroupPayload,
  ): Promise<IResponse<IGroupResource>> =>
    instance.patch(`/group/${id}/`, params),

  delete: (id: number): Promise<IResponse<unknown>> =>
    instance.delete(`/group/${id}/`),

  exportData: (): Promise<IResponse<unknown>> =>
    instance.get('/group/convert-user-group/', { responseType: 'blob' }),
};
