import { formatDate } from 'helpers';
import fp from 'lodash/fp';

export const getTradeName = fp.get('trade_name');

export const getLegalRepresentative = fp.get('legal_representative');

export const getIsAccredited = fp.get('is_accredited');

export const getCreated = fp.compose(formatDate, fp.get('created'));

export const getUpdated = fp.compose(formatDate, fp.get('updated'));

export const getIsKybCompleted = fp.get('is_kyb_complete');

export const getLegalRepresentativeEmail = fp.get(['user_detail', 'email']);

export const getApplicationType = fp.get('application_type');
