import { Tr } from '@chakra-ui/react';
import { useGenericContext } from 'components/contexts/useGenericContext';
import { Td, TdActionIcon } from 'components/lib';
import { CustomTable } from 'components/modules/Tables/CustomTable/CustomTable';
import { getResults, isResultsEmpty } from 'helpers';
import { nanoid } from 'nanoid';
import { IJournalResource } from 'services/resources/journal';

import { getAction, getCreated, getEmail, HEADERS } from '../helpers';

export const Page: React.FC = (): JSX.Element => {
  const { state } = useGenericContext<IJournalResource>();

  return (
    <>
      <CustomTable
        isEmpty={isResultsEmpty(state)}
        headers={HEADERS}
        isLoading={state?.loading}
        mx={8}
      >
        {getResults(state)?.map((row: IJournalResource) => (
          <Tr key={nanoid()} position="relative">
            <Td>{getEmail(row)}</Td>
            <TdActionIcon action={getAction(row)} />
            <Td>{getCreated(row)}</Td>
          </Tr>
        ))}
      </CustomTable>
    </>
  );
};
