import fp from 'lodash/fp';

export const genericTransform = fp.compose(
  fp.map(({ id, name }) => ({
    label: name,
    value: id.toString(),
  })),
);

export const groupTransform = fp.compose(
  fp.map(({ id, name }) => ({
    label: name,
    value: id,
  })),
);

export const emailTransform = fp.compose(
  fp.map(({ id, email }) => ({
    label: email,
    value: id.toString(),
  })),
);

export const companyTransform = fp.compose(
  fp.map(({ id, company }) => ({
    label: company?.name,
    value: id.toString(),
  })),
);

export const dealTransform = fp.compose(
  fp.map(({ id, company, slug }) => ({
    label: `${company?.name} / ${slug}`,
    value: id.toString(),
  })),
);
