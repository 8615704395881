import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { theme } from 'styles';

export const RichElement = styled(Box)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme.colors.black};
    font-family: ${theme.fonts.heading};
    font-weight: ${theme.fontWeights.bold};
    margin: ${`${theme.space[4]} 0`};
  }

  h1,
  h2,
  h3 {
    font-size: ${theme.fontSizes.xl};
  }

  h4 {
    font-size: ${theme.fontSizes.lg};
  }

  p {
    color: ${theme.colors.blackAlpha[700]};
    font-family: ${theme.fonts.body};
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeights.medium};
    line-height: ${theme.lineHeights.shorter};
    margin: ${`${theme.space[4]} 0`};

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    color: ${theme.colors.blackAlpha[700]};
    font-size: ${theme.fontSizes.sm};
    font-weight: ${theme.fontWeights.medium};
    line-height: ${theme.lineHeights.shorter};
    margin: ${`${theme.space[4]} 0`};
    margin-left: ${theme.space[6]};

    li {
      margin-bottom: 0.5rem;
    }
  }

  blockquote {
    border-left: 4px solid;
    line-height: ${theme.lineHeights.base};
    margin: ${`${theme.space[4]} 0 ${theme.space[8]}`};
    padding: ${`0 ${theme.space[8]}`};
  }

  img {
    height: auto;
    width: 100%;
  }
`;
