import {
  Avatar,
  BoxProps,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { IUserNavigation } from 'types.d';

export interface IUserNavigationProps extends BoxProps {
  /**
   * User avatar image to render in the UI, in case that the user doesn't have
   * set an avatar, it will display it username initials.
   */
  avatar?: string;
  /**
   * Navigation routes that will be rendered in the header element or in the
   * drawer menu in case of mobile screen.
   */
  navigation: IUserNavigation[];
  /**
   * On click events it will trigger the logout redux action and dispatch the
   * respective sagas in order to destroy the user's session.
   */
  onLogout: () => void;
  /**
   * User's fullname if it has complete its profile, otherwise it will render
   * the user's email.
   */
  username?: string;
}

export const UserNavigation: React.FC<IUserNavigationProps> = (
  props,
): JSX.Element => {
  const { avatar, navigation, onLogout, username: user, ...rest } = props;

  const { t } = useTranslation('common');

  return (
    <Menu {...rest}>
      <MenuButton as={Button} h="100%" variant="ghost">
        <Avatar bg="blue.200" name={user} size="sm" src={avatar} />
      </MenuButton>

      <MenuList>
        {navigation.map((item) => (
          <MenuItem key={nanoid()}>{item.label}</MenuItem>
        ))}
        <MenuItem fontSize="sm" onClick={onLogout}>
          {t('header.logout')}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
