import { instance } from 'services';
import { IFindFnReturn, IQueryParams, IResponse } from 'services/types.d';

import { ISpotlightPayload, ISpotlightResource } from './types.d';

export const SpotlightResources = {
  findAll: (p?: IQueryParams): Promise<IFindFnReturn<ISpotlightResource>> =>
    instance.get('/spotlight/', { params: p }),

  create: (params: ISpotlightPayload): Promise<IResponse<ISpotlightResource>> =>
    instance.post(`/spotlight/`, params),

  update: (
    id: number,
    params: ISpotlightPayload,
  ): Promise<IResponse<ISpotlightResource>> =>
    instance.patch(`/spotlight/${id}/`, params),

  delete: (id: number): Promise<IResponse<unknown>> =>
    instance.delete(`/spotlight/${id}/`),
};
