import {
  FormHelperText as FHT,
  HelpTextProps,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react';
import fp from 'lodash/fp';
import { FaInfoCircle } from 'react-icons/fa';

export const FormHelperText: React.FC<HelpTextProps> = (
  props,
): JSX.Element | null => {
  const { children, ...rest } = props;

  if (fp.isEmpty(children) || fp.isNil(children)) return null;

  return (
    <FHT color="blackAlpha.500" fontSize="xs" fontWeight="medium" {...rest}>
      <Stack align="start" isInline>
        <Icon as={FaInfoCircle} color="blue.100" h={3} w={3} />
        <Text align="start" as="span">
          {children}
        </Text>
      </Stack>
    </FHT>
  );
};
