import { Box, BoxProps, Icon } from '@chakra-ui/react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

export interface IToggleButtonProps extends Omit<BoxProps, 'onClick'> {
  /**
   * If `true`, the sidebar should be collapsed.
   */
  isToggle: boolean;
  /**
   * Change the collapsed state of the sidebar.
   */
  onClick: (v?: boolean) => void;
}

export const ToggleButton: React.FC<IToggleButtonProps> = (
  props,
): JSX.Element => {
  const { isToggle, onClick, ...rest } = props;
  return (
    <Box as="button" onClick={() => onClick()} {...rest}>
      <Icon as={isToggle ? FaChevronRight : FaChevronLeft} boxSize={3} />
    </Box>
  );
};

ToggleButton.defaultProps = {
  alignItems: 'center',
  bg: 'white',
  border: '1px',
  borderColor: 'blackAlpha.100',
  display: 'flex',
  h: 8,
  justifyContent: 'center',
  position: 'absolute',
  right: 0,
  rounded: 'full',
  sx: { ':hover': { bg: 'gray.100' } },
  top: '30px',
  transform: 'translateX(50%)',
  transition: 'background 0.5s ease',
  w: 8,
};
