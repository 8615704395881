import { Box, BoxProps, Table, Tbody, Thead, Tr } from '@chakra-ui/react';
import { H2 } from 'components/elements';
import { Td, Th } from 'components/lib';
import { EmptyBanner } from 'containers/UserPages/KYCPhysicalPages/pages/DetailPage/components/EmptyBanner';
import { getId } from 'helpers';
import NotFoundCover from 'images/people.svg';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { IFinerioTransactionResource } from 'services/resources/finerio/types.d';

import {
  getTransactionAmount,
  getTransactionDate,
  getTransactionDescription,
  hasTransactions,
} from '../helpers';

export interface ITransactionsProps extends BoxProps {
  /**
   * Account transactions during a certain period.
   */
  transactions?: IFinerioTransactionResource[];
}

export const Transactions: React.FC<ITransactionsProps> = (
  props,
): JSX.Element => {
  const { transactions = [], ...rest } = props;

  const { t } = useTranslation(['finerio', 'tables']);

  return (
    <Box {...rest}>
      <H2 mb={8} textTransform="uppercase">
        {t('finerio:modal.transactions.title')}
      </H2>

      {hasTransactions(transactions) ? (
        <Table colorScheme="blackAlpha" variant="striped">
          <Thead>
            <Tr>
              <Th>{t('tables:HEADER.ID')}</Th>
              <Th>{t('tables:HEADER.DESCRIPTION')}</Th>
              <Th>{t('tables:HEADER.AMOUNT')}</Th>
              <Th>{t('tables:HEADER.DATE')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {transactions?.map((item: IFinerioTransactionResource) => (
              <Tr key={nanoid()}>
                <Td>{getId(item)}</Td>
                <Td>{getTransactionDescription(item)}</Td>
                <Td>{getTransactionAmount(item)}</Td>
                <Td>{getTransactionDate(item)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <EmptyBanner
          cover={NotFoundCover}
          title={t('finerio:modal.transactions.empty')}
        />
      )}
    </Box>
  );
};
