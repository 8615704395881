export const HEADERS = [
  'HEADER.ID',
  'HEADER.EMAIL',
  'HEADER.PHONE',
  'HEADER.IS_SUPERUSER',
  'HEADER.IS_STAFF',
  'HEADER.IS_ACCREDITED',
  'HEADER.TERMS',
  'HEADER.PRIVACY',
  'HEADER.ACCREDITATION_DATE',
  'HEADER.LAST_LOGIN',
  'HEADER.CREATED',
];
