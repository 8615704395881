import fp from 'lodash/fp';
import { IUserResource } from 'services/resources/users/user/types.d';

export const getFullname = fp.compose(
  fp.trim,
  fp.join(' '),
  fp.values,
  fp.omitBy(fp.isNil),
  fp.pick(['name', 'first_surname', 'last_surname']),
  fp.get('kyc_physical_person'),
);

export const hasName = fp.compose(
  fp.negate((v: boolean) => !v),
  fp.isEmpty,
  fp.get(['kyc_physical_person', 'name']),
);

export const getUsername = (user: IUserResource): string => {
  if (fp.isNumber(user) || hasName(user)) return fp.get('email')(user);
  return getFullname(user);
};
