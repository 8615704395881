import { Box } from '@chakra-ui/react';
import { QuerySuggestions } from 'components/elements';
import { DateField, MoneyField, SelectField } from 'components/inputs';
import { Form, Formik, FormikProps } from 'formik';
import { genericTransform } from 'helpers/transform-helpers';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ExchangeResources } from 'services/resources/catalogs/exchange';
import { SecurityTypeResources } from 'services/resources/catalogs/security-type';
import { IDealPayload } from 'services/resources/deal-flow/deal/types.d';
import { ICommonDealStateProps, ISelectOption } from 'types.d';

import { SecurityFormValidationSchema } from './helpers';

export const SecurityForm = forwardRef<
  FormikProps<IDealPayload>,
  ICommonDealStateProps
>((props, ref): JSX.Element => {
  const { initialValues = {}, onSubmit, ...rest } = props;

  const { t } = useTranslation('deals');

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...initialValues }}
      innerRef={ref}
      onSubmit={onSubmit}
      validationSchema={SecurityFormValidationSchema}
    >
      <Box as={Form} {...rest}>
        <QuerySuggestions
          query={SecurityTypeResources.findAll}
          transform={genericTransform}
        >
          {({ loading, value }) => (
            <SelectField
              isLoading={loading}
              label={t('forms.input.security-type.label')}
              mb={4}
              name="security_type"
              options={value as ISelectOption[]}
              placeholder={t('forms.input.query.placeholder')}
              size="md"
              w={{ base: '100%', lg: '66%' }}
            />
          )}
        </QuerySuggestions>

        <MoneyField
          label={t('forms.input.cap.label')}
          mb={4}
          name="valuation_cap"
          size="md"
          w={{ base: '100%', lg: '33%' }}
        />

        <MoneyField
          label={t('forms.input.pre-money.label')}
          mb={4}
          name="pre_money"
          size="md"
          w={{ base: '100%', lg: '33%' }}
        />

        <MoneyField
          label="Tipo de cambio"
          name="exchange_type"
          mb={4}
          size="md"
          w={{ base: '100%', md: '66%' }}
        />

        <DateField
          label="Fecha Tipo de cambio"
          name="exchange_type_date"
          mb={4}
          size="md"
          w={{ base: '100%', md: '66%' }}
        />

        <MoneyField
          label="CAP USD"
          name="usd_cap"
          mb={4}
          size="md"
          w={{ base: '100%', md: '66%' }}
        />

        <QuerySuggestions
          query={ExchangeResources.findAll}
          transform={genericTransform}
        >
          {({ loading, value }) => (
            <SelectField
              isLoading={loading}
              label={t('forms.input.exchange.label')}
              name="exchange"
              options={value as ISelectOption[]}
              placeholder={t('forms.input.query.placeholder')}
              size="md"
              w={{ base: '100%', lg: '66%' }}
            />
          )}
        </QuerySuggestions>
      </Box>
    </Formik>
  );
});

SecurityForm.displayName = 'SecurityForm';
