import { Box, BoxProps } from '@chakra-ui/react';

export const Container: React.FC<BoxProps> = (props): JSX.Element => {
  const { children, ...rest } = props;

  return (
    <Box
      bg="blue"
      h="1.25px"
      margin="0 auto"
      position="relative"
      width="100%"
      {...rest}
    >
      {children}
    </Box>
  );
};
