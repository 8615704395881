import { BsFileEarmarkSpreadsheet } from 'react-icons/bs';
import { FaPencilAlt, FaTrashAlt, FaTrashRestoreAlt } from 'react-icons/fa';

export enum ButtonActionsEnum {
  DELETE = 'DELETE',
  FILE = 'FILE',
  RESTORE = 'RESTORE',
  UPDATE = 'UPDATE',
}

export const ACTIONS = {
  [ButtonActionsEnum.DELETE]: {
    color: 'red',
    icon: <FaTrashAlt />,
    id: ButtonActionsEnum.DELETE,
  },

  [ButtonActionsEnum.FILE]: {
    color: 'teal',
    icon: <BsFileEarmarkSpreadsheet />,
    id: ButtonActionsEnum.FILE,
  },

  [ButtonActionsEnum.RESTORE]: {
    color: 'green',
    icon: <FaTrashRestoreAlt />,
    id: ButtonActionsEnum.RESTORE,
  },
  [ButtonActionsEnum.UPDATE]: {
    color: 'blue',
    icon: <FaPencilAlt />,
    id: ButtonActionsEnum.UPDATE,
  },
};
