import { CRUDProvider } from 'components/providers';
import { useTranslation } from 'react-i18next';
import { KYCHistoryResources } from 'services/resources/users/kyc-physical';

import { Page } from './components/Page';

export const ListingPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation('kyc-history');

  return (
    <CRUDProvider
      quote="In this page the staff team will be able to view, create, edit and delete kyc history registries."
      resource={KYCHistoryResources}
      searchBy="email"
      title="KYC History Page"
    >
      <Page />
    </CRUDProvider>
  );
};
