import { Box, BoxProps, Icon, Table, Tbody, Thead, Tr } from '@chakra-ui/react';
import { Td, TdActions, Th } from 'components/lib';
import { nanoid } from 'nanoid';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MdFileDownload } from 'react-icons/md';
import { IKYBMoralPersonResource } from 'services/resources/users/kyb-moral/types.d';

import {
  documents,
  getAddressProof,
  getConstitutiveCertificate,
  getElectronicSignNumberProof,
  getLegalRepresentativeId,
  getLegalRepresentativeProof,
  getOfficialIdNumberProof,
  getResourceProvider,
  getTaxIdProof,
  IDocumentsProps,
} from '../helpers';

export interface IOfficialDocumentsInformationProps extends BoxProps {
  /**
   * The bank statements related to a user.
   */
  kyb?: IKYBMoralPersonResource;
}

export const OfficialDocumentsInformation: React.FC<IOfficialDocumentsInformationProps> =
  (props): JSX.Element | null => {
    const { kyb, ...rest } = props;

    const { t } = useTranslation('kyb-moral');

    const documentsIdentificator = {
      constitutive_certificate: getConstitutiveCertificate(kyb),
      tax_id_proof: getTaxIdProof(kyb),
      official_id_number_proof: getOfficialIdNumberProof(kyb),
      electronic_sign_number_proof: getElectronicSignNumberProof(kyb),
      address_proof: getAddressProof(kyb),
      legal_representative_proof: getLegalRepresentativeProof(kyb),
      legal_representative_ID: getLegalRepresentativeId(kyb),
      resource_provider: getResourceProvider(kyb),
    };

    const handleOnDownload = useCallback((row: string) => {
      window.open(documentsIdentificator[row]);
    }, []);

    return (
      <Box {...rest}>
        <Table colorScheme="blackAlpha" variant="striped">
          <Thead>
            <Tr>
              <Th>{t('page.detail.table.title.document')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {documents?.map((row: IDocumentsProps) => (
              <Tr key={nanoid()}>
                <Td>{t(row?.label as string)}</Td>
                <TdActions
                  customButtonBg="orange"
                  customButtonIcon={<Icon as={MdFileDownload} color="white" />}
                  isActive
                  onCustomCallback={() =>
                    handleOnDownload(row?.identificator as string)
                  }
                  onlyCustomAction
                />
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    );
  };

OfficialDocumentsInformation.defaultProps = {
  overflow: 'scroll',
};
