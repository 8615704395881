import { IFetchFn, IFetchState } from 'components/hooks';
import { FormikHelpers } from 'formik';
import { once } from 'lodash/fp';
import { createContext, useContext } from 'react';
import { IBaseResource, IFilterParams, IQueryParams } from 'services/types.d';

export interface IGenericContextProps<T = IBaseResource> {
  /**
   * Stored value of the `is_active` filter, this must be a binary value that
   * represents a `true` or `false` for the `is_active` attribute that all the
   * registries have.
   */
  active: number;
  /**
   * On click events that triggers this action it will make a HTTP DELETE
   * request that will deactivate the registry changing its `is_active`
   * attribute to `false`, then it will make a GET request to update the
   * listing resources and finally will close the delete modal.
   */
  defaultDelete: () => void;
  /**
   * On click events that triggers this action it will make a HTTP PATCH
   * request that will update the selected registry, then it will make a
   * GET request to update the listing resources and finally will close the
   * form drawer.
   */
  defaultPatch: (v: Partial<T>, h?: FormikHelpers<Partial<T>>) => void;
  /**
   * On click events that triggers this action it will make a HTTP PATCH
   * request that will update the selected registry in order to change the
   * `is_active` attribute from `false` to `true`, finally it will make a
   * GET request to update the listing resources.
   */
  defaultRestore: (r: T, v: Partial<T>) => void;
  /**
   * Async generic function that retrieves a set of registries from the API.
   */
  doFetch: IFetchFn<T>;
  /**
   * If `true`, then the form drawer that will allow to create or update
   * registries will be open.
   */
  isDrawerOpen: boolean;
  /**
   * If `true`, then the delete modal will be open in order to ask the user to
   * reconfirm its requested action.
   */
  isModalOpen: boolean;
  /**
   * This function is call when the company filter detects a change, the main
   * function is to update the `params` prop with the search field to retrieve.
   */
  onFilterByCompany: (v?: string) => void;
  /**
   * This function is call when the journal activity filter detects a change, the main
   * function is to update the `params` prop with the search field to retrieve.
   */
  onFilterByJournalActivity: (v?: string) => void;
  /**
   * This function is call when the group name filter detects a change, the main
   * function is to update the `params` prop with the search field to retrieve.
   */
  onFilterByGroupName: (v?: string) => void;
  /**
   * Before create any data, this function stores an empty `registry` and
   * triggers the from drawer.
   */
  onPrepareCreate: () => void;
  /**
   * Before deleting any data, this function stores the selected `registry` and
   * triggers the open delete modal action.
   */
  onPrepareDelete: (v: T) => void;
  /**
   * Before creating or updating any data, this function stores the selected
   * `registry` and triggers the `toggleDrawer` action.
   */
  onPrepareDrawer: (v: T) => void;
  /**
   * This function is call when the search filter detects a change, the main
   * function is to update the `params` prop with the search field to retrieve.
   */
  onSearchRegistry: (v?: string) => void;
  /**
   * Mutates the value of the `active` filter.
   */
  onSetActiveFilter: (v: string) => void;
  /**
   * Mutates the value of the `ordering` filter.
   */
  onSetOrdering: (v: string) => void;
  /**
   * Mutates the user filters: `is_accredited`, `is_staff` and `is_superuser`.
   */
  onSetUserFilters: (v: string[]) => void;
  /**
   * Mutates the user filters: `is_owner`, `is_admin`.
   */
  onSetUserGroupFilters: (v: string[]) => void;
  /**
   * Stored value of the `ordering` filter, this must be a binary value that
   * represents the `ascending` or `descending` order of the listing resultset.
   */
  ordering: string;
  /**
   * Stored value of the MongoDB `ordering` filter, this must be a binary value that
   * represents the `ascending` or `descending` order of the listing resultset.
   */
  orderingMongoID: string;
  /**
   * HTTP GET query parameters for filter and ordering registries.
   * @type IQueryParams
   */
  params: IQueryParams;
  /**
   * Selected registry to update or delete.
   */
  registry: T;
  /**
   * Set the selected registry in the provider state.
   */
  setRegistry: (v: T) => void;
  /**
   * Provider's custom state that stores the paginated response from the API
   * the loading state and the error handler.
   */
  state: IFetchState<T>;
  /**
   * On a click action, this function opens or closes the form drawer component.
   */
  toggleDrawer: () => void;
  /**
   * On a click action, this function opens or closes the delete modal
   * component.
   */
  toggleModal: () => void;
  /**
   * This values are a partial of the @type{IFilterParams}, only the
   * `is_accredited`, `is_staff` and `is_superuser` are part of the payload.
   */
  userFilters: Partial<IFilterParams>;
}

export const getGenericContext = once(
  <T>(): React.Context<IGenericContextProps<T>> =>
    createContext({} as IGenericContextProps<T>),
);

export const useGenericContext = <T>(): IGenericContextProps<T> =>
  useContext(getGenericContext<T>());
