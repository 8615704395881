import { instance } from 'services';
import { IFindFnReturn, IQueryParams, IResponse } from 'services/types.d';

import { IUserResource } from '../user/types.d';
import { IKYCPhysicalPayload, IKYCPhysicalResource } from './types.d';

const CUSTOM_HEADERS = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};

export const KYCPhysicalResources = {
  findAll: (p?: IQueryParams): Promise<IFindFnReturn<IKYCPhysicalResource>> =>
    instance.get('/kyc-physical/', { params: p }),

  findByID: (id: number | string): Promise<IResponse<IKYCPhysicalResource>> =>
    instance.get(`/kyc-physical/${id}`),

  findUser: (id: number | string): Promise<IResponse<IUserResource>> =>
    instance.get(`/kyc-physical/${id}/user`),

  create: (
    params: IKYCPhysicalPayload,
  ): Promise<IResponse<IKYCPhysicalResource>> =>
    instance.post(`/kyc-physical/`, params),

  update: (
    id: number,
    params: IKYCPhysicalPayload,
    isMultipart = false,
  ): Promise<IResponse<IKYCPhysicalResource>> =>
    instance.patch(
      `/kyc-physical/${id}/`,
      params,
      isMultipart ? CUSTOM_HEADERS : {},
    ),

  delete: (id: number): Promise<IResponse<unknown>> =>
    instance.delete(`/kyc-physical/${id}/`),

  updateFiles: (
    id: number,
    params: FormData,
  ): Promise<IResponse<IKYCPhysicalResource>> =>
    instance.patch(`/kyc-physical/${id}/`, params, CUSTOM_HEADERS),
};
