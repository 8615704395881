import { instance } from 'services';
import { IFindFnReturn, IQueryParams, IResponse } from 'services/types.d';

import { IInvestmentPayload, IInvestmentResource } from './types.d';

export const InvestmentResources = {
  findAll: (p?: IQueryParams): Promise<IFindFnReturn<IInvestmentResource>> =>
    instance.get('/investment/', { params: p }),

  create: (
    params: IInvestmentPayload,
  ): Promise<IResponse<IInvestmentResource>> =>
    instance.post(`/investment/`, params),

  update: (
    id: number,
    params: IInvestmentPayload,
  ): Promise<IResponse<IInvestmentResource>> =>
    instance.patch(`/investment/${id}/`, params),

  delete: (id: number): Promise<IResponse<unknown>> =>
    instance.delete(`/investment/${id}/`),
};
