import { Tr } from '@chakra-ui/react';
import { useGenericContext } from 'components/contexts/useGenericContext';
import { SitiForm } from 'components/forms';
import { Td } from 'components/lib';
import { CustomTable, FormDrawer } from 'components/modules';
import {
  addErrorToast,
  addSuccessToast,
} from 'containers/ToastManager/store/slice';
import { FormikProps } from 'formik';
import { getEmail, getId, getResults } from 'helpers';
import FileDownload from 'js-file-download';
import fp from 'lodash/fp';
import { nanoid } from 'nanoid';
import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ISitiResource, SitiResource } from 'services/resources/siti';
import { ISitiPayload } from 'services/resources/siti/types.d';

import {
  getCode,
  getCreated,
  getInvoice,
  getPeriodEnd,
  getPeriodStart,
  HEADERS,
} from '../helpers';

export const Page: React.FC = (): JSX.Element => {
  const { isDrawerOpen, params, state, toggleDrawer, doFetch } =
    useGenericContext<ISitiResource>();

  const ref = useRef<FormikProps<ISitiPayload>>(null);

  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const defaultPatch = async ({ file: f, ...v }: ISitiPayload) => {
    const p = new FormData();
    if (!fp.isUndefined(f) && !fp.isString(f))
      p.append('file', f as File, (f as File).name);

    fp.compose(
      fp.each((k: string) => p.append(k, v[k])),
      fp.keys,
      fp.omitBy(fp.isNil),
    )(v);

    try {
      const response = (await SitiResource.create(p as ISitiPayload)).data;
      if (!fp.isUndefined(response.identificadorReporte)) {
        FileDownload(JSON.stringify(response), 'siti_report.json');
      }
      dispatch(addSuccessToast('toast.success.files'));
    } catch (e) {
      dispatch(addErrorToast('toast.errors.files.network'));
    }

    await doFetch(params);
    toggleDrawer();
  };

  const prepareOnSubmit = useCallback(() => {
    if (ref?.current) ref?.current?.submitForm();
  }, [ref]);

  return (
    <>
      <CustomTable isEmpty={false} headers={HEADERS} isLoading={false} mx={8}>
        {getResults(state)?.map((row: ISitiPayload) => (
          <Tr key={nanoid()} position="relative">
            <Td>{getId(row)}</Td>
            <Td>{getEmail(row)}</Td>
            <Td>{getPeriodStart(row)}</Td>
            <Td>{getPeriodEnd(row)}</Td>
            <Td>{getCode(row)}</Td>
            <Td>{getInvoice(row)}</Td>
            <Td>{getCreated(row)}</Td>
          </Tr>
        ))}
      </CustomTable>

      <FormDrawer
        isOpen={isDrawerOpen}
        isDisabled={!!ref?.current?.isSubmitting || !!ref?.current?.isValid}
        isLoading={!!ref.current?.isSubmitting}
        onClose={toggleDrawer}
        onSubmit={prepareOnSubmit}
      >
        <SitiForm onSubmit={defaultPatch} ref={ref} />
      </FormDrawer>
    </>
  );
};
