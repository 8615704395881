import { Button } from '@chakra-ui/react';
import { EmailField, PasswordField } from 'components/inputs';
import { Form, Formik, FormikHelpers } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ILoginEmailPayload,
  ILoginPayload,
} from 'services/resources/auth/types.d';

import { PasswordFormValidationSchema } from '../helpers';

export interface IPasswordFormProps {
  /**
   * Form initial value, for setting the email value.
   */
  initialValues: ILoginEmailPayload;
  /**
   * On submit handler (full login payload).
   */
  onSubmit: (v: ILoginPayload, helpers?: FormikHelpers<ILoginPayload>) => void;
}

export const PasswordForm: React.FC<IPasswordFormProps> = (
  props,
): JSX.Element => {
  const { initialValues, onSubmit } = props;

  const { t } = useTranslation('login');

  const handleOnSubmit = useCallback((values, helpers) => {
    const data: ILoginPayload = {
      email: initialValues.email,
      password: values.password,
    };
    onSubmit(data, helpers);
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...initialValues, password: '' }}
      onSubmit={handleOnSubmit}
      validationSchema={PasswordFormValidationSchema}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <EmailField isDisabled mb={4} name="email" size="md" />
          <PasswordField mb={4} name="password" size="md" />
          <Button
            colorScheme="blue"
            isDisabled={isSubmitting || !isValid}
            isLoading={isSubmitting}
            isFullWidth
            size="md"
            type="submit"
          >
            {t('button.logIn')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
