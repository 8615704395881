import { Divider, PageHeadline } from 'components/elements';
import { useFind } from 'components/hooks';
import { ReadOnly } from 'components/modules';
import fp from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use';
import { OccupationYearResources } from 'services/resources/catalogs/occupation-year';
import { IReadOnlyResource } from 'services/resources/catalogs/types.d';

export const OccupationYearPage: React.FC = (): JSX.Element => {
  const [state, onFind] = useFind<IReadOnlyResource>(OccupationYearResources);

  const { t } = useTranslation('common');

  useMount(() => onFind());

  return (
    <>
      <PageHeadline
        quote={t('page.read-only.quote')}
        title={t('page.occupation-year.title')}
      />

      <Divider />

      <ReadOnly data={fp.get(['value', 'results'])(state)} m={8} />
    </>
  );
};
